<template>
	<div class="containerX">
		<h1 class="mb-1">Nouveau lien de paiement</h1>
		<form>
			<div class="row">
				<div class="col-12 col-md-3 pe-md-1">
					<vue-field
						type="number"
						v-model="form.montant"
						margin="mb-2 mb-md-0"
						placeholder="Montant" />
				</div>
				<div class="col-12 col-md-5 px-md-1">
					<vue-field
						v-model="form.mail"
						type="email"
						@prenom="form.prenom=$event"
						@nom="form.nom=$event"
						margin="mb-2 mb-md-0"
						placeholder="Adresse mail" />
				</div>
				<div class="col-12 col-md-4 mb-0 mb-md-2 ps-md-1">
					<vue-field
						type="tel"
						v-model="form.tel"
						margin="mb-2 mb-md-0"
						placeholder="Numéro de téléphone portable" />
				</div>
			</div>
			<div class="row">
				<div class="col-6 col-md-3 mb-2 pe-md-1">
					<input type="text" v-model="form.prenom" class="form-control form-control-sm" placeholder="Prénom">
				</div>
				<div class="col-6 col-md-3 mb-2 px-md-1">
					<input type="text" v-model="form.nom" class="form-control form-control-sm" placeholder="Nom">
				</div>
				<div class="col-12 col-md-6 mb-2 ps-md-1">
					<input type="text" v-model="form.message" class="form-control form-control-sm" placeholder="Message">
				</div>
			</div>
			<div class="col-12 text-end">
				<btn
					class="btn-outline-primary"
					:disabled="!form.montant"
					@click="saveLink">Enregistrer</btn>
			</div>
		</form>

		<div class="py-5" />

		<div class="d-flex mb-1">
			<h1 class="flex-grow-1 m-0">Historique</h1>
			<div>Total payé : <montant :montant="linksPayes" /> / <montant :montant="linksTotal" /></div>
		</div>
		<div class="d-flex gap-3 mb-3">
			<input type="date" v-model="du" :max="au" @input="loadPi" class="form-control flex-grow-1">
			<input type="date" v-model="au" :min="du" @input="loadPi" class="form-control flex-grow-1">
			<div class="btn btn-outline-dark" @click="loadPi"><i class="fa fa-refresh"></i></div>
		</div>

		<div class="list-group">
			<div v-for="(link, l) in links" :key="link.id" class="list-group-item bg-opacity-25" :class="{'bg-success':link.paye, 'bg-warning':!link.paye}">
				<div class="d-flex align-items-center">
					<div class="flex-grow-1">
						<b><montant :montant="link.montant" /></b>
						<!--				<div v-html="link.message" class="small text-muted"></div>-->
						<span class="ms-2 small text-muted">{{ link.prenom }} {{ (link.nom||'').toUpperCase() }} {{ link.mail }} {{ link.tel }}</span>
						<span class="ms-2 small text-muted">/{{ link.hash }}</span>

						<div v-if="link.paye || link.event" class="small">
							<i class="far fa-credit-card me-2" />
							<date v-if="link.paye" :date="link.paye" />
							<a v-if="link.event" :href="`${stripe_url}events/${link.event}`" target="_blank" class="ms-2">Détail</a>
						</div>
						<div v-else-if="link.created || link.intent" class="small">
							<i class="far fa-calendar me-2" />
							<date v-if="link.created" :date="link.created" />
							<a v-if="link.intent" :href="`${stripe_url}payments/${link.intent}`" target="_blank" class="ms-2">Détail</a>
						</div>
					</div>
					<div>
						<!--						<br>-->
						<a class="btn btn-outline-dark btn-sm ms-1" v-if="!link.paye" @click="modal=l"><i class="fa fa-qrcode"></i></a>
						<!--						<a class="btn btn-outline-dark btn-sm ms-1" v-if="!link.paye && link.mail"><i class="fa fa-envelope"></i></a>-->
						<!--						<a class="btn btn-outline-dark btn-sm ms-1" v-if="!link.paye && link.tel"><i class="fa fa-message"></i></a>-->
						<!--						<router-link :to="{name:'paiement', params:{id:link.hash}}" v-if="!link.paye" target="_blank" class="btn btn-outline-dark btn-sm ms-1"><i class="fa fa-eye"></i></router-link>-->
						<div class="btn btn-outline-dark btn-sm ms-1" @click="edit=Object.assign({}, link, {montant:link.montant/100, index:l})"><i class="fa fa-pencil"></i></div>
						<div class="btn btn-outline-danger btn-sm ms-1" v-if="!link.paye" @click="deleteLink(l)"><i class="fa fa-trash"></i></div>
					</div>
				</div>

			</div>
			<div v-if="links===null" class="list-group-item text-center">Chargement ...</div>
			<div v-else-if="links.length===0" class="list-group-item text-center">Aucun lien de paiement ...</div>
		</div>


		<modal-right
			v-if="edit"
			position="center modal-center-left"
			@cancel="edit=null">
			<!--			/{{ edit.hash }}-->
			<template #title>Édition du lien #{{ edit.id }} du <date :date="edit.created" /></template>
			<template v-slot:default="props">
				<div class="text-center">
					<!--					<input type="number" v-model="edit.id" class="form-control form-control-sm mb-2" disabled placeholder="Identifiant">-->
					<!--					<input type="text" v-model="edit.hash" class="form-control form-control-sm mb-2" disabled placeholder="Hash">-->
					<input type="number" v-model="edit.montant" class="form-control mb-2" placeholder="Montant">
					<input type="text" v-model="edit.message" class="form-control form-control-sm mb-2" placeholder="Message">
					<input type="text" v-model="edit.prenom" class="form-control form-control-sm mb-2" placeholder="Prénom">
					<input type="text" v-model="edit.nom" class="form-control form-control-sm mb-2" placeholder="Nom">
					<input type="email" v-model="edit.mail" class="form-control form-control-sm mb-2" placeholder="Adresse mail">
					<vue-field type="tel" v-model="edit.tel" class="" placeholder="Numéro de téléphone portable" />

					<input type="text" v-model="edit.intent" class="form-control form-control-sm mb-2" placeholder="Intent">
					<input type="text" v-model="edit.customer" class="form-control form-control-sm mb-2" placeholder="Customer">
					<input type="text" v-model="edit.paye" class="form-control form-control-sm mb-2" placeholder="Date de paiement">
					<input type="text" v-model="edit.event" class="form-control form-control-sm mb-2" placeholder="Event">

					<div class="text-center">
						<btn label="Enregistrer" color="primary w-100 mb-2" @click="saveEditLink" />
						<btn label="Annuler" color="link btn-sm text-muted text-decoration-none" @click="props.closeModal" />
					</div>
					<!--				Montant : <montant :montant="links[edit].montant" />-->
					<!--				<br>-->
					<!--				<qrcode-vue class="my-3" :value="`${app_url}/paiement/${links[edit].hash}`" :size="300" :margin="1" level="M" />-->
					<!--				<br>-->
					<!--				<router-link :to="{name:'paiement', params:{id:links[edit].hash}}" v-if="!links[modal].paye" target="_blank" class="btn btn-outline-dark btn-sm ms-1"><i class="fa fa-eye"></i> Ouvrir la page</router-link>-->
					<!--				&lt;!&ndash;								<a class="btn btn-outline-dark btn-sm ms-1" v-if="!links[modal].paye" @click="modal=i"><i class="fa fa-qrcode"></i></a>&ndash;&gt;-->
					<!--				<a class="btn btn-outline-dark btn-sm ms-1" v-if="!links[modal].paye && links[modal].mail"><i class="fa fa-envelope"></i> Notifier {{ links[modal].mail }}</a>-->
					<!--				<a class="btn btn-outline-dark btn-sm ms-1" v-if="!links[modal].paye && links[modal].tel"><i class="fa fa-message"></i> Notifier {{ links[modal].tel }}</a>-->
				</div>
			</template>
		</modal-right>
		<modal-right
			v-else-if="modal!==null"
			position="center modal-center-left"
			@cancel="modal=null; modal_pending=null">
			<template #title>Lien de paiement : <montant :montant="links[modal].montant" /></template>
			<!--					<template v-slot:default="props">-->
			<div class="text-center py-3">
				<!--				<br>-->
				<!--				{{ app_url }}-->
				<qrcode-vue class="mb-3" style="border-radius:12px" :value="`${app_url}paiement/${links[modal].hash}`" :size="300" :margin="1" level="M" />
				<br>

				<div v-if="!links[modal].paye"><router-link18n :to="{name:'paiement', params:{id:links[modal].hash}}" target="_blank" class="btn btn-outline-dark btn-sm mb-1">Ouvrir la page <i class="fa fa-eye ms-1" /></router-link18n></div>
				<!--								<a class="btn btn-outline-dark btn-sm ms-1" v-if="!links[modal].paye" @click="modal=i"><i class="fa fa-qrcode"></i></a>-->
				<div v-if="!links[modal].paye && links[modal].mail"><btn class="btn-outline-dark btn-sm mb-1" :disabled="modal_pending==='mail'" :fa="modal_pending==='mail' ? 'circle-notch fa-spin':'envelope'" :label="`Notifier ${links[modal].mail}`" @click="notifier(links[modal].id, 'mail')" /></div>
				<div v-if="!links[modal].paye && links[modal].tel"><btn class="btn-outline-dark btn-sm mb-1" :disabled="modal_pending==='tel'" :fa="modal_pending==='tel' ? 'circle-notch fa-spin':'message'" :label="`Notifier ${links[modal].tel}`" @click="notifier(links[modal].id, 'tel')" /></div>
				<!--					</template>-->
			</div>
		</modal-right>
	</div>

</template>

<script lang="ts">
import QrcodeVue from 'qrcode.vue'
import Xhr from "/src/shared/Xhr"

export default {
	components: {
		QrcodeVue,
	},
	data(){
		return {
			du:null,
			au:null,

			form:null,
			links:null,
			stripe_url:import.meta.env.VITE_STRIPE,

			modal:null,
			modal_pending:null,//Pour afficher btn loading sur notifier mail/tel. Value  :mail/tel
			edit:null,
		}
	},
	created() {
		this.initForm();

		var du = new Date();
		du.setDate(1);
		this.du = du.toISOString().substring(0,10);

		var au = new Date();
		au.setDate(0);
		au.setMonth(au.getMonth()+1);
		this.au = au.toISOString().substring(0,10);

		this.loadPi();
	},
	computed:{
		app_url(){
			return import.meta.env.VITE_APP_URL;
		},
		linksPayes(){
			if(!this.links) return null;
			return this.links.filter(link => link.paye).reduce((acc, link) => {
				return acc + link.montant;
			}, 0);
		},
		linksTotal(){
			if(!this.links) return null;
			return this.links.reduce((acc, link) => {
				return acc + link.montant;
			}, 0);
		}
	},
	methods: {
		initForm(){
			this.form = {
				id:null,
				hash:null,
				montant:null,
				message:null,
				prenom:null,
				nom:null,
				mail:null,
				tel:null,
				created:null,
				customer:null,
				intent:null,
				paye:null,
				event:null,
			}
		},
		loadPi(){
			Xhr('GET', `stripelinks/links?du=${this.du}&au=${this.au}`).then((data)=>{
				this.links = data.links;
				this.du = data.du;
				this.au = data.au;
			});
		},

		saveLink(){
			Xhr('POST', `stripelinks`, this.form).then((data)=>{
				this.links.push(data.data);
				this.modal = this.links.length-1;
				this.initForm();
			});
		},
		saveEditLink(){
			Xhr('PUT', `stripelinks/${this.edit.id}`, this.edit).then((data)=>{
				this.links[ this.edit.index ] = data.data;
				this.edit = null;
			});
		},
		notifier(id, type){
			this.modal_pending = type;
			Xhr('POST', `stripelinks/notifier/${id}/${type}`).then((data)=>{
				if(data.code!==200) window.alert("Erreur :"+data.e);
				this.modal_pending = false;
			});
		},
		deleteLink(i){
			let id = this.links[i].id;
			Xhr('DELETE', `stripelinks/${id}`).then((data)=>{
				if(data.delete) this.links.splice(i, 1);
			});
		},
		commander(){
			// demande au serveur OD de créer un PaiementIntent pour ce customer
			// qund récupère le client_secret, Ajouter le Payment Element à cette page / une autre page de paiement
			Xhr('GET', `paniers/stripeintent/${this.cartStore.id}`).then((data)=>{
				this.intent_id = data.intent_id;
				this.client_secret = data.client_secret;
				this.customer_id = data.customer_id;
			});
		},
	}
}
</script>
