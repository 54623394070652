export default function(link){
	return new Promise((resolve, reject)=>{
		let domScript = document.querySelector(`script[src="${link}"]`)
		if(!domScript){
			domScript = document.createElement('script')
			domScript.src = link;
			domScript.async = true;
			document.head.append(domScript)
			domScript.addEventListener('error', ()=>{
				reject();
			})
			domScript.addEventListener('load', ()=>{
				resolve();
			})
		}else{
			resolve();
		}
	})
}
