<template>
	<router-link v-bind="$props" :to="computedTo"><slot /></router-link>
</template>
<script>
	export default {
		props: {
			to: {},
			tag: {},
			exact: {},
		},
		computed:{
			computedTo(){
				let to = this.to;
				if(to && typeof to==="object"){
					let params = to.params;
					to.params = Object.assign({}, params ? to.params : {}, {lang:this.$i18n.locale});
				}
				return to;
			}
		}
	};
</script>
