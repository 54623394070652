<template>
	<div class="btns d-flex">
		<slot />

		<btn
			v-if="cancel"
			color="link text-muted ps-0"
			@click="$emit('cancel')"
			:label="labelcancel" />

		<div class="flex-grow-1"></div>

		<slot name="between" />

		<btn
			v-if="save"
			type="submit"
			color="primary"
			:disabled="disabled"
			@click="$emit('save')"
			:fa="fasave"
			:label="labelsave" />

		<btn-delete v-if="remove" @remove="$emit('remove')" :label="false" />

		<slot name="after" />
	</div>
</template>
<script>
	export default {
		props: {
			save: {default: true, type:Boolean},
			cancel: {default: true},
			fasave: {default: null},
			labelsave: {default: 'Enregistrer'},
			labelcancel: {default: 'Annuler'},
			remove: {default: false},
			disabled: {default: false},
		},
		emits:['cancel','save','remove']
	}
</script>
