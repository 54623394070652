<template>
		<div class="produit-card d-flex flex-column" v-if="data">
<!--			@click="openModal"-->
			<div class="flex-grow-1 d-flex flex-column">
				<router-link18n :to="{name:'produit', params:{id:data.id, slug:data.slug}}">
					<visuel :hover="true" v-if="!data.visuels || data.visuels.length===0" :produit_id="data.id" :size="10" />
					<visuel :hover="true" v-else :visuel_id="data.visuels[0].id" :size="10" />
<!--					<div v-else style="z-index: 2" class="position-relative">-->
<!--						<Flicking :options="{ align: 'prev', circular: true }" :plugins="plugins">-->
<!--							<visuel-->
<!--								v-for="visuel in data.visuels"-->
<!--								:key="visuel.id"-->
<!--								:visuel_id="visuel.id"-->
<!--								:size="10"-->
<!--								:alt="data.label"-->
<!--								class="w-100" />-->
<!--							<template #viewport>-->
<!--								<div class="flicking-pagination" @click.prevent.stop></div>-->
<!--							</template>-->
<!--						</Flicking>-->
<!--					</div>-->
				</router-link18n>
				<div class="card-body px-2X py-3X px-sm-3X pb-sm-1X mt-sm-2X mt-2">
					<div
						style="box-shadow: 0 0 10px #00000010; opacity:0.75"
						class="badge bg-primary fw-normal text-black bg-opacity-10 text-uppercase position-absolute m-3 top-0 start-0">{{ data.gamme?.tag?.label }}</div>
<!--					<div class="badge favorit position-absolute m-3 top-0 end-0 text-danger bg-light rounded-5 p-0 text-center shadow-sm"-->
<!--						style="height:30px;width:30px;line-height:30px">-->
<!--						<i class="far fa-heart"></i>-->
<!--					</div>-->

					<router-link18n :to="{name:'produit', params:{id:data.id, slug:data.slug}}">
						<h1 class="card-title">{{ data.label }}</h1>

						<div class="px-2X px-sm-3X mb-2X my-sm-3X mt-1">
							<div v-for="(declinaison, d) in produit.declinaisons" :key="declinaison.id">
								<div class="d-flex mb-1 align-items-center">
									<div class="flex-grow-1">
										<span class="d-none d-md-inline-block me-sm-2">{{ declinaison.conteneur }}</span>
										<span>{{ declinaison.label }}</span></div>

									<div class="text-end lh-1">
										<div v-if="declinaison.prix_initial_ht">
											<montant-httc
												class="small fs-lg fw-boldX text-danger text-decoration-line-through"
												field="prix_initial"
												:montants="declinaison" />
										</div>
										<montant-httc :montants="declinaison" />
									</div>
								</div>
<!--								<panier-btns-->
<!--									:declinaison_id="declinaison.id"-->
<!--									:display_stock="false"-->
<!--									:display_add="true"-->
<!--									:display_btns="false"-->
<!--									:modal="true"-->
<!--									class="mx-autoX text-centerX"-->
<!--									bclass="px-4X w-100">-->
<!--								</panier-btns>-->
							</div>
						</div>

						<div
							v-if="data.description && desc"
							class="mt-1"
							v-text="data.description.split('.').slice(0, 1).join('.')+'.'" />
					</router-link18n>
					<div class="flex-grow-1"></div>
				</div>
				<div class="px-2X px-sm-3X mb-2X my-sm-3X mt-3">
					<div v-for="(declinaison, d) in produit.declinaisons" :key="declinaison.id">
<!--						<div class="d-flex mb-2">-->
<!--							<div class="flex-grow-1">-->
<!--								<span class="d-none d-sm-inline-block me-sm-2">{{ declinaison.conteneur }}</span>-->
<!--								<span>{{ declinaison.label }}</span></div>-->
<!--							<div><montant-httc :montants="declinaison" /></div>-->
<!--						</div>-->
						<panier-btns
							:declinaison_id="declinaison.id"
							:display_stock="false"
							:display_add="true"
							:display_btns="false"
							:modal="true"
							@add="$emit('add')"
							class="mx-autoX text-centerX"
							bclass="px-4X w-100">
						</panier-btns>
					</div>
				</div>
			</div>

<!--			<modal-right :position="modal" v-if="modal" @cancel="modal=false">-->
<!--				<template #title>{{ data.label }}</template>-->



<!--				<template v-slot:body="props">-->

<!--					<div class="p-4 flex-grow-1">-->
<!--						<div-->
<!--							v-if="data.description"-->
<!--							v-text="data.description"-->
<!--							class="mb-3" />-->

<!--						<div v-if="(data.utilisation || data.conseils.length>0)">-->
<!--							<b>Conseils d'application</b>-->
<!--							<div v-if="data.utilisation" class="mb-1">{{ data.utilisation }}</div>-->
<!--							<div v-if="data.conseils" class="mb-3">-->
<!--								<div v-for="(produitconseil, i) in data.conseils" :key="produitconseil.id" class="mb-1">-->
<!--									<u>{{ produitconseil.conseil.label }}</u> : {{ produitconseil.conseil.texte }}.-->
<!--								</div>-->
<!--							</div>-->
<!--						</div>-->

<!--						<div v-if="data.peau?.tag?.id" class="mb-3">-->
<!--							<b>Type de peau</b>-->
<!--							<div>{{ data.peau.tag.label }} {{ data.peau.tag.description }} {{ data.peau.tag.ref }}</div>-->
<!--						</div>-->

<!--						<div v-if="data.principesactifs && data.principesactifs.length>0" class="mb-3">-->
<!--							<b>Principes actifs</b>-->
<!--							<div v-for="produitprincipeactif in data.principesactifs" :key="produitprincipeactif.id">-->
<!--								<u>{{ produitprincipeactif.principeactif.label }}</u> :-->
<!--								<span>{{ produitprincipeactif.principeactif.description.split('<b>')[1].split('</b>')[0] }}.</span>-->
<!--							</div>-->
<!--						</div>-->

<!--						<div class="mb-3">-->
<!--							<div v-for="(declinaison, d) in produit.declinaisons" :key="declinaison.id">-->
<!--								<panier-btns-->
<!--									@add="props.closeModal"-->
<!--									:declinaison_id="declinaison.id"-->
<!--									:display_stock="false"-->
<!--									:display_add="true"-->
<!--									:display_btns="false"-->
<!--									:modal="true"-->
<!--									bclass="w-100">-->
<!--									<span class="d-inline-block me-2">{{ declinaison.conteneur }}</span>-->
<!--									<span>{{ declinaison.label }}</span>-->
<!--									<div class="fw-bolder"><montant-httc :montants="declinaison" /></div>-->
<!--								</panier-btns>-->
<!--							</div>-->
<!--						</div>-->

<!--						<div class="mt-4 pt-2 mb-4">-->
<!--							<visuel-->
<!--								v-for="visuel in data.visuels"-->
<!--								:key="visuel.id"-->
<!--								:visuel_id="visuel.id"-->
<!--								:size="10"-->
<!--								:alt="data.label"-->
<!--								style="border-radius: 12px"-->
<!--								class="w-100 mb-3" />-->
<!--						</div>-->

<!--						<div v-if="data.ingredients" class="mb-3 small text-muted">-->
<!--							<b>Ingrédients</b>-->
<!--							<div class="">{{ data.ingredients }}</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</template>-->
<!--			</modal-right>-->
		</div>
</template>
<!--<style lang="scss">-->
<!--.flicking-pagination{-->
<!--	bottom:0px-->
<!--}-->
<!--</style>-->
<style lang="scss" scoped>
	@import '/src/assets/variables.scss';

	.produit-card{
		display: block;
		//background: getColor('white');
		color: getColor('black');
		//padding:15px;
		//border-radius: 12px;
		position: relative;
		overflow: hidden;
		a{
			color: getColor('black');
			text-decoration: none;
		}
		//.favorit{
		//	&:hover{
		//		background: red!important;
		//	}
		//}
		.card-title{
			font-size: 1.2em;
		}
		.card-body{
			.badge{
				z-index:2;
				backdrop-filter: blur(10px);
			}
		}
		.footer{
			padding: 0 12px 12px;
		}
	}
</style>

<script lang="ts">
import {useProductStore} from "../stores/products";
import {useCartStore} from "../stores/cart";
// import { AutoPlay, Pagination } from "@egjs/flicking-plugins";

export default {
	props:{
		produit:{default:null},//deprecated
		produit_id:{default:null},//new
		desc:{default:true},
	},
	data(){
		return {
			cartStore: useCartStore(),
			produitsStore: useProductStore(),
			// plugins:[
				// new Pagination({ type: 'bullet' }),
				// new AutoPlay({ duration: 4000, direction: "NEXT", stopOnHover: true }),
				// new Arrow(),
			// ],
			modal:false,
		}
	},
	emits:['add'],
	computed:{
		data(){
			if(this.produit) return this.produit;
			return this.produitsStore.produit(this.produit_id);
		}
	},
	// methods:{
	// 	openModal(e){
	// 		this.modal = `center modal-center-${e.clientX > window.innerWidth/2 ? 'left':'right'}`;
	// 	},
	// }
}
</script>
