const mode = import.meta.env.MODE;
const prod = import.meta.env.PROD; // DEV

// Vue
import { createApp } from 'vue/dist/vue.esm-bundler';
import { defineAsyncComponent} from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

// Style
import './assets/main.scss'

// i18n
import { createI18n } from 'vue-i18n'
const app = createApp(App);

// Store
const pinia = createPinia();
app.use(pinia);

// Router
import router from './router'
app.use(router);

// i18n
import fr from "/src/locales/fr.js";
import en from "/src/locales/en.js";
import es from "/src/locales/es.js";

// Current locale
var current = null;
if(!current) {
	// Locale from Url
	let urlLanguage = window.location.href.split('/')[3];
	let urlLanguageOk = ['fr', 'en', 'es'].indexOf(urlLanguage) > -1;
	if(urlLanguageOk) current = urlLanguage;
	// console.log("1- urlLanguage", urlLanguage, urlLanguageOk);
}
if(!current){
	// Locale from Browser user config
	let userLanguage = navigator.language.split('-')[0];
	let userLanguageOk = ['fr','en', 'es'].indexOf(userLanguage)>-1;
	if(userLanguageOk) current = userLanguage;
	// console.log("2- userLanguage", userLanguage, userLanguageOk);
}
if(!current){
	// Default
	current = 'fr';
	// console.log("3- defaultLanguage", current);
}
const i18n = createI18n({
	locale: current, // set current locale
	fallbackLocale: 'fr',
	messages:{ fr, en, es },
	legacy: false,
})
app.use(i18n);

// i18n Link Component
import RouterLink18n from '/src/components/router-link18n.vue'
app.component('RouterLink18n', RouterLink18n);

// Cookies
import VueCookies from 'vue3-cookies'
app.use(VueCookies, {
// globalCookiesConfig({
	expireTimes: "30d",
	path: "/",
	domain: window.location.hostname,
	// secure: true,
	// sameSite: "None"
});

// Chart (>ad)
// import VueApexCharts from "vue3-apexcharts";
// app.use(VueApexCharts);

// Meta Tag
import { createMetaManager, defaultConfig } from 'vue-meta'
app.use(createMetaManager(false, {
	...defaultConfig,
	meta: { tag: 'meta', nameless: true },
}));
import { plugin as vueMetaPlugin } from 'vue-meta'
app.use(vueMetaPlugin)


// Components
import ModalRight from '/src/components/modal-right.vue'
import Btn from '/src/components/btn.vue'
import BtnShare from '/src/components/btn-share.vue'
import BtnDelete from '/src/components/btn-delete.vue'
import FormBtns from '/src/components/form-btns.vue'
import ProduitCardInline from "/src/components/produit-card-inline.vue";
import ProduitCard from "/src/components/produit-card.vue";
import FormatMontant from "/src/components/format-montant.vue";
const FormatMail = defineAsyncComponent(() => import(/*webpackChunkName:"format"*/ '/src/components/format-mail.vue'))
const FormatTel = defineAsyncComponent(() => import(/*webpackChunkName:"format"*/ '/src/components/format-tel.vue'))
import FormatMontantHttc from "/src/components/format-montant-httc.vue";
import FormatDate from "/src/components/format-date.vue";
import Collaps from "/src/components/collaps.vue";
import Divflex from "/src/components/divflex.vue";
import CartCardSkeleton from "/src/components/cart-card-skeleton.vue";
import Logo from "/src/components/logo.vue";
import Visuel from "/src/components/visuel.vue";
import PanierBtns from "/src/components/panier-btns.vue";
import Tabs from "/src/components/tabs.vue";
const TableDynamic = defineAsyncComponent(() => import(/*webpackChunkName:"table"*/ '/src/components/table-dynamic.vue'))
const Loader = defineAsyncComponent(() => import(/*webpackChunkName:"loading"*/ '/src/views/auth/loader.vue'))
const DivLoading = defineAsyncComponent(() => import(/*webpackChunkName:"loading"*/ '/src/components/div-loading.vue'))
const CheckoutItem = defineAsyncComponent(() => import(/*webpackChunkName:"checkout"*/ '/src/pages/checkout-item.vue'))
import MyTitle from "@/views/my/my-title.vue";
const Sdata = defineAsyncComponent(() => import(/*webpackChunkName:"sdata"*/ './components/sdata.vue'))
const Faq = defineAsyncComponent(() => import(/*webpackChunkName:"faq"*/ './components/faq.vue'))
const PanierConditions = defineAsyncComponent(() => import(/*webpackChunkName:"panier"*/ './views/panier-conditions.vue'))

app.component('ModalRight', ModalRight)
	.component('Collaps', Collaps)
	.component('ProduitCardInline', ProduitCardInline)
	.component('ProduitCard', ProduitCard)
	.component('CartCardSkeleton', CartCardSkeleton)
	.component('Date', FormatDate)
	.component('Montant', FormatMontant)
	.component('FormatMail', FormatMail)
	.component('FormatTel', FormatTel)
	.component('MontantHttc', FormatMontantHttc)
	// .component('AdresseInput', AdresseInput)
	.component('TableDynamic', TableDynamic)
	.component('Divflex', Divflex)
	.component('Btn', Btn)
	.component('BtnDelete', BtnDelete)
	.component('FormBtns', FormBtns)
	.component('Logo', Logo)
	.component('Visuel', Visuel)
	.component('Tabs', Tabs)
	.component('PanierBtns', PanierBtns)
	.component('BtnShare', BtnShare)
	.component('Loader', Loader)
	.component('DivLoading', DivLoading)
	.component('CheckoutItem', CheckoutItem)
	.component('MyTitle', MyTitle)
	.component('Sdata', Sdata)
	.component('Faq', Faq)
	.component('PanierConditions', PanierConditions)


// FILTERS
import {ucfirst,uc,nd,de,du,la,au,le,numero,deci,iban,etage,join,suivi,suivifin,cadastre,montant,duree} from '/src/shared/utils'
app.config.globalProperties.$filters = {
	ucfirst,
	uc,
	nd,
	de,
	du,
	la,
	au,
	le,
	numero,
	deci,
	iban,
	// datefr,
	etage,
	join,
	suivi,
	suivifin,
	cadastre,
	montant,
	duree,//durée livraison
}

// COMPONENTS - FORM & INPUTS
// import VueForm from './components/form/Form.vue'
// import VueInput from './components/form/vue-input.vue'
// const VueField = defineAsyncComponent({
// 	component: import(/*webpackChunkName:"form"*/ /* webpackPrefetch: true */ './components/form/FormField.vue'),
// 	loading: LoadingComponent,
// 	error: ErrorComponent,
// 	timeout: 2000
// })
import VueForm from './components/form/vue-form.vue'
import VueField from './components/form/vue-field.vue'
// const VueFieldAdd = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-field-add.vue'))
import VueInput from './components/form/vue-input.vue'
import VueInputEmail from './components/form/vue-input-email.vue'
const VueInputDate = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-date.vue'))
const VueInputTextarea = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-textarea.vue'))
// const VueTextareaHash = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-textarea-hash.vue'))
const VueInputFile = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-file.vue'))
// const VueInputSign = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-sign.vue'))
const VueInputList = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-list.vue'))
const VueInputSwitch = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-switch.vue'))
const VueInputSwitch3 = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-switch3.vue'))
const VueInputSelect = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-select.vue'))
const VueInputAdresse = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-adresse.vue'))
const VueInputDuau = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-duau.vue'))
const VueInputTel = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-tel.vue'))
const VueInputCheck = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-checkboxs.vue'))
// const VueInputMontant = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-montant.vue'))
// const VueInputSliderminmax = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-sliderminmax.vue'))
const VueInputMultiline = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-multiline.vue'))
const VueInputVisuel = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ './components/form/vue-input-visuel.vue'))
app.component('VueForm', VueForm)
	.component('VueField', VueField)
	// .component('VueFieldAdd', VueFieldAdd)
	.component('VueInput', VueInput)
	.component('VueInputText', VueInput)
	.component('VueInputEmail', VueInputEmail)
	// .component('VueInputEmail', VueInput)
	.component('VueInputPassword', VueInput)
	// .component('VueInputTextarea', VueInput)
	.component('VueInputTextarea', VueInputTextarea)
	// .component('VueInputTextareaHash', VueTextareaHash)
	.component('VueInputNumber', VueInput)
	.component('VueInputDate', VueInputDate)
	// .component('VueInputDatetime', VueInputDate)
	.component('VueInputTime', VueInput)
	.component('VueInputFile', VueInputFile)
	// .component('VueInputSign', VueInputSign)
	.component('VueInputList', VueInputList)
	.component('VueInputSwitch', VueInputSwitch)
	.component('VueInputSwitch3', VueInputSwitch3)
	.component('VueInputAdresse', VueInputAdresse)
	.component('VueInputDuau', VueInputDuau)
	// .component('VueInputTel', VueInput)
	.component('VueInputTel', VueInputTel)
	.component('VueInputSelect', VueInputSelect)
	.component('VueInputCheck', VueInputCheck)
	// .component('VueInputMontant', VueInputMontant)
	// .component('VueInputSliderminmax', VueInputSliderminmax)
	.component('VueInputVisuel', VueInputVisuel)
	.component('VueInputMultiline', VueInputMultiline);

const InputPassword = defineAsyncComponent(() => import(/*webpackChunkName:"form"*/ '/src/views/auth/input-password.vue'))
app.component('InputPassword', InputPassword);


// FORM - INPUT TEL
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
app.use(VueTelInput);

// DIRECTIVES
// import { directive as onClickOutside } from 'vue-on-click-outside'
import onClickOutside from '/src/shared/directives/click-outside'
app.directive('on-click-outside', onClickOutside);


// Tooltip
import FloatingVue from 'floating-vue'
app.use(FloatingVue);
import 'floating-vue/dist/style.css'

// Flicking
// import Flicking from "../dist/flicking.esm";
// import Flicking from "@egjs/vue3-flicking";
// import "@egjs/vue3-flicking/dist/flicking.css";
// eslint-disable-next-line vue/multi-word-component-names
// app.component("Flicking", Flicking);

// Splide
import '@splidejs/vue-splide/css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
app.component("Splide", Splide);
app.component("SplideSlide", SplideSlide);

// RRWEB
import { v4 as uuid } from 'uuid';
if(!LocalStorage.in('od_play')) LocalStorage.set('od_play', uuid());
var rr_session = LocalStorage.get('od_play') || uuid();
import {addCustomEvent, record, getRecordConsolePlugin} from 'rrweb'
import LocalStorage from "@/shared/LocalStorage";
let rrweb_doms = [];
var rrweb_timeout = null;
var rrweb_doms_pending = false;
record({
	emit(event) {
		rrweb_doms.push(event);
		if(rrweb_doms.length>99) rrweb_save();
		if(rrweb_timeout){
			clearTimeout(rrweb_timeout);
			rrweb_timeout = null;
		}
		rrweb_timeout = setTimeout(rrweb_save, 1000*10)
	},
	// plugins: [
	// 	getRecordConsolePlugin({
	// 		level: ['info', 'log', 'warn', 'error'],
	// 		lengthThreshold: 1000,
	// 		stringifyOptions: {
	// 			stringLengthLimit: 500,
	// 			numOfKeysLimit: 50,
	// 			depthOfLimit: 1,
	// 		},
	// 		logger: window.console,
	// 	}),
	// ],
});
function rrweb_save(){
	if(rrweb_doms_pending) return;
	rrweb_doms_pending = true;
	var count = rrweb_doms.length;
	let body = JSON.stringify(rrweb_doms);
	// console.log(count);
  fetch(`https://play.davidolyster.com/od_${mode}/${rr_session}`, {
		method: 'POST',
		headers: {'Content-Type': 'application/json'},
	  // keepalive: true,
	  // mode: 'no-cors',
		body,
	// }).then((data)=>{
	// 	// console.log('rrweb DOM fetch data', data, count);
	}).finally(()=>{
		rrweb_doms.splice(0, count);
		rrweb_doms_pending = false;
	})
}
window.onbeforeunload = function(){
	addCustomEvent('end', {})
	navigator.sendBeacon(`https://play.davidolyster.com/od_${mode}/${rr_session}`, JSON.stringify(rrweb_doms));
}


app.mixin({
	methods: {
		// Listcol Dynamictable
		setDatasDefault(datas){
			// console.log("setDatasDefault", datas);
			this.datas = datas;
		},
		callbackAddDefault(data){
			// this.datas.push(data);
			// console.log("callbackAddDefault", data);
			if(!data) return;
			this.datas.unshift(data);
			let modelAlias = this.model.alias;
			let toName = this.model.routeDetail ? this.model.routeDetail : modelAlias.toLowerCase();
			// console.log("callbackAddDefault", ">", toName);
			if(toName){
				let paramKey = this.paramKey || 'id';
				let params = {};
				params[paramKey] = data[modelAlias].id;
				this.$router.push({name:toName, params:params});
			}
		},
		callbackUpdateDefault(data){
			this.callbackEditDefault(data, true);
		},
		callbackEditDefault(data, redirect){
			let modelAlias = this.model.alias;
			let i = this.datas.map((d) => d[modelAlias].id).indexOf(data[modelAlias].id);
			if(i>-1){
				this.datas[i] = Object.assign(this.datas[i], data);
			}

			if(redirect){
				let toName = this.model.routeListe ? this.model.routeListe : modelAlias.toLowerCase()+'s';
				if(toName && this.$router.currentRoute.name!==toName){
					this.$router.push({name:toName});
				}
			}

			// let localStorageNom = "dt/"+ this.model.model +'/data';
			// LocalStorage.set(localStorageNom, {paging:LocalStorage.get(localStorageNom).paging, response:this.datas});
		},
		callbackRemoveDefault(ids){
			// id ou array d'id
			// console.log('callbackRemoveDefault', id, typeof id);
			let modelAlias = this.model.alias;

			// Crée tableau d'ids
			if(typeof ids==="string") ids = [ids];

			// Supprime datas
			for(let i=0; i<ids.length; i++){
				let id = ids[i];
				let index = this.datas.map((d) => d[modelAlias].id).indexOf(id);
				this.datas.splice(index, 1);
			}

			// Stock
			// let localStorageNom = "dt/"+ this.model.model +'/data';
			// LocalStorage.set(localStorageNom, {paging:LocalStorage.get(localStorageNom).paging, response:this.datas});

			// Redirige
			let toName = this.model.routeListe ? this.model.routeListe : modelAlias.toLowerCase()+'s';
			// console.log("callbackRemoveDefault", 'toName:', toName, 'current:',this.$router.currentRoute.name);
			if(toName && this.$router.currentRoute.name!==toName){
				this.$router.push({name:toName});
			}
		},
	}
});

// GOOGLE TAG ANALYTICS
import VueGtag from "vue-gtag";
// import { event } from 'vue-gtag'
if(prod){
	app.use(VueGtag, {
		config: { id: import.meta.env.VITE_GTAG_ID }
	// , router
	})
	// event('close', {
	// 	'event_category': 'modal',
	// 	// 'event_label': <label>,
	// 	'value': this.title
	// })
}


// URL
addCustomEvent('route', { path: window.location.href })

// UA
addCustomEvent('ua', { v:window.navigator?.userAgent })

// Thème
if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
	const modeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
	document.body.setAttribute('data-bs-theme', modeMediaQuery.matches ? 'dark':'light');
	addCustomEvent('theme', { v:modeMediaQuery.matches ? 'dark':'light' })
}else{
	addCustomEvent('theme', { v:'not_supported' })
}

// Version Badge Api
// Check if the API is supported (v2)
if ('setExperimentalAppBadge' in navigator) addCustomEvent('badge-version', { v:2 });
// Check if the previous API surface is supported (v1)
if ('ExperimentalBadge' in window) addCustomEvent('badge-version', { v:1 });
// Check if the previous API surface is supported (v3)
if ('setAppBadge' in navigator) addCustomEvent('badge-version', { v:3 });

// Langue
addCustomEvent('locale', { v:current })

// PWA Installée
window.addEventListener('appinstalled', () => {
	addCustomEvent('pwa-installed', {});
});

// PWA Lancée
window.addEventListener('DOMContentLoaded', () => {
	let displayMode = 'browser-tab';
	if (navigator.standalone) displayMode = 'standalone-ios';
	if (window.matchMedia('(display-mode: standalone)').matches) displayMode = 'standalone';
	addCustomEvent('display-mode', {v:displayMode});
});

// ERROR
// window.addEventListener("error", this.handleError, true);
// window.onerror = function (msg, source, lineNo, columnNo, error) {
// 	addCustomEvent('error', {
// 		error:msg,
// 		script:source,
// 		line:lineNo,
// 		column:columnNo,
// 		stackTrace:error
// 	});
// };
//

rrweb_save();

app.mount('#app')
