<template>
	<header class="py-4 py-sm-5" :class="{'position-fixed top-0 w-100':headFixed, 'transparent':headEnd}">
		<div class="container d-flex align-items-center">
			<router-link18n :to="{name:'accueil'}" :aria-label="$t('nav.accueil')" class="d-flex align-items-center">
				<div class="me-3 me-sm-4 d-flex flex-column align-items-center">
					<logo :od="true" height="34px" class="od" />
				</div>
				<div class="me-0 d-flex flex-column align-items-center">
					<logo :od="false" :davidolyster="true" :paris="true" height="16px" class="davidolyster" />
					<logo :od="false" :davidolyster="false" :paris="true" height="10px" class="mt-2 paris" />
				</div>
			</router-link18n>
			<div class="flex-grow-1"></div>

			<btn
				v-if="authStore.hasScope('admin')"
				color="outline-dark btn-sm ms-1"
				:to="{name:'ad'}"
				far="cog" />

			<header-user />

			<langue-switch />

			<header-panier />
			<header-panier-modal />

<!--			<router-link18n v-if="authStore.isLoggedIn" class="btn btn-outline-primary btn-sm ms-1" :to="{name:'signout'}">-->
<!--				<i class="far fa-sign-out"></i>-->
<!--			</router-link18n>-->

		</div>
	</header>
</template>

<script lang="ts">
import LangueSwitch from "./langue-switch.vue";
import HeaderPanier from "./header-panier.vue";
import HeaderPanierModal from "./header-panier-modal.vue";
import HeaderUser from "./header-user.vue";
import { useAuthStore } from "../stores/auth";

export default {
	components:{
		HeaderUser,
		LangueSwitch,
		HeaderPanierModal,
		// HeaderSearch,
		HeaderPanier,
		// LangueSwitch,
		// HeaderBurger,
	},
	data() {
		return {
			authStore: useAuthStore(),
			// scroll:null,
			headEnd:null,
		}
	},
	// beforeRouteUpdate(){
	// 	console.log("beforeRouteUpdate");
	// },
	mounted() {
		document.addEventListener('scroll', this.handleScroll);
		this.$nextTick(()=>{
			this.handleScroll();
		})
	},
	beforeUnmount() {
		document.removeEventListener('scroll', this.handleScroll)
	},
	computed:{
		// headEnd(){

		// },
		routeMeta(){
			// console.log(this.$route);
			return this.$route.meta ? this.$route.meta : null;
		},
		headFixed(){
			let x= false;
			if(this.routeMeta?.head_fixed) x = this.routeMeta?.head_fixed;
			// console.log('this.headFixed',x);
			// eslint-disable-next-line vue/no-async-in-computed-properties
			this.$nextTick(()=>{
				this.handleScroll();
			})
			return x;
		},

	},
	methods:{
		handleScroll(){
			// let a= this.$route.meta?.head_absolute;///head_absolute
			if(!this.headFixed){
				// console.log('handleScroll 0');
				this.headEnd = false;
				return;
			}
			// console.log(window.scrollY)
			// if(window.scrollY>=50){
				this.headEnd = window.scrollY<=50;
			// }
			// if(window.scrollY===0){
			// 	console.log('handleScroll 1');
			// 	this.headEnd = true;
			// 	return;
			// }
			// let b= document.getElementById('header_end')?.getBoundingClientRect().y;
			// console.log('handleScroll 2',b);
			// this.headEnd = b - 120  > 0;

			// console.log("handleScroll", b);
			// this.scroll = b;
		}
	}
}
</script>


<style scoped lang="scss">
@import "/src/assets/variables.scss";


.od{ height:34px }
.davidolyster{ height:16px }
.paris{ height:10px }
@media only screen and (max-width: 400px) {
	.od{ height:27px }
	.davidolyster{ height:12px }
	.paris{ height:7px }
}
@media only screen and (max-width: 340px) {
	.od{ height:24px }
	.davidolyster{ height:10px }
	.paris{ height:6px }
}

header {
	//&:not(.transparent){
	//background: getColor('background');
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background:getColor('background');
		z-index: -1;
		transform: translateY(0%);
		transition: transform ease-in-out 0.25s;
	}
	&.transparent{
		&:before{
			transform: translateY(-100%);
		}
		//background:transparent;
		//background: linear-gradient(to top, transparent, #FFFFFF50);
		//background: linear-gradient(0deg, transparent -20%, white 120%);
		//backdrop-filter: blur(10px);
	}
	//z-index: 0;
	//position: relative;
//>div{
	z-index: 11;
//	//position: relative;
//	background: orange;
//}
//	&:before{
//		content: '';
//		position: absolute;
//		top:0;
//		left: 0;
//		right: 0;
//		bottom: 0;
//		background: red;
//		z-index: -100;
//	}

	position: sticky;
	top: env(safe-area-inset-top, 0);
	padding-top: max(env(safe-area-inset-top, 0), 1em);
	padding-bottom: 1em;

	//color: getColor('white');
	//position: relative;
	overflow: hidden;

	:deep(.btn.router-link-active){
		//background: getColor('whitesmoke');
		color: var(--bs-btn-disabled-color);
		pointer-events: none;
		background-color: var(--bs-btn-disabled-bg);
		border-color: var(--bs-btn-disabled-border-color);
		opacity: var(--bs-btn-disabled-opacity);
	}
	//	top: 8px!important;
	//	left: 90%!important;
	//	padding-bottom: 5px;
	//}

	@include smartphones(){
		overflow: inherit;
		left: 0;
		right: 0;
		position: sticky;
		top: env(safe-area-inset-top, 0);
		//padding-top: env(safe-area-inset-top, 0);
		&:after{
			content:"";
			position: absolute;
			left: 0;
			right: 0;
			top:calc(-1 * env(safe-area-inset-top, 0));
			height: env(safe-area-inset-top, 0);
			background:getColor('primary-color-darken');
		}
	}
}
</style>

