<template>
	<div
		class="field"
		:class="{
			'input': type !== 'textarea',
			'textarea': type === 'textarea',
			'input-prefix': this.$slots.prefix,
			'input-suffix': this.$slots.suffix,
			'input-prepend': this.$slots.prepend,
			'input-append': this.$slots.append,
			'focus':inputFocused,
			'value':modelValue!==null && modelValue!=='',
			'label':label
		}">

		<div v-if="$slots.prefix" class="prefix">
			<slot name="prefix" />
		</div>

		<span v-if="$slots.default" class="slot">
			<slot />
		</span>
		<!--:value.trim	-->
		<input
      :style="styleinput"
			:id="inputId"
			class="inner"
			:class="classinput"
			:name="name"
			:type="type"
			:value="modelValue"
			:placeholder="placeholder"
			:disabled="disabled"
			:readonly="readonly"
			:max="max"
			:min="min"
			:tabindex="tabindex"
			:autocomplete="autocomplete"
			:autocapitalize="autocapitalize"
			@keyup="$emit('keyup', $event)"
			@keydown="$emit('keydown', $event)"
			@blur="onBlur"
			@focus="$emit('focus')"
			@input="onInput">
		<div v-if="$slots.suffix" class="suffix" :class="clas">
			<slot name="suffix" />
		</div>
		<!--		<div v-if="$slots.append && type !== 'textarea'" class="append">-->
		<!--			<slot name="append" />-->
		<!--		</div>-->
	</div>
</template>

<script>
	export default {
		name: 'VueInput',
		emits:['keydown','keyup','focus','blur','inputed'],
		props: {
			label:{},//présence de label
			inputId:{},
			type: { default: 'text' },
			// value: [String, Number],
			modelValue: {},
			classinput: {default:''},//ex size
			name: String,
			readonly: {},
			autocomplete: {},
			autocapitalize: {},
			min: Number,
			max: Number,
			step:{},
			pattern:{},
			placeholder: String,
			tabindex: {},
			disabled: { default: false },
			rows: { default: 3 },
			styleinput: { default:''},//pour email, password code recovery
			sty: { default: null },//pour email
      clas: { default: null },//pour email
		},

		data(){
			return {
				inputFocused:false,
			}
		},

		methods: {
			onBlur(e){
				this.$emit('blur', e.target.value);
				// this.$emit('focused', false);
				// this.inputFocused = false;
			},
			// onFocus(){
			// 	// this.inputFocused = true;
			// 	this.$emit('focus');
			// },
			onInput (e) {
				// if(e.target && e.target.value){
					this.$emit('inputed', e.target.value)
				// }else{
				// 	this.$emit('input', e)
				// }
			}
		}
	}
</script>

<style lang="scss">
	@import '/src/assets/variables.scss';

	.vdp-datepicker__calendar {
		width: 100%;
		max-width: 300px;
		bottom: 100%;
		left: -1px;
		right: -1px;
		margin: auto;
		header {
			line-height: 25px;
		}
		.cell {
			padding: 1px;
			height: inherit;
			line-height: inherit;
			border-radius:10px
		}
	}

	.field{
		display: flex;
		align-items: center;
		input{
			flex-grow: 1;
		}

		.prefix{
			padding-left: 10px;
		}
		.suffix{
			padding-right: 10px;
		}
	}

	.slot{
		padding: 0 10px;
		cursor: not-allowed;
    flex-shrink: 0;
		@extend .ellipsis;
		//@include no-select();
	}

	.input {
		$r: &;
		.suffix {
			white-space: nowrap;
			line-height: 1em;
		}
		&-prefix {
			#{$r}__inner {
				padding-left: 40px;
			}
		}
		&-suffix {
			#{$r}__inner {
				padding-right: 40px;
			}
		}
		&-prepend {
			display: inline-table;
			border-collapse: separate;
			#{$r}__inner {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		&-append {
			display: inline-table;
			border-collapse: separate;
			#{$r}__inner {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
	}
</style>
