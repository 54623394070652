// import {getAccessToken} from './auth'
// import store from '@/store.js'
import {useAuthStore} from "../stores/auth";
import {addCustomEvent} from "rrweb";
// import cat from "countries-and-timezones";
// timezone_country: cat.getCountryForTimezone(this.getTimezone());

function generateXHR(method, path, data={}, header={}, callback=function(){}, xhrLoading=true){
	const xhr = new XMLHttpRequest();
	let timer = new Date().getTime();

	var url = path;
	if(url.substr(0, 4)!="http") url = import.meta.env.VITE_API_URL + url;
	// if(xhrLoading){
	// 	store.commit('loading', { url, status:true});
	// }

	xhr.open(method, url);
	// const xhr_ref = 'xhr-'+url;

	xhr.upload.addEventListener('progress', function(evt){
		callback({event:'progress', loaded:evt.loaded, total:evt.total});
	}.bind(this), false);


	let headerDefault = {
		'Accept':'application/json',
		'Content-Type':'application/json',
		'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
	};
	if(data instanceof FormData && (!header || typeof header['Content-Type']==="undefined")){
		// headerDefault['Content-Type'] = 'multipart/form-data';
		headerDefault['Content-Type'] = null;
		// headerDefault['Content-Type'] = false;
	}

	const authStore = useAuthStore()
	if(authStore.isLoggedIn){
		headerDefault.Authorization = 'Bearer '+authStore.access_token;
	}
	xhr.withCredentials = false;

	// console.log("xhr user",authStore.user.langue);
	if(authStore.user?.langue){
		// console.log("xhr langue user", authStore.user?.langue);
		headerDefault.Language = authStore.user?.langue;
	}else{
		headerDefault.Language = localStorage.getItem("od_language");
	}


	if(header!==false){
		header = Object.assign(headerDefault, header);
		// console.log("Xhr head", header);
		for(var i=0;  i< Object.keys(header).length; i++){
			let val = header[ Object.keys(header)[i] ];
			if(val) xhr.setRequestHeader(Object.keys(header)[i], val);
		}
	}

	if(data instanceof FormData){
		xhr.send(data);
	}else{
		xhr.send(JSON.stringify(data));
	}

	const promise = new Promise(function(resolve, reject){
		xhr.onload = () => {
			// store.commit('loading', { url, status:false});
			let duree = new Date().getTime() - timer;
			if(xhr.status >= 200 && xhr.status < 400 && xhr.response){
				let json = JSON.parse(xhr.response);
				addCustomEvent((json.code==200 ? 'xhr':'xhr-error'), {url:path, t:duree, errors:json.errors, message:json.message})
				return resolve(json);
			}else{
				let json = JSON.parse(xhr.response);
				addCustomEvent('xhr-error', {url:path, t:duree, c:xhr.status})
				return reject(json);
			}
		};
		xhr.onerror = () => {
			addCustomEvent('xhr-error', {url:path})
		};
		xhr.onabort = () => {
			addCustomEvent('xhr-abort', {url:path})
		};
	});

	promise.xhr = xhr;

	promise.abort = function(){
		addCustomEvent('xhr-abort', {url:path})
		return xhr.abort();
	};

	return promise;
}


export default generateXHR;
