<template>
	<div class="container px-2">
		<div>
			<div class="hero pt-4 pt-sm-7 px-0">
				<div class="px-4">

					<template v-if="data && data.link">
						<div v-if="data.link.prenom || data.link.nom || data.link.mail || data.link.tel" class="msg mb-3">
							<div v-if="data.link.prenom || data.link.nom">Bonjour {{ data.link.prenom }} {{ (data.link.nom||'').toUpperCase() }}</div>
							<div class="small mx-auto" v-if="data.link.mail || data.link.tel">
								<span class="d-inline-block mx-2" v-if="data.link.mail"><i class="far fa-envelope me-2" /><format-mail class="msg text-decoration-none" :mail="data.link.mail" /></span>
								<span class="d-inline-block mx-2" v-if="data.link.tel"><i class="far fa-phone me-2" /><format-tel class="msg text-decoration-none" :tel="data.link.tel" /></span>
							</div>
						</div>
					</template>

					<h2 v-if="!id" class="mb-5">Ce lien de paiement est incomplet.</h2>
					<h2 v-else-if="!data" class="mb-5">Chargement du lien ...</h2>
					<h2 v-else-if="!data.link" class="mb-5">Ce lien de paiement n'existe plus ...</h2>
					<div v-else-if="payPendingOrSuccessed" class="mb-5 msg">
						<h2 class="mb-2">Merci pour votre paiement</h2>
						<template v-if="data.link.paye">Votre paiement de <montant :montant="data.link.montant" /> a été enregistré le <date :date="data.link.paye" />.</template>
						<template v-else>Votre paiement de <montant :montant="data.link.montant" /> est en cours de vérification.</template>
					</div>
					<h2 v-else class="mb-4">Votre lien de paiement</h2>
					<!--						{{ pay }}-->
					<!--						{{ data }}-->
					<div v-if="data?.link?.message && displayForm" v-html="data.link.message" class="mb-5 msg" />
					<!--						<div v-if="data.link.created">Créé le : {{ data.link.created }}</div>-->
					<!--						<div v-if="data.link.paye">Payé le : {{ data.link.paye }}</div>-->
					<form v-show="displayForm">
						<div class="row">
							<div class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
								<div id="payment-element" ref="payment-element"></div>
								<div class="text-danger d-flex align-items-baseline mt-3" v-if="errors">
									<i class="fa fa-circle-exclamation me-2"></i>
									<div class="flex-grow-1 text-start">{{ errors }}</div>
								</div>
								<btn
									v-if="displayForm"
									@click="onSubmitForm"
									:disabled="pendingPay"
									:fa="pendingPay ? 'circle-notch fa-spin':null"
									:label="pendingPay ? ``:`Payer ${ data.link.montant/100 } €`"
									color="primary w-100 mt-4 mb-4 btn-lg" />
							</div>
						</div>
					</form>

					<div class="col-10 offset-1 col-lg-8 offset-lg-2 text-center text-danger mb-5 small"
							 v-if="!displayForm && errors">
						<i class="fa fa-circle-exclamation me-2"></i>{{ errors }}</div>

				</div>
				<div class="image" :style="{backgroundImage:`url(${produitModel.url('visuel', 118, null, 'webp')}), linear-gradient(-10deg, #196ca0, transparent 70%)`}"></div>
			</div>
		</div>


<!--		<div v-if="displayForm" class="small text-muted opacity-50 text-center">-->
<!--			<small>-->
<!--				Ok&nbsp;:&nbsp;4242424242424242-->
<!--				|&nbsp;Auth&nbsp;init&nbsp;:&nbsp;4000002500003155-->
<!--				|&nbsp;Auth&nbsp;pay&nbsp;:&nbsp;4000002760003184-->
<!--				|&nbsp;Ko&nbsp;:&nbsp;4000000000009995-->
<!--			</small>-->
<!--		</div>-->
	</div>
</template>

<script lang="ts">

import Xhr from '/src/shared/Xhr.js';
// import CheckoutStripeconfirm from "/src/pages/checkout-stripeconfirm.vue";
import addScript from '/src/composables/script';
import Produit from "../services/Produit";


export default {
	props:{
		id:{default:null}
		// panier_hash:{default:null},
		// intent_id:{default:null},
		// client_secret:{default:null},
		// customer_id:{default:null},
	},
	data(){
		return {
			produitModel:new Produit(),

			publishable_key: import.meta.env.VITE_STRIPE_PKEY,
			stripe:null,
			elements:null,
			errors:null,
			// inputId:"autocomplete"+this.$.uid,
			// payment_intent:null,
			data:null,
			pendingPay:null,
			// client_secret:null,//pour modal paiement
			// intent_id:null,//pour modal paiement
			// customer_id:null,//pour modal paiement
		}
	},
	async mounted(){
		const link = "https://js.stripe.com/v3/";
		await addScript(link);
		this.commander()
	},
	// created() {
	// },
	computed:{
		pay(){
			// "payment_intent": "pi_3O1uMHDHL8XiWsbf09dbNjd6"
			// "payment_intent_client_secret": "pi_3O1uMHDHL8XiWsbf09dbNjd6_secret_RuebOZKCkeMa1Tg7d1ZZy8TxG"
			// "redirect_status": "succeeded"
			return this.$route.query
		},
		displayForm(){
			if(!this.id) return null;
			if(!this.data || !this.data.client_secret) return null;
			if(this.pay.payment_intent_client_secret) return null;
			return true;
		},
		payPendingOrSuccessed(){
			if(this.pay.payment_intent_client_secret) return true;
			if(this.data && this.data.link.paye) return true;
			if(this.data && this.data.pi_status==='succeeded') return true;
			return null;
		}


	},
	methods: {
		commander(){
			// demande au serveur OD de créer un PaiementIntent pour ce customer
			// qund récupère le client_secret, Ajouter le Payment Element à cette page / une autre page de paiement
			Xhr('GET', `stripelinks/intent/${this.id}`).then((data)=>{
				if(data.code===200) {
					this.data = data;
					this.initPay();
				}else{
					this.errors = data.message;
				}
			});
		},
		initPay(){
			if(!this.displayForm) return;

			let stripe = this.stripe = Stripe(this.publishable_key);
			const options = {
				clientSecret: this.data.client_secret,
				appearance: {/*...*/},
			};
			console.log("initPay", options);

			// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
			const elements = this.elements = stripe.elements(options);
			// Create and mount the Payment Element
			const paymentElement = elements.create('payment');
			paymentElement.mount('#payment-element');
		},
		async onSubmitForm(){
			if(!this.displayForm) return;
			this.pendingPay = true;

			let stripe = this.stripe;
			let elements = this.elements;
			const {error} = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements,
				confirmParams: {
					return_url: `${import.meta.env.VITE_APP_URL}paiement/${this.id}`,
				},
			});

			if (error) {
				// This point will only be reached if there is an immediate error when
				// confirming the payment. Show error to your customer (for example, payment
				// details incomplete)
				console.log(error);
				// const messageContainer = document.querySelector('#error-message');
				this.errors = error.message;
				this.pendingPay = null;
			} else {
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '/src/assets/variables.scss';

.hero{
	background: linear-gradient(90deg, rgb(174, 202, 217), rgb(183, 207, 221), rgb(168, 196, 210));
	border-radius: 12px;
	overflow: hidden;
	text-align: center;
	h2,
	.msg{
		color: getColor('grey');
	}
	.image{
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		object-fit: contain;
		pointer-events: none;
		aspect-ratio: 2 / 1;
		min-height: 250px;
	}
}
</style>
