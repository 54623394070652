<template>
	<a :class="`btn btn-${color}`" v-if="href" :href="href" target="_blank" :aria-label="site">
		<i :class="`${fab}`"></i>
	</a>
	<btn :defaultclass="`rounded-1 btn btn-`" :color="color" v-else @click="onClick" :aria-label="site">
		<i :class="`${fab}`"></i>
	</btn>
</template>
<script>
import {clipboard} from "@/shared/utils";

export default{
	name:'BtnShare',
	props:{
		site:{default:null},
		url:{default:null},
		media:{default:null},
		text:{default:null},
		description:{default:null},
		color:{default:'light'},
	},
	emits:['click'],
	computed: {
		fab() {
			switch(this.site){
				case 'facebook': return 'fab fa-facebook-f';
				case 'twitter': return 'fab fa-x-twitter';
				case 'pinterest': return 'fab fa-pinterest-p';
				case 'linkedin': return 'fab fa-linkedin-in';
				case 'tumblr': return 'fab fa-tumblr';
				case 'whatsapp': return 'fab fa-whatsapp';
				case 'mail': return 'far fa-envelope';
				case 'link': return 'far fa-link';
			}
			return null;
		},
		href() {
			let url = this.url;
			if(!url) url = window.location.href;
			switch(this.site){
				case 'facebook': return `https://www.facebook.com/sharer.php?u=${url}&t=${this.text} - DAVID OLYSTER`;
				case 'twitter': return `https://twitter.com/share?url=${url}&text=${this.text} - DAVID OLYSTER`;
				case 'pinterest': return `https://pinterest.com/pin/create/button/?url=${url}&media=${this.media}&description=${this.text} - DAVID OLYSTER`;
				case 'linkedin': return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${this.text} - DAVID OLYSTER`;
				case 'tumblr': return `https://www.tumblr.com/share/link?url=${url}&name=${this.text} - DAVID OLYSTER`;//&description=${this.description}`;
				case 'whatsapp': return `https://api.whatsapp.com/send?text=${url}&type=custom_url`
				case 'mail': return `mailto:?subject=${this.text} - DAVID OLYSTER&body=${url}`
				// https://www.reddit.com/submit?url=${url}&title={this.text}
				// https://story.kakao.com/share?url=${url}
				// https://www.blogger.com/blog-this.g?n=${this.text}&source=youtube&b=<a href=...></a>&eurl=${this.media}&pli=1
			}
			return null;
		},
	},
	methods:{
		onClick(){
			let url = this.url;
			if(!url) url = window.location.href;
			switch(this.site){
				case 'link': clipboard(url);
			}
		}
	}
}
</script>
