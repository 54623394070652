<template>
<!--	<div>-->
<!--		<div class="containerX px-2X">-->
<!--			<div class="hero text-start text-sm-center pt-4 pt-sm-5 pb-0">-->
<!--				<div class="row pt-7">-->
<!--					<div class="col-12 col-sm-10 offset-sm-1 col-xl-8 offset-xl-2">-->
<!--						<h1 class="px-4">{{ $t("home.hero.collection_caviar") }}</h1>-->
<!--						<h2 class="px-4 mb-3" v-html="$t('home.hero.gamme_soins_pour_lutter_signes_age')"></h2>-->
<!--					</div>-->
<!--				</div>-->
<!--				<div class="image" :style="{backgroundImage:`url(${produitModel.url('visuel', 118, null, 'webp')}), linear-gradient(-10deg, #196ca0, transparent 70%)`}"></div>-->
<!--			</div>-->
<!--		</div>-->

<!--		backgroundImage:`url(${produitModel.url('visuel', 118, null, 'webp')}), linear-gradient(90deg, rgb(174, 202, 217), rgb(183, 207, 221), rgb(168, 196, 210)); height:100vh; max-height:100vh`-->
		<div>
			<div class="hero text-center pt-4 pt-sm-7 pb-0">
				<div class="container pt-7">
					<div class="row">
						<div class="col-12 col-sm-10 offset-sm-1 col-xl-12 offset-xl-0">
							<h1 class="px-4X">{{ $t("home.hero.collection_caviar") }}</h1>
							<h2 class="px-4X mb-3" v-html="$t('home.hero.gamme_soins_pour_lutter_signes_age')"></h2>
						</div>
					</div>
				</div>
				<div class="image image-collection" role="img" :aria-label="$t('home.hero.collection_caviar')">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 400" style="height: 100%; transform: translateX(calc((100vw - 100%)/2));">
						<path
							@click="$router.push({name:'produit', params:{id:3, slug:produit(3).slug}})"
							fill="transparent" d="M305,356.7V374c0,0,21.6,2.6,60.8,2.6s59.7-10,59.7-10v-58.4c0,0-3.7-2.1-7.9-3.4
	c-4.2-1.3,1.3-7.1,1.3-7.1v-17.4c0,0-10.9-1.2-24.9-2c-4-12.2-11.3-4.1-14-13.5c-2.1,6.1-7.3,12.7-11.8,12.8
	c-23.7,0.3-29,0.6-29,0.6l-25.8-19.8c0,0-21.7-8.7-51.8,30.8c-39.4,51.8-19.7,67.1-19.7,67.1l25.5,17.6c0,0,9.2,3.2,21.6-5.3
	C301.3,360.3,305,356.7,305,356.7z"/>
						<path
							@click="$router.push({name:'produit', params:{id:7, slug:produit(7).slug}})"
							fill="transparent" d="M457.9,357.9c0-0.2-0.1-0.3-0.1-0.3l-2.1-328.2c0,0-7.4-1.4-26-1.4c-18.6,0-31.5,1.4-31.5,1.4
	l-1.4,102.2l0.2,146.9c12.6,0.8,22,1.8,22,1.8v17.4c0,0-5.5,5.8-1.3,7.1s7.9,3.4,7.9,3.4v58.4c0,0-0.1,0.1-0.4,0.2
	c9.9-0.1,19.7-0.6,24.1-1.8c6.6-1.8,8.4-4.2,8.7-5.8L457.9,357.9z"/>
						<path
							@click="$router.push({name:'produit', params:{id:6, slug:produit(6).slug}})"
							fill="transparent" d="M522,23l5.2,102l2.8,241.9c0,0-7.7,8.7-35.8,9.8c-28,1.1-36.3-5.4-36.3-5.4L455.7,23
	c0,0,19.8-2.8,33.7-2.8S522,23,522,23z"/>
						<path
							@click="$router.push({name:'produit', params:{id:4, slug:produit(4).slug}})"
							fill="transparent" d="M631.2,365l-0.1-77l10.7-2.7v-13c0,0-15.5-3.3-57.6-3.3c-33.8-0.2-39.6,1.5-55.3,2.6l1.1,95.2
	c0,0-0.5,0.5-1.5,1.3c4.4,1.9,17.2,5.2,53.7,5.2c29.8,0,45.1-2.5,52.7-4.7C632,366.7,631.2,365,631.2,365z"/>
						<path
							@click="$router.push({name:'produit', params:{id:5, slug:produit(5).slug}})"
							fill="transparent" d="M694.4,369.9v-80.6c0,0-2.6-4.5-12-3.7c-3.9,0.3-1.7-34.3-1.7-34.3l-12.9-0.8l6.9-7.1l41.4,28.3
	c0,0,11.4-0.3,13.4,3.7c2,4-3.7,15.6,3.1,15.4c10.2-0.3,1.4-12.6,3.7-15.4c2.3-2.9,5.7-8.9,0-12.6c-5.7-3.7-9.4-1.7-10-4.3
	c-0.6-2.6-43.4-29.1-43.4-29.1s7.1-8.9,4.9-15.4c-2.3-6.6-34-25.4-34-25.4s-7.1,1.7-10,7.1c-2.9,5.4-10.9-13.7-20.9-16.9
	s-16.3,0.9-19.7,6c-3.4,5.1-4.9,20.6,7.1,25.4c12,4.9,16.9,2.6,17.8,6.5c1.1,5.2-4,5.8-3,12.2c0.5,3.1,29.8,22.6,29.8,22.6
	s-10.2-1.2-11.1,1.3c-0.9,2.6,1.4,31.7,1.4,31.7l-14.1,3.6l0.1,77c0,0,4.3,9.7,32.3,9.4C689.1,374.2,694.4,369.9,694.4,369.9z"/>
					</svg>
				</div>
			</div>
		</div>

	<div id="header_end" ref="header_bot"></div>

	<section class="degrad py-7">
		<Splide
			:key="articles.length"
			class="accueil-splide"
			:options="{
				align: 'prev',
				gap:'15px',
				autoWidth:true,
				padding: '0.75rem',
				mediaQuery: 'min',
				type:'loop',
				autoplay:true,
				interval:5000,
				arrows:false,
				pagination:false,
				classes: {
					arrows: 'splide__arrows container',
				},
				breakpoints:{
					1400:{
						padding: 'calc(50vw - 660px + 0.75rem)'
					},
					1200:{
						padding: 'calc(50vw - 570px + 0.75rem)'
					},
					992:{
						padding: 'calc(50vw - 480px + 0.75rem)'
					},
					768:{
						padding: 'calc(50vw - 360px + 0.75rem)'
					},
					576:{
						padding: 'calc(50vw - 270px + 0.75rem)'
					}
				}
			}">
			<SplideSlide v-for="article in articles" :key="article.id" class="col-8 col-md-5 col-lg-4">
				<router-link18n class="d-block text-decoration-none" :to="{name:'actualite', params:{id:article.id, slug:article.slug}}">
					<visuel :visuel_id="article.visuel_id" class="rounded-3 mb-2" />
					<h2 class="m-0 pe-3">{{article.label }}</h2>
				</router-link18n>
			</SplideSlide>
		</Splide>
	</section>

	<section class="acc-img-con acc-img-con-0" role="img" :aria-label="$t('home.mousses.titre')">
		<div class="container position-relative pt-5 pt-sm-7" style="z-index: 1">
			<div class="d-md-flex flex-column align-items-end">
				<h1 class="revealing-image mb-2 text-center text-md-end">{{ $t("home.mousses.titre") }}</h1>
				<h2 class="revealing-image mb-4 mb-md-5 text-center text-md-end">{{ $t("home.mousses.description") }}</h2>
			</div>
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="1" class="reveal-card col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7 mb-3 ps-3 pe-2" />
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="2" class="reveal-card col-12 col-md-7 offset-md-5 col-lg-6 offset-lg-6 col-xl-5 offset-xl-7 ps-3 pe-2" />
		</div>
	</section>
	<section class="acc-img-con acc-img-con-1" role="img" :aria-label="$t('home.cremes.titre')">
		<div class="container position-relative pt-5 pt-sm-7" style="z-index: 1">
			<h1 class="revealing-image text-white mb-2 col-12 col-md-10 col-lg-7 col-xl-5 text-center text-md-start">{{ $t("home.cremes.titre") }}</h1>
			<h2 class="revealing-image text-white mb-4 mb-md-5 text-center text-md-start">{{ $t("home.cremes.description") }}</h2>
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="3" class="reveal-card col-12 col-md-9 col-lg-6 col-xl-5 mb-3 ps-3 pe-2" />
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="4" class="reveal-card col-12 col-md-7 col-lg-6 col-xl-5 ps-3 pe-2" />
		</div>
	</section>
	<section class="acc-img-con acc-img-con-2" role="img" :aria-label="$t('home.serum.titre')">
		<div class="container position-relative pt-5 pt-sm-7" style="z-index: 1">
			<div class="d-md-flex flex-column align-items-end">
				<h1 class="revealing-image mb-2 col-md-10 col-12 col-lg-7 col-xl-5 text-center text-md-end">{{ $t("home.serum.titre") }}</h1>
				<h2 class="revealing-image mb-4 mb-md-5 col-md-6 col-lg-5 col-xl-7 text-center text-md-end">{{ $t("home.serum.description") }}</h2>
			</div>
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="5" class="reveal-card col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8 mb-3 ps-3 pe-2" />
		</div>
	</section>
	<section class="acc-img-con acc-img-con-3" role="img" :aria-label="$t('home.cheveux.titre')">
		<div class="container position-relative pt-5 pt-sm-7" style="z-index: 1">
			<h1 class="revealing-image text-white mb-2 col-12 col-md-10 col-lg-7 col-xl-5 text-center text-md-start">{{ $t("home.cheveux.titre") }}</h1>
			<h2 class="revealing-image text-white mb-4 mb-md-5 text-center text-md-start">{{ $t("home.cheveux.description") }}</h2>
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="7" class="reveal-card col-12 col-md-6 col-lg-5 mb-3 ps-3 pe-2" />
			<produit-card-inline :btns_add="true" :btns="false" :produit_id="6" class="reveal-card col-12 col-md-6 col-lg-5 ps-3 pe-2" />
		</div>
	</section>

	<section class="degrad py-7">
		<div class="container">
			<h1 class="revealing-image mb-3 col-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5">{{ $t("home.catalogue.titre") }}</h1>
			<h2 class="revealing-image mb-4 mb-md-5 col-12 col-md-9 col-lg-10 col-xl-8 col-xxl-7">{{ $t("home.catalogue.description") }}</h2>
		</div>

		<Splide
			class="accueil-splide pb-5"
			:options="{
				align: 'prev',
				gap:'15px',
				autoWidth:true,
				padding: '0.75rem',
				mediaQuery: 'min',
				classes: {
					arrows: 'splide__arrows container',
				},
				breakpoints:{
					1400:{
						padding: 'calc(50vw - 660px + 0.75rem)'
					},
					1200:{
						padding: 'calc(50vw - 570px + 0.75rem)'
					},
					992:{
						padding: 'calc(50vw - 480px + 0.75rem)'
					},
					768:{
						padding: 'calc(50vw - 360px + 0.75rem)'
					},
					576:{
						padding: 'calc(50vw - 270px + 0.75rem)'
					}
				}
			}">
			<template v-for="(gamme, g) in gammes" :key="gamme.gamme.id">
				<SplideSlide v-for="p in gamme.produits" :key="p" class="col-8 col-sm-5 col-md-4 col-lg-3 col-xxl-3">
					<produit-card :produit="produit(p)" class="h-100" />
				</SplideSlide>
			</template>
		</Splide>
	</section>

	<section class="contact degrad py-7">
		<div class="container text-center">
			<div class="row">
				<div class="col-11 col-sm-10 col-md-8 col-lg-7 mx-auto">
					<h2 class="mb-4">{{ $t('accueil.partenaire.devenez') }}</h2>
					<p>{{ $t('accueil.partenaire.devenez_detail') }}</p>
					<btn :to="{name:'contact', query:{'sujet':'partenaire'}}" color="light px-5">{{ $t('accueil.partenaire.devenez_btn')}}</btn>
				</div>
			</div>
		</div>
	</section>

</template>
<script lang="ts">
	import { Product, useProductStore } from '../stores/products';
	import Produit from "../services/Produit";
	import VisuelTop from "../components/visuel-top.vue";
	// import {Pagination} from "@egjs/flicking-plugins";
	import ProduitCard from "../components/produit-card.vue";
	import ProduitCardInline from "../components/produit-card-inline.vue";
	import RouterLink18n from "@/components/router-link18n.vue";
	import Xhr from "@/shared/Xhr";

	export default {
		components: {RouterLink18n, ProduitCardInline, ProduitCard, VisuelTop},
		metaInfo() {
			return {
				title: this.$t("home.meta.title"),
				// description: this.$t("home.meta.description"),
				meta: [
					{name: 'description', content: this.$t("home.meta.description") },
					{name: "keywords", content: this.$t("home.meta.keywords") },
				]
			}
		},
		data(){
			return {
				produitModel:new Produit(),
				articles:[],
				videoclass:null,
				// plugins:[
				// 	new Pagination({ type: 'bullet' }),
					// new AutoPlay({ duration: 4000, direction: "NEXT", stopOnHover: true }),
					// new Arrow(),
				// ],
			}
		},
		computed: {
			products(){
				return useProductStore().list;
			},
			gammes(){
				return useProductStore().gammes;
			}
		},
		created() {
			let storage = sessionStorage.getItem('od_articles');
			if(storage && storage!=="undefined") {
				this.articles = JSON.parse(sessionStorage.getItem('od_articles' ));
			}

			Xhr('GET', 'actualites/paginator').then((xhr)=>{
				sessionStorage.setItem('od_articles', JSON.stringify(xhr.response));
				this.articles = xhr.response;
			});
		},
		methods:{
			produit(id){
				return useProductStore().items[id as string]<Product>;
			},
			onOk(){
				setTimeout(()=> {
					if(this.$refs.video) this.$refs.video.play();
				}, 1000)
			},
			onEnd(){
				this.videoclass = 'zoom';
				setTimeout(()=>{
					if(!this.$refs.video) return;
					this.$refs.video.currentTime = 0;
					this.videoclass = 'zoom reset';
					setTimeout(()=> {
						this.videoclass = 'reset';
						this.$refs.video.play();
					}, 10)
				}, 1000)
			},
			showTooltip(i, show=true){
				console.log("showTooltip", i, show);
			}
		}
	}
</script>

<style lang="scss">
	@import "/src/assets/variables.scss";
	.accueil-splide{
		.splide__arrows {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: inherit;
			display: flex;
			justify-content: space-between;
		}
		.splide__arrow {
			transform: none;
			top: 100%;
			position: initial;
			transition:opacity 0.25s;
			color: getColor('primary-color');
			font-family: var(--fa-style-family, "Font Awesome 6 Pro");
			font-weight: var(--fa-style, 900);
			svg{
				display: none;
			}
		}
		.splide__arrow--prev:before{
			content:"\f053";
		}
		.splide__arrow--next:before{
			content:"\f054";
		}
		.splide__pagination {
			bottom: 0;
			left: inherit;
			right: inherit;
			height: 32px;
			transform: translateX(calc(50vw - 50%));
			.splide__pagination__page.is-active {
				background: getColor('primary-color');
			}
		}
	}
</style>
<style lang="scss" scoped>
	@import "/src/assets/variables.scss";
	@import "/node_modules/bootstrap/scss/bootstrap.scss";
	//
	//@keyframes reveal {
	//	from {
	//		opacity: 0;
	//		transform:translateY(50px);
	//		//clip-path: inset(45% 20% 45% 20%);
	//		//background: red;
	//	}
	//	to {
	//		opacity: 1;
	//		transform:translateY(0px);
	//		//clip-path: inset(0% 0% 0% 0%);
	//		//background: blue;
	//	}
	//}
	//.revealing-image {
	//	animation: linear reveal both;
	//	animation-timeline: view();
	//	//animation-range: entry 25% cover 50%;
	//	animation-range: cover 0% contain 10%;
	//}
	@keyframes revealcard {
		from { opacity: 0; transform: scale(0.5) }
		to { opacity: 1; transform: scale(1) }
	}
	.reveal-card {
		animation: revealcard linear both;
		animation-timeline: view();
		animation-range: cover 0% contain 10%;
		//view-timeline-inset: 10%;
	}

	.image-collection {
		background-image: image-set(
				url("/img/home_collection_500.webp") type("image/webp"),
				url("/img/home_collection_500.png") type("image/png")
		);
		//background-image: url('/img/home_collection_500.png');
		background-color:linear-gradient(-10deg, #196ca0, transparent 70%);
		@include media-breakpoint-up(sm) {
			background-image: image-set(
					url("/img/home_collection_1000.webp") type("image/webp"),
					url("/img/home_collection_1000.png") type("image/png")
			);
			//background-image: url('/img/home_collection_1000.png');
		}
		@include media-breakpoint-up(lg) {
			background-image: image-set(
					url("/img/home_collection_2000.webp") type("image/webp"),
					url("/img/home_collection_2000.png") type("image/png")
			);
			//background-image: url('/img/home_collection_2000.png');
		}
	}

	//
	//.hero-tooltip {
	//	background: black;
	//	position: absolute;
	//	bottom: 26%;
	//	left: 32vw;
	//	transform: translateX(-50%);
	//	z-index: 1;
	//	padding: 2px 8px;
	//	border-radius: 6px;
	//	color: white;
	//	max-width: 200px;
	//}
	//.hero-tooltip:after {
	//	content: '';
	//	width: 0;
	//	height: 0;
	//	border-left: 10px solid transparent;
	//	border-right: 10px solid transparent;
	//	border-top: 10px solid black;
	//	position: absolute;
	//	top: 100%;
	//	left: calc(50% - 5px);
	//}


	.acc-img-con{
		width: 100%;
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: center bottom;
	}
	.acc-img-con-0{
		background-color:#a6b7c7;
		background-image:url('/img/home_mousse_500.jpg');
		padding-bottom: 55vw;
		@include media-breakpoint-up(sm) {
			background-image:url('/img/home_mousse_1000.jpg');
		}
		@include media-breakpoint-up(md) { padding-bottom: 30vw; }
		@include media-breakpoint-up(lg) {
			background-image:url('/img/home_mousse_2000.jpg');
			padding-bottom: 21vw;
		}
		@include media-breakpoint-up(xl) { }
		@include media-breakpoint-up(xxl) {
			background-image:url('/img/home_mousse_2000.jpg');
		}
	}
	.acc-img-con-1{
		background-color:#d6c5b2;
		background-image:url('/img/home_creme_500.jpg');
		padding-bottom: 50vw;
		@include media-breakpoint-up(sm) {
			background-image:url('/img/home_creme_1000.jpg');
		}
		@include media-breakpoint-up(md) { padding-bottom: 30vw; }
		@include media-breakpoint-up(lg) {
			background-image:url('/img/home_creme_2000.jpg');
			padding-bottom: 20vw;
		}
		@include media-breakpoint-up(xl) { }
		@include media-breakpoint-up(xxl) {
			background-image:url('/img/home_creme.jpg');
		}
	}
	.acc-img-con-2{
		background-color:#e0e0de;
		background-image:url('/img/home_serum_500.jpg');
		padding-bottom: 40vw;
		@include media-breakpoint-up(sm) {
			background-image:url('/img/home_serum_1000.jpg');
		}
		@include media-breakpoint-up(md) { padding-bottom: 10vw; }
		@include media-breakpoint-up(lg) {
			background-image:url('/img/home_serum_2000.jpg');
			padding-bottom: 10vw;
		}
		@include media-breakpoint-up(xl) { padding-bottom: 15vw; }
		@include media-breakpoint-up(xxl) {
			background-image:url('/img/home_serum.jpg');
			padding-bottom: 20vw;
		}
	}
	.acc-img-con-3{
		background-color:#c7b5ac;
		background-image:url('/img/home_cheveux_500.jpg');
		padding-bottom: 58vw;
		@include media-breakpoint-up(sm) {
			background-image:url('/img/home_cheveux_1000.jpg');
		}
		@include media-breakpoint-up(md) { padding-bottom: 25vw; }
		@include media-breakpoint-up(lg) {
			background-image:url('/img/home_cheveux_2000.jpg');
			padding-bottom: 25vw;
		}
		@include media-breakpoint-up(xl) { padding-bottom: 15vw; background-position-y: 0vw }
		@include media-breakpoint-up(xxl) {
			background-image:url('/img/home_cheveux.jpg');
		}
	}










	video.reset{
		transition: none;
	}
	video.zoom {
		transform: scale(1.5);
		filter: opacity(0) blur(20px);
	}
	video {
		transition: all 1s ease-in-out;
		transform: scale(1);
		filter: opacity(1) blur(0px);
	}


	//.mt-negimg{
	//
	//	//
	//	//@media screen and (max-width: 576px){ margin-top:-15vw; }
	//	//@media screen and (max-width: 768px){ margin-top:-20vw; }
	//	//@media screen and (max-width: 992px){ margin-top:-30vw; }
	//	//@media screen and (max-width: 1200px){ margin-top:-40vw; }
	//	//@media screen and (max-width: 1400px){ margin-top:-45vw; }
	//}
	//.mt-negimg2{
	//	margin-top:-20vw;
	//	@include media-breakpoint-up(sm) { margin-top:-20vw; }
	//	@include media-breakpoint-up(md) { margin-top:-55vw; }
	//	@include media-breakpoint-up(lg) { margin-top:-45vw; }
	//	@include media-breakpoint-up(xl) { margin-top:-40vw; }
	//	@include media-breakpoint-up(xxl) { margin-top:-35vw; }
	//}


	.hero{
		background: linear-gradient(90deg, rgb(174, 202, 217), rgb(183, 207, 221), rgb(168, 196, 210));
		//border-radius: 12px;
		overflow: hidden;

		.image{
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			object-fit: contain;
			//pointer-events: none;
			aspect-ratio: 2 / 1;
			min-height: 250px;
			max-height: 80vh;

			svg{
				path{
					&:hover{
						fill:#ffffff70;
					}
					cursor: pointer;
				}
			}
		}
	}
	h1{
		font-size: 3em;
		color: getColor('blue-color');
		//padding: 2px 24px 4px;
		text-transform: uppercase;
		font-weight: 300;
		display: inline-block;
		line-height: 1em;
		letter-spacing: 2px;
	}
	:not(.hero){
		.image{
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			object-fit: contain;
			//pointer-events: none;
			//aspect-ratio: 2 / 1;
			min-height: 250px;
			max-height: 100vh;
		}
	}

	//.produit{
	//	cursor: pointer;
	//}
	.video{
		background: getColor('white');
		height: 100%;
		min-height: 30vh;
		display: flex;
		border-radius: 12px;
		video{
			width:70%;
			max-width: 200px;
			margin:auto;
			opacity: 0.2;
		}
	}
	.invert{
		@media(prefers-color-scheme: dark) {
			filter: invert(.85);
		}
	}
	h2{
		font-weight: 300;
		color:var(--blue-color);
	}

	.contact{
		.lien{
			border-radius: 12px;
			display: block;
			text-align: center;
			background: getColor('white');
			color: getColor('blue-color');
			//height: 100%;
		}
		.events{
			.event{
				//height: 100%;
				display: block;
				border-radius: 12px;
				//padding: 15px;
				text-align: center;
				background: getColor('white');
				a{
					color: getColor('blue-color');
				}
			}
			//img{
			//	height:80px;
			//	filter: brightness(0);
			//	@media(prefers-color-scheme: dark) {
			//		filter: brightness(1);
			//	}
			//}
		}
	}
</style>
