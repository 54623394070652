import RestModel from '/src/shared/RestModel';

export default class Produit extends RestModel{

	constructor() {
		super();
		this.model = 'produits';
	}

	get init(){
		return {
			id:null,
			slug:null,
			label:null,
			description:null,
			accroche:null,
			genre:1,
			prix_ht:null,
			poids:null,
			associe:null,
			seuil:null,

			// details:null,
			offline:true,
			meta_description:true,
			meta_keywords:true,
		}
	}

	url(type, id, size=null, ext='webp'){
		//Size : 2 ou 10
		return this.appendRoot('produits-visuels/'+type+'/'+id+(size?'/'+size:'')+'.'+ext);
	}

	// urlImageHeader(produit_id){
	// 	return (this.url() + '/imageheader/'+produit_id+'.jpg');
	// }

	faqLabel(string){
		switch (string) {
			case 'actif': return `Principes actifs`;
			case 'utilisation': return `Conseils d’utilisation`;
			case 'pour': return `Recommandé pour`;
			case 'entretien': return `Conseils d’entretien`;
			case 'resume': return `Résumé`;
			case 'market': return `Commercial`;
			case 'tech': return `Technique`;
			default: return string;
		}
	}
}
