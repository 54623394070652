<template>
	<form novalidate @submit.prevent="onSubmit">
		<slot />
	</form>
</template>
<script>
  export default {
    name: 'VueForm',
		emits:['submit'],
    methods: {
      onSubmit (e) {
        event.preventDefault();
				// this.validator.validateAll()
				// console.log('Validation succeeded!')
				// console.log("validator error", e);
				this.$emit('submit', e);
      }
    }
  }
</script>
