<template>
	<modal-right nom="panier"
		v-if="open"
		@cancel="open=false"
		ref="headerPanierModal"
		position="center-bottom"
		:header="false">
<!--			<template #header>Aperçu de votre panier</template>-->

		<template v-slot:default="props">
			<div v-if="!productStore.loaded" class="space-y-4">
				<CartCardSkeleton v-for="n in 2" :key="n" />
			</div>
			<div v-else-if="!formattedCart.length">
				<h4 class="my-5 text-center" v-html="$t('panier.nb_articles_dans_panier', 0)"></h4>
<!--					<panier-random />-->
			</div>
			<template v-else>


				<div class="d-flex align-items-center mb-2 fw-bold">
					<div class="flex-grow-1">
						{{ $t("panier.sous_total_x_articles", cartStore.count, { count: cartStore.count }) }} :
					</div>
					<montant-httc :montants="{'prix_ht':cartStore.total_ht, 'prix_ttc':cartStore.total_ttc}" />
				</div>
				<div
					class="position-relative"
					v-for="(cartItem, index) in formattedCart" :key="index">
					<div class="d-flex">
						<visuel :declinaison_id="cartItem.id" :size="5" class="m-0" style="min-width:60px; width:60px; border-radius:8px" />
						<div
							class="d-flex align-items-center w-100 text-truncate ms-2 ms-sm-3"
							:style="index!==formattedCart.length-1 ? 'border-bottom: 1px solid lightgrey':null">

							<div class="flex-grow-1 text-truncate">
								<div class="text-truncate">{{ cartItem.produit.label }}</div>
								<div class="text-muted">
									<span>{{ cartItem.declinaison.conteneur }}</span>
									<span class="ms-2">{{ cartItem.declinaison.label }}</span>
								</div>
							</div>
							<div class="text-end ms-2 ms-sm-3">
								<div class="lh-1">
									<div v-if="cartItem.declinaison.prix_initial_ht">
										<montant-httc
											class="small fs-lg fw-boldX text-danger text-decoration-line-through"
											field="prix_total_initial"
											:montants="cartItem" />
									</div>
									<montant-httc :montants="cartItem" field="prix_total" class="m-0" />
								</div>
								<div v-if="cartStore.delcinaison(cartItem.id).qtt>1" class="small text-muted">
									<div class="small text-nowrap">
										{{ cartStore.delcinaison(cartItem.id).qtt }}
										x
										<montant-httc :montants="cartItem.declinaison" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				<template v-if="cartStore.ventesCroisees.length>0">
<!--					<checkout-livraison-gratuit2>-->
<!--						<template v-slot:default="props">-->
							<div class="mt-4 mb-2 fw-bold">
<!--								<template v-if="props.demi && props.delta>0">-->
<!--									{{ $t("panier.ajoutez_x_pour_port_gratuit", { montant: parseFloat(props.delta/100).toLocaleString($i18n.locale, {style:'currency', currency:'EUR', minimumFractionDigits:2, maximumFractionDigits:2}) }) }} :-->
<!--								</template>-->
<!--								<template v-else>-->
									{{ $t("panier.x_autres_produits_pour_vous", { count: cartStore.ventesCroisees.length }) }} :
<!--								</template>-->
							</div>
<!--						</template>-->
<!--					</checkout-livraison-gratuit2>-->

					<div v-for="(produit, index) in croisees" :key="index">
						<div class="d-flex">
							<visuel :produit_id="produit.id" :size="5" class="m-0" style="min-width:60px; width:60px; border-radius:8px" />
							<div
								class="d-flex align-items-center w-100 text-truncate ms-2 ms-sm-3"
								:style="index!==cartStore.ventesCroisees.length-1 ? 'border-bottom: 1px solid lightgrey':null">
								<div class="flex-grow-1 text-truncate">
									<div class="text-truncate">{{ produit.label }}</div>
									<div class="text-muted d-flex">
										<span class="text-truncate flex-grow-1 flex-sm-grow-0">{{ produit.declinaisons[0].conteneur }}</span>
										<span class="flex-grow-0 flex-sm-grow-1 ms-2">{{ produit.declinaisons[0].label }}</span>

										<div v-if="produit.declinaisons[0].prix_initial_ht">
											<montant-httc
												class="small ms-2 ms-sm-3 text-danger text-decoration-line-through"
												field="prix_initial"
												:montants="produit.declinaisons[0]" />
										</div>

										<montant-httc :montants="produit.declinaisons[0]" class="ms-2 ms-sm-3" />
									</div>
								</div>
								<div class="text-end ms-3">
									<!--									<panier-btns :produit_id="produit.id"></panier-btns>-->

									<!--										@add="props.closeModal"-->
									<panier-btns
										:declinaison_id="produit.declinaisons[0].id"
										:display_stock="false"
										:display_add="true"
										:display_btns="false"
										:modal="false"
										bclass="w-100">
										<i class="fa fa-bag-shopping-plus" />
									</panier-btns>
								</div>
							</div>
						</div>
					</div>


<!--					<produit-card-inline-->
<!--						v-for="produit_id in cartStore.ventesCroisees"-->
<!--						:key="produit_id"-->
<!--						:btns="true"-->
<!--						:description="false"-->
<!--						:produit_id="produit_id">-->
<!--						X-->
<!--					</produit-card-inline>-->

<!--					@add="props.closeModal()"-->
				</template>
<!--				<checkout-livraison-gratuit v-else class="text-center small text-muted mb-4" />-->

				<div v-if="!isCheckout" class="text-center mt-3">
					<btn :label="$t('panier.finaliser_commande')" color="primary w-100 mb-2" :to="{name:'checkout'}" @click="closeModal" />
					<btn :label="$t('panier.continuer_achats')" color="link btn-sm text-muted text-decoration-none" @click="props.closeModal" />
				</div>
			</template>

		</template>
	</modal-right>
</template>
<script>
import { useCartStore } from '../stores/cart';
import { useProductStore } from '../stores/products';
import CartCardSkeleton from "./cart-card-skeleton.vue";

export default {
	name: "header-panier-modal",
	components: {
		CartCardSkeleton,
	},
	data:()=>{
		return {
			open:false,
			cartStore:useCartStore(),
			productStore:useProductStore(),
		}
	},
	created() {
		window.addEventListener('header-panier-modal-open', this.openModal);
		window.addEventListener('header-panier-modal-close', this.closeModal);
	},
	computed:{
		count(){
			return useCartStore().count;
		},
		formattedCart(){
			return useCartStore().formattedCart;
		},
		isCheckout(){
			return ['checkout'].indexOf(this.$route.name)>-1;
		},
		croisees(){
			return this.cartStore.ventesCroisees.map(id => this.produit(id));
		}
	},
	methods:{
		openModal(){
			if(this.isCheckout) return;
			if(this.count===0) return;
			if(this.open) return;
			this.open = true;
		},
		closeModal(){
			if(!this.open) return;
			this.$refs.headerPanierModal.closeModal();
			window.dispatchEvent(new CustomEvent('header-search-modal-close'));
		},
		produit(id){
			return useProductStore().items[id]
		},
	}
}
</script>
<style lang="scss" scoped>
	.produit-card{
		background: white;
		border-radius: 8px;
	}
</style>
