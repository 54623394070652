<template>
	<section class="degrad py-6 position-relative" v-if="!est_abonne">
		<div class="container">
			<h3>{{ $t('newsletter.abonnez_vous_aux_newsletters') }}</h3>
			<div v-html="$t('newsletter.beneficiez_reduction')"></div>
			<div class="text-center mt-2">
				<btn @click="openModal" :label="$t('newsletter.mabonner')" class="stretched-link" />
			</div>
		</div>

		<modal-right v-if="modal" @cancel="modal=null" position="center modal-x-center">
			<template #title>{{ $t('newsletter.abonnez_vous_aux_newsletters') }}</template>
			<template v-slot:default="props">

				<div v-html="$t('newsletter.beneficiez_reduction')" class="mb-4"></div>

				<form @submit="send">
					<div class="row">
						<div class="col-12 col-sm-6">
							<vue-field
								v-model="form.prenom"
								:label="$t('contact.form.prenom')"
								:errors="errors"
								field="prenom" />
						</div>
						<div class="col-12 col-sm-6">
							<vue-field
								v-model="form.nom"
								:label="$t('contact.form.nom')"
								:errors="errors"
								field="nom" />
						</div>
					</div>

					<vue-field
						v-model="form.mail"
						type="email"
						:label="$t('contact.form.mail')"
						:errors="errors"
						field="mail" />

					<vue-field
						v-model="form.tel"
						type="tel"
						:label="$t('contact.form.tel')"
						:errors="errors"
						field="tel" />

					<div class="mt-3">
						<div class="d-flex align-items-baseline">
							<input id="box" type="checkbox" :value="'box'" @input="form.legal=!form.legal" class="me-2">
							<div>
								<label for="box" class="d-block" v-html="$t('newsletter.legal')" />
								<router-link18n @click="props.closeModal" :to="{name:'terms'}">{{ $t("terms.meta.title") }}</router-link18n>.
							</div>
						</div>
					</div>

					<div v-if="callback && callback.message" class="alert" :class="callback.send ? 'alert-success':'alert-warning'">
						{{callback.message}}
					</div>


					<div class="text-center">
						<btn color="primary mt-4" @click="send" :disabled="!form.nom || !form.mail || !form.legal" :label="$t('newsletter.mabonner')" />
						<btn :label="$t('annuler')" color="link btn-sm text-muted text-decoration-none mx-auto d-block" @click="props.closeModal" />
					</div>

				</form>
			</template>

		</modal-right>
	</section>
</template>
<style lang="scss" scoped>
	@import '/src/assets/variables.scss';
	.newsletter{
		background: getColor(primary-color);
	}
</style>
<script>
	import { useAuthStore } from '../stores/auth';
	export default{
		data: function() {
			return {
				modal:null,
				// userStore:useUserStore()

				callback:null,
				authStore:useAuthStore(),
				form:{
					prenom:null,
					nom:null,
					mail:null,
					tel:null,
					legal:false,
				},
				errors:null,
			}
		},
		computed:{
			est_abonne(){
				return this.authStore.newsletters;
			},
			formJson(){
				return JSON.stringify(this.form);
			}
		},
		watch:{
			formJson(){
				this.errors = null;
				this.callback = null;
			}
		},
		methods:{
			openModal(){
				this.initForm();
				this.modal = true;
			},
			initForm(){
				this.form.prenom = this.authStore.user?.prenom;
				this.form.nom = this.authStore.user?.nom;
				this.form.mail = this.authStore.user?.mail;
				this.form.tel = this.authStore.user?.tel;
			},
			async send(){
				let save = await this.authStore.subscribeNewsletter(true, this.form);
				if(save) this.mail = null;

				// this.errors = data.errors||null;
				// if(data.send){
				// 	this.initForm();
				// 	this.$nextTick(()=>{
				// 		this.callback = { send:true, message:'Message envoyé !' }
				// 	})
				// 	//this.$notifications.push({message:'Message envoyé !'});
				// }else{
				// 	console.error(data);
				// }
			}
		}
	}
</script>
