<template>
	<span class="text-nowrap">{{formatedMontant}}</span>
</template>
<script>
	export default{
		props: {
			montant:{default:null},// type: Number },
			// suffix:{default:null},
			devise:{default:'EUR'},
			default:{default:0},
			decimal:{default:2},
		},
		computed: {
			formatedMontant: function () {
				let montant = this.montant;
				if(!this.montant) montant = this.default;
				montant = this.montant/100;
				// if(montant===false || montant===null) return null;
				let options = {style:'currency', currency: this.devise, minimumFractionDigits: this.decimal, maximumFractionDigits: this.decimal };
					// ? this.devise : this.currency
				// console.log(options, this.$i18n)

				return parseFloat(montant).toLocaleString(this.$i18n.locale, options);//
				// + (this.suffix? ' '+this.suffix : '');

				//
				// const formatter = new Intl.NumberFormat('fr-FR', {
				// 	style: 'currency',
				// 	currency: 'EUR',
				// })

			},
		},
	}
</script>
