import { defineStore } from 'pinia';
import {Declinaison} from "./declinaisons";

const fakeStoreUrl = '/produits';
const url = fakeStoreUrl;

import Xhr from '../shared/Xhr.js';

export interface Product {
  id: number;
  // title: string;
  // price: number;
  // category: string;
  // image: string;

  // associes:[]
  // categorie_id:null
  // created:"2023-02-12T12:06:14+00:00"
  declinaisons:[Declinaison],
  // default_declinaison_id:null
  description:string,
  // gamme_id:null
  // id: 1
  // ingredients:null
  label:string,
  // label_alt:null
  // modified:null
  // procupation_id:null
  // produits_tags:[]
  // type_peau: null
  // typepeau_id:null
  // utilisation: null
  // visuels:[]
}

interface ProductState {
  items: Record<string, Product>;
  ids: number[];
}

export const useProductStore = defineStore({
  id: 'products',

  state: (): ProductState => ({
    items: {},
    ids: [],
  }),

  getters: {
    list(): Product[] {
      return this.ids.map((i) => this.items[i]);
    },

    gammes(){
      let gammes = {};
      for(let i=0; i<this.list.length; i++){
        let produit = this.list[i];
        let tag = produit.gamme.tag;
        if(!gammes[tag.id]) gammes[tag.id] = {gamme:tag, produits:[]};
        gammes[tag.id].produits.push(produit.id);
      }
      return gammes;
    },

    //rien à faire ici >> dans declinaisons.ts ?
    declinaisons(): Declinaison[] {
      var xxx = [];
      var x = Object.values(this.items);
      for(let i=0; i<x.length; i++){
        let decs=x[i].declinaisons;
        for(let d=0; d<decs.length; d++){
          // console.log('p',i,'d',d,);
          xxx[decs[d].id] = decs[d];//(...x[i].declinaisons);
        }
      }
      // let xx = Object.assign(...x.map(i => i.declinaisons))
      // console.log('declinaisons this.items', xxx);
      return xxx;
    },

    loaded(): boolean {
      return this.ids.length > 0;
    },



  },

  actions: {
    fetchAll(force=false) {
      // console.log("fetchAll loaded", this.loaded);
      if (!force && this.loaded) return;
      // var data: Product[] = null;
      let storage = sessionStorage.getItem('od_produits');
      if(storage && storage!=="undefined") {
        this._setMap( JSON.parse(sessionStorage.getItem('od_produits')), 'storage' );
      }

      Xhr('GET', url).then((xhr)=>{
        // console.log("xhr",xhr);
        sessionStorage.setItem('od_produits', JSON.stringify(xhr.datas));
        this._setMap( xhr.datas, "xhr" );
      });
    },
    _setMap(data: Product[] = null, from){
      // console.log("set_map_"+from, data)
      this.ids = data.map((product) => {
        this.items[product.id] = product;
        return product.id;
      });
    },
    setProduit(produit_id, data){
      console.log("setProduit", produit_id, data);
      this.items[produit_id] = data;
      // sessionStorage.setItem('od_produits', JSON.stringify(Object.values(this.items)));
    },


    getRandom(): Product[] {
      if(!this.loaded) return;
      // let random = Math.floor(Math.random() * Object.keys(this.ids).length);
      let items = Object.values(this.items).filter(item => item.offline==false && item.declinaisons.reduce((pv, cv) => pv + parseInt(cv.stock?.total||0), 0));
      return items.sort( () => .5 - Math.random() )[0];
    },
    produit(id){
      return this.items[id];// as string <Product>
    },


    // getVisuel(visuel_id, ext='jpg', width=600, height=600, version=null){
    //   let params = {w:width,h:height}
    //   if(version) params.version=version;
    //   let url = new URLSearchParams(params);
    //   return fakeStoreUrl + '/visuel/'+visuel_id+'.'+ext+'?'+url;
    // },
    // getVisuelDeclinaison(declinaison_id, width=600, height=600, version=null){
    //   let params = {w:width,h:height}
    //   if(version) params.version=version;
    //   let url = new URLSearchParams(params);
    //   return fakeStoreUrl + '/visueldeclinaison/'+declinaison_id+'.jpg?'+url;
    // },
    // getVisuelProduit(produit_id, width=600, height=600, version=null){
    //   let params = {w:width,h:height}
    //   if(version) params.version=version;
    //   let url = new URLSearchParams(params);
    //   return fakeStoreUrl + '/visuelproduit/'+produit_id+'.jpg?'+url;
    // },
    getStock(declinaison, qtt=1){
      let stock = declinaison.stock ? declinaison.stock.total : null;

      let reassort = null;
      if(declinaison.reassort!=null
        && declinaison.reassort.date!=null
        && declinaison.reassort.mouvement!=null
        && (parseInt(stock|0) + parseInt(declinaison.reassort.mouvement)) > qtt){

        reassort = 'En stock le '+(declinaison.reassort.date);//datefr
      }

      if(stock === null) return {
        label:reassort?reassort:'Aucune info de stock',
        qtt:0,
        class:'danger',
        visible:true
      }

      if(stock===0) return {
        label:reassort?reassort:'En rupture de stock',
        qtt:0,
        class:'danger',
        visible:true
      }

      if(stock < qtt) return {
        label:reassort?reassort:'Stock limité à '+(stock>1 ? `${stock} exemplaires`:`un exemplaire`),
        qtt:0,
        class:'danger',
        visible:true
      }

      return {
        label:`${declinaison.stock.total} en stock`,
        qtt:declinaison.stock.total,
        class:'success',
        visible:false
      }
    },
    getDeclinaison(produit, declinaison_id=null){
      if(produit.declinaisons.length===0) return null;
      if(!declinaison_id) declinaison_id = produit.default_declinaison_id;
      if(!declinaison_id) declinaison_id = produit.declinaisons[0].id;
      let declinaison = produit.declinaisons.filter(declinaison => declinaison.id === declinaison_id);
      if(declinaison.length===0) return null;
      return declinaison[0];
    },
    // setDeclinaisonStock(declinaison_id, stock){
    //   console.log(declinaison_id, stock);
    //   console.log(this.ids);
    //   console.log(this.items);
    //   this.declinaisons[declinaison_id].stock.total = stock;
    //   let produit_id = this.declinaisons[declinaison_id].produit_id;
    //   this.setProduit(produit_id, this.items[produit_id]);
    // },
    hasMultiPrix(produit){
      if(produit.declinaisons.length<=1) return false;
      return [...new Set(produit.declinaisons.map(declinaison => declinaison.prix_ttc))].length>1;
    },
    getMinDeclinaison(produit){
      if(produit.declinaisons.length===0) return null;
      return produit.declinaisons
        .sort(function(a, b){ return a.prix_ttc - b.prix_ttc })[0];
    },
    getTags(produit){
      if(!produit.produits_tags) return [];
      return produit.produits_tags.filter(ptag => ptag?.tag?.type==='tag')
    }
  },
});
