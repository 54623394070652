function enabled(){
	let enabled = 'vibrate' in navigator;
	if(!enabled) console.log("Vibration not supported");
	return enabled;
}

class Vibration {

	// navigator.vibrate(500);// Vibrate for 500ms
	// navigator.vibrate([500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500]);// Vibrate for 500ms 6 times, pausing for 250ms in between each one.

	static vibrate(duration){
		if(!enabled()) return;
		navigator.vibrate(duration);
	}

	static stop(){
		if(!enabled()) return;
		navigator.vibrate(0);
	}

}

export default Vibration;