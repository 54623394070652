<template>
	<vue-input
		:modelValue="modelValue"
		:autocomplete="autocomplete"
		@update:modelValue="onInput"
		@inputed="onInput"
		@focus="onFocus"
		@blur="onBlur"
		@keydown.tab="onTab"
		@keydown.enter="onTab"
		ref="input"
		:styleinput="domainesPossibles && domainesPossibles.length===1 && w ? 'max-width:'+w+';flex-grow:0' : ''"
		:clas="'flex-grow-1 d-flex align-items-center'"
		:classinput="'text-lowercase'"
		:class="{noPrInp:domainesPossibles && domainesPossibles.length===1 && w}"
		:placeholder="placeholder"
		:fa="fa">
		<template #suffix v-if="(domainesPossibles && domainesPossibles.length===1) || $slots.suffix">
			<div
					class="proposition text-muted "
					v-if="domainesPossibles && domainesPossibles.length===1"
					:key="domainesPossibles[0]+'_tab'"
					@click.stop="onAdd(domainesPossibles[0])">
				{{ domainesPossibles[0].substring(modelValue.split('@')[1].length, domainesPossibles[0].length) }}
			</div>

			<div v-if="domainesPossibles && domainesPossibles.length===1 && w" class="flex-grow-1"></div>
			<slot v-if="$slots.suffix" name="suffix" />
		</template>
	</vue-input>
</template>

<style lang="scss" scoped>
.noPrInp{
  :deep(.suffix){
    height: 40px;
    line-height: 40px;
    cursor: text;
    display: block;
  }
	:deep(input){
    padding-right: 0!important;
  }
}
</style>
<script>
	import Xhr from '/src/shared/Xhr'
	export default {
		name: 'VueInputEmail',
		props: {
			modelValue: { default:null },
			placeholder: { default:'' },
			autocomplete: { default:null },
			fa: { default:null },
		},
		emits:['keydown','keyup','focus','blur','input','inputed','update:modelValue','domaine','prenom','nom'],
		data(){
			return {
				domaines:[]
			}
		},
		created() {
			// console.log("vie");
			this.getDomaines();
    },
		computed:{
      w(){
        var string = this.modelValue || '';

				const fakeEle = document.createElement('div');
				fakeEle.style.position = 'absolute';
				fakeEle.style.top = '0';
				fakeEle.style.left = '-9999px';
				fakeEle.style.overflow = 'hidden';
				fakeEle.style.visibility = 'hidden';
				fakeEle.style.whiteSpace = 'nowrap';
				fakeEle.style.fontSize = '16px';
				fakeEle.style.font = '400 16px Roboto,RobotoDraft,Helvetica,Arial,sans-serif';
				document.body.appendChild(fakeEle);
				fakeEle.innerHTML = string.replace(/\s/g, '&' + 'nbsp;');
        const box = fakeEle.getBoundingClientRect();
				document.body.removeChild(fakeEle);
        return (parseFloat(box.width) + 10 ) + 'px';
      },
			hasValue(){
				return !!this.modelValue;
			},
			hasAt(){
				if(!this.modelValue) return false;
				return this.modelValue.indexOf('@')>-1;
			},
			domainesPossibles(){
				// Affiche si dommaines + possède valeur + pas que des chiffres
				if(this.domaines.length===0) return null;
				if(!this.hasValue || this.modelValue.length<5) return null;
				if(!isNaN(this.modelValue)) return null;

				// Affiche tous si pas de @ saisi
				if(!this.hasAt) return this.domaines;

				// Un domaine match exactement avec celui renseigné
				let indexAt = this.modelValue.indexOf('@');
				let fin = this.modelValue.slice(indexAt+1, this.modelValue.length).toLowerCase();
				if(this.domaines.filter(d => d===fin).length>0) return null;

				// Affiche les dommaines correspondants à la saisie partielle
				return this.domaines.filter(d => d.startsWith(fin));
			}
		},
		methods: {
			getDomaines(){
				// new Xhr('GET', 'domaines', null, null, function(){}, false).then(function(data){
				// 	this.domaines = data;
				// }.bind(this));
			},
			onFocus(event){
        this.$emit('focus', event);
			},
      onBlur(event){
        this.onTab();
        this.$emit('blur', event);
      },
      onTab(event=null){
        if(this.domainesPossibles && this.domainesPossibles.length===1){
          if(event){
            event.preventDefault();
            event.stopPropagation();
          }
          this.onAdd(this.domainesPossibles[0]);
        }
      },
			onAdd(value){
				let newValue = this.modelValue+'@'+value;
				let indexAt = this.modelValue.indexOf('@');
				if(indexAt>-1){
					newValue = this.modelValue.slice(0, indexAt)+'@'+value;
				}
				this.$emit('inputed', newValue);
				this.$emit('domaine');
			},
			onInput (e) {
				this.analysePrenomNom(e);
				this.$emit('inputed', e)
			},
			analysePrenomNom(e){
				// console.log("analysePrenomNom", e);
				let mail = (e||'').toLowerCase().replaceAll(/[^.\-_@a-z]/gi, '');
				let index = mail.indexOf('@');
				if(index===-1) return;
				let mails = mail.split('@');
				let split = mails[0].split(/(.*)-|\.|_(.*)/).filter(x=>x);
				this.$emit('prenom', split[0] ? split[0][0].toUpperCase()+split[0].substring(1,split[0].length) : null);
				this.$emit('nom', split[1] ? split[1].toUpperCase() : null);
			},
		}
	}
</script>
