<template>
	<div class="container py-5 py-sm-7">

		<form class="col-12 col-md-6 offset-md-3 px-sm-5 py-sm-7">
			<vue-field
				v-model="form.mail"
				label="Mon adresse e-mail"
				type="email"
				@input="analysePrenomNom"
				margin="mb-2 mb-sm-4" size="form-control-lg" />
<!--			<vue-field v-model="form.tel" label="Tel" type="tel" margin="mb-4" size="form-control-lg" />-->

			<div class="row gx-2">
				<div class="col-12 col-sm-6">
					<vue-field v-model="form.prenom" label="Prénom" margin="mb-2 mb-sm-4" size="form-control-lg" />
				</div>
				<div class="col-12 col-sm-6">
					<vue-field v-model="form.nom" label="Nom" margin="mb-2 mb-sm-4" size="form-control-lg" />
				</div>
			</div>

			<vue-field v-model="form.newsletters" label="M'abonner aux newsletters" type="switch" />
<!--					<vue-field-->
<!--						v-model="$i18n.locale"-->
<!--						label="Langue"-->
<!--						type="select"-->
<!--						:search="false"-->
<!--						:options="$i18n.availableLocales.map(function(locale){ return {value:locale, label:$t(`locale.${locale}`)}; })" />-->
<!--				{{ $i18n.locale }}-->
<!--							{{ $t('hello') }}-->

			<div class="text-center mt-4 mt-sm-5">
				<btn @click="save" label="Créer mon compte DAVID OLYSTER" color="primary px-sm-5 w-100 w-sm-inherit" />
			</div>
		</form>
	</div>
</template>
<style lang="scss" scoped>
</style>
<script>
	import {useAuthStore} from "../../stores/auth";

	export default{
		name:'one-click-user',
		metaInfo() {
			return {
				title: this.$t("nouveau_client.title"),
				meta: [
					{name: 'description', content: this.$t("nouveau_client.description")},
					{name: 'keywords', content: this.$t("nouveau_client.keywords")},
				]
			}
		},
		props:{
			data:{},
		},
		emits:['save','cancel'],
		data: function() {
			return {
				form: {
					prenom: null,
					nom: null,
					mail: null,
					newsletters: true,
				}
			}
		},
		created:function(){
			this.initForm();
		},
		methods:{
			analysePrenomNom(){
				let mail = (this.form.mail||'').toLowerCase().replaceAll(/[^.\-_@a-z]/gi, '');
				let index = mail.indexOf('@');
				if(index===-1) return;
				let mails = mail.split('@');
				let split = mails[0].split(/(.*)-|\.|_(.*)/).filter(x=>x);
				this.form.prenom = split[0] ? split[0][0].toUpperCase()+split[0].substring(1,split[0].length) : null;
				this.form.nom = split[1] ? split[1].toUpperCase() : null;
			},
			initForm(){
				this.form = {
					mode:'salon',
					prenom: null,
					nom: null,
					mail: null,
					tel: null,
					newsletters: true,
					langue: this.$i18n.locale
				};
			},
			async save(){
				var data = await useAuthStore().signUp(this.form, false, true);
				if(data.code===200){
					console.log('>',data.message);
				}else{
					console.log('save error', data);
				}
			},
		},
	}
</script>
