<template>
	<div
		class="field-container"
		:style="'display:'+(hidden?'none':'inherit')"
		:class="margin+(focused?' focused':'')+(disabled?' disabled':'')+(error ?' input-'+errorClass:'')">

		<div class="d-inline-block" v-if="label || $slots.labelalt || error">
<!--			<component :is="error ? 'v-tooltip':'div'" :triggers="['hover','focus','touch']">-->
				<div>
					<label v-if="label" :for="inputId" v-html="label" />
					<span v-if="$slots.labelalt" class="label-alt">
						<slot name="labelalt" />
					</span>
					<i v-if="error && errorClass==='danger'" class="fa fa-exclamation-circle text-danger small ms-1"></i>
				</div>
				<!-- (required ? '<sup>*</sup>':'') -->
<!--				<template #popper v-if="error">-->
<!--					<span class="text-danger" v-html="error.join('<br>')+'.'" />-->
<!--				</template>-->
<!--			</component>-->
		</div>

		<component
			v-bind="$props"
      :styleinput="styleinput"
      :classinput="classinput"
			:is="component"
			ref="input"
			:input-id="inputId"
			:class="{error:!!error}"
			:label="!!label"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
			@blur="onBlur"
			@focus="onFocus"
			@inputed="onInputed"
			@value="onValue"
			@keyup="onKeyUp"
			@keydown="onKeyDown"
			@init="$emit('init')"
			@search-change="$emit('search-change', $event)"
			@on-select="$emit('on-select', $event)"
			@new="$emit('new', $event)"
			@domaine="$emit('domaine', $event)"
			@tag="$emit('tag', $event)"
			@name="$emit('name', $event)"
			@prenom="$emit('prenom', $event)"
			@nom="$emit('nom', $event)"
			@label="$emit('update:altValue', $event)"
			@adresse="$emit('update:altValue', $event)"
			@tva="$emit('update:altValue', $event)">
			<template #prefix v-if="$slots.prefix || fa">
				<i v-if="fa" :class="'far fa-'+fa" class="icon"></i>
				<slot name="prefix" v-if="$slots.prefix" />
			</template>

      <template v-for="s in Object.keys($slots)" #[s]>
				<slot :name="s" />
			</template>
		</component>

		<template v-if="error">
			<span class="small mt-2" :class="errorClass" v-html="error.join('.<br>')+'.'" />
		</template>

<!--		{{ field }} / {{ error }} / error-->
<!--		<hr>-->
<!--			{{props}}-->
<!--		<template #default="errors">-->
<!--			XXX{{errors}}-->
<!--		</template>-->
<!--		{{ $props }}-->
<!--		FormFieldErrors:{{ errors }}-->
<!--		Test:{{test}}-->
	</div>
</template>

<script>
// import { v4 as uuid } from 'uuid';

export default {
  name: 'VueField',
	// inject: ['errors'],
  props: {
		label: { default:null, type:[String, Array, null] },
    placeholder: { default:null, type:[String, Array, null] },
		errors: { default:null },
		// required: {},
    rules: [Object, Array],
    field: String,
		modelValue: {},
    type: {
      type:String,
      default:'text'
    },
    size:{},//input class form-control-lg
    hidden:{},
    disabled:{},
    tabindex:{},
    prepend:{},
    append:{},
    bordered:{},
    classes:{},
	  classinput:{},
    idinput:{default:null},
    autocomplete:{},
    autocapitalize:{},
    options:{},
    min:{},
    max:{},
		step:{},
		pattern:{},
    multiple:{},
    remote:{},
    select:{},
    selectData:{default:null},
    maxHeight:{},
    taggable:{default:null}, //select
    row:{},
		margin:{default:'mb-2'},

    textEnabled:null,//switch
    textDisabled:null,//switch
    colorEnabled:null,//switch
    colorDisabled:null,//switch

    toolbar:{},//textarea

    reload:{},//select
    exclus:{},//id exclus de la liste select
    add:{},//select
    tag:{},//select
    autoselect:{},//select
		searchable:{},//select
		loading:{},//select

    search:{},//type file
    dynamics:{},//type file
    accept:{},//type file
    dates:{},//type file
		precision:{},//type file
		tags_str:{},//type file

    items:{},//type list

		slider:{},//type checkbox
		inline:{},//type checkbox

		labels:{},//type switch3
		values:{},//type switch3
		colors:{},//type switch3
		fas:{},//type switch3

		approximative:{},//type date
		current:{},//type date
		time:{},//type date

		decimal:{},//type montant
		fa:{},//tous, icon en prefix a faible opacity
    styleinput:{},//mail
    classSuffix:{},//mail
		proposeTags:{},//textarea-hash
		forceTags:{},//textarea-hash
		tva:{},//montant
		enableTva:{},//montant
		data:{},//adresse
		id:{},//file
		produit_id:{},//file
  },
	emits:[
		'update:modelValue','update:altValue','update:errors','keydown','keyup','focus','blur','value','keydown','keyup','init','search-change','on-select','new','domaine','tag','name','prenom','nom'
	],
	data () {
    return {
			focused: false,
			// error:null,
      // isValid: true,
      // validateMessage: '',
      validateState: false,
      inputId:this.idinput ? this.idinput : "vipt"+( this.name ? this.name : this.$.uid), //uuid()
      // inputFocused:false,
    }
  },

  computed:{
    component(){
      let string = this.type;
      return 'VueInput' + string.charAt(0).toUpperCase() + string.slice(1);
    },
		error(){
			if(!this.field) return null;
			if(!this.errors) return null;
			if(!this.errors[this.field]) return null;
			return Object.values(this.errors[this.field]).map(e => e.msg ? e.msg : e);
		},
		errorClass(){
			if(!this.field) return null;
			if(!this.errors) return null;
			if(!this.errors[this.field]) return null;
			return Object.values(this.errors[this.field])[0].class ? Object.values(this.errors[this.field])[0].class : 'text-danger';
		}
  },

  methods:{
		focus(){
			this.$refs.input.focusInput();
		},
	  activate(){//multiselect
			this.$refs.input.activate();
		},
		onFocus(e){
			this.focused = true;
			if(this.field && this.errors && this.errors[ this.field ] !== undefined){
				delete this.errors[ this.field ];
			}
			this.$emit('focus', e);
		},
		onBlur(e){
			this.$emit('blur', e);
			this.focused = false;
		},
		onInputed(e, l=null){
			// if(this.errors && this.field) this.errors[this.field] = null;
			this.$emit('update:modelValue', e!=='' ? e : null, l);

			// RESET eventuelles erreurs serveur
			let errors = this.errors;
			if(this.field && this.errors && this.errors[ this.field ] !== undefined){
				delete this.errors[ this.field ];
			}
			this.$emit('update:errors', errors);
		},
		onValue(e){
			this.$emit('value', e);
		},
		onKeyDown(e){
			this.$emit('keydown', e);
		},
		onKeyUp(e){
			this.$emit('keyup', e);
		}
  }
}
</script>

<style lang="scss">
  @import "/src/assets/variables.scss";


	.field-container{
		max-width: 100%;
	}
	.input {
		max-width: 100%;
		background-color: getColor('white');
		transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
		border: 1px solid getColor('lightgray2');
		box-shadow: 0 1px 1px rgb(0 0 0 / 3%), 0 3px 6px rgb(0 0 0 / 2%);
		border-radius: 5px;

		input,
		select,
		textarea,
		.fakeinput{
			background-color: getColor('whitsmoke');
			color: getColor('black');
			border-radius: 5px;
			max-width: 100%;
			padding: 0.75rem;
			border:none;
			max-width: 100%;
			width: inherit;
		}
		//select{
		//	margin-right: 0.75rem;
		//}
	}

	.focused .input {
		outline: 0;
		border-color: transparentize(#828282, .8);//#0573e180
		box-shadow:
			0px 1px 1px rgba(0, 0, 0, 0.03),
			0px 3px 6px rgba(0, 0, 0, 0.02),
			0 0 0 3px transparentize(#828282, .4), 0 1px 1px 0 #00000014; //#0573e140

		input,
		select,
		textarea{
			outline: none;
			box-shadow: none;
			border:none
		}
	}


  .input-border-focused{
    margin:0;
    /*border-width:2px;*/
    //border-color:getColor(secondary-color);

		border: 1px solid #e6e6e6;
		box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
		border-radius: 5px;

    //-webkit-box-shadow: none;
    //box-shadow: none;
    outline: none;
  }

  .field-container{
		//position: relative;
		//display: block!important;
		//max-width: 100%;
		//width: 100%;

    //input{
    //  width: 100%;
    //}

    //.error-msg{
		//	padding: 0 5px;
    //}
    &.uppercase{
      input{
        text-transform: uppercase;
      }
    }
		&.lowercase{
			input{
				text-transform: lowercase;
			}
		}
    &.ucfirst{
      input{
        text-transform: capitalize;
      }
    }
		&.disabled{
			opacity: 0.8;
			.field,
			input{
				color:getColor('gray');
			}
		}

		//> label,
		//>.label-alt .btn{
		//	color: getColor('gray');
		//	padding: 0 5px;
		//	text-overflow: ellipsis;
		//	white-space: nowrap;
		//	overflow: hidden;
		//	display: block;
		//}
		.label-alt{
			position: absolute;
			top:0;
			right: 0;
		}
    .icon{
      opacity: 0.5;
    }
    &.focused{
			label{
				color: #828282;//getColor(primary);
			}
			//.field{
			//	background: getColor(primary-color-lighten);
			//}
      //.icon{
				//color: getColor(secondary-color);
      //}
		}

		&:not(.focused){
			.field{
				input:not([aria-valuetext])::-webkit-datetime-edit{
					color: getColor('placeholder');
					@extend .noselect;
				}
			}
		}
		.field{
			//background: getColor('whitesmoke');
			/*background: getColor(input-bg);*/
			/*border-width: 1px;*/
			/*border-style: solid;*/
			/*border-color: getColor(input-border);*/
			transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
			/*&:hover{*/
			/*	background: getColor('lightgray2');*/
			/*}*/
			width: 100%;
			font: 400 16px Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
			/*border: 1px solid red;*/
			//color: getColor('black');
			//margin: 0;
			//border-radius: 5px;
			//line-height: 40px;
			//height: 40px;
			/*padding: 0 10px;*/

			input::placeholder,
			textarea::placeholder,
			input::-webkit-input-placeholder,
			textarea::-webkit-input-placeholder,
			.multiselect__placeholder,
			.placeholder
			{
				//color: getColor('placeholder');
				//@extend .noselect;
				/*max-width:100%;*/
				/*min-width:0;*/
				/*<!--@extend .ellipsis;-->*/
			}

			input, textarea{
				border:none;
				//outline: none;
				//background: transparent;
				//height: 100%;
				//padding: 0 10px;
				//min-width: 0;
				//border-radius: 5px;
				//color:getColor('black');

				&[disabled] {
					cursor: no-drop;
					background-color: getColor('whitesmoke');
				}
				&[type='number'] {
					&::-webkit-inner-spin-button,
					&::-webkit-outer-spin-button {
						-webkit-appearance: none;
					}
				}

				&[type="date"]::-webkit-inner-spin-button,
				&[type="time"]::-webkit-inner-spin-button,
				&[type="date"]::-webkit-clear-button,
				&[type="time"]::-webkit-clear-button {
					display: none;
				}

				&[type="time"]::-webkit-calendar-picker-indicator,
				&[type="date"]::-webkit-calendar-picker-indicator{
					display: none;
				}
				&[type="time"]::-webkit-input-placeholder,
				&[type="date"]::-webkit-input-placeholder{
					visibility: hidden !important;
				}
			}

			//&.focus{
			//	@extend .input-border-focused;
			//	label{
			//		color:red;//getColor(secondary-color);
			//		/*<!--transform: scale(0.65) translateY(-20px) translateX(7px);-->*/
			//		/*background: white;*/
			//	}
			//}
		}

		$colorDanger:#df1b41;
		$colorWarning:#ffc107;
		$colorSuccess:#198754;
		&.input-invalid,
		&.input-text-danger {
			label{
				color:$colorDanger!important;
			}
			.field {
				color: $colorDanger;
				//border-color: $colorDanger;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px $colorDanger;

				border-color: transparentize($colorDanger, 0.2);
				//box-shadow: 0px 1px 1px transparentize($colorDanger, .3), 0px 3px 6px transparentize($colorDanger, .2);
				//0 0 0 3px transparentize($colorDanger, .6), 0 1px 1px 0 transparentize($colorDanger, 0.08)
			}
		}
		&.input-text-warning {
			label{
				color:$colorWarning!important;
			}
			.field {
				color: $colorWarning;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px $colorWarning;
				border-color: transparentize($colorWarning, 0.2);
			}
		}
		&.input-text-success {
			label{
				color:$colorSuccess!important;
			}
			.field {
				color: $colorSuccess;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px $colorSuccess;
				border-color: transparentize($colorSuccess, 0.2);
			}
		}
		//.input input{
		//
		//}
		//&.error{
		//	//border-color:#dc3545;
		//	label{
		//		color:#dc3545!important;
		//	}
		//	.field{
		//		//box-shadow: 0 0 0 1px #dc3545 inset;
		//		//border:1px solid;
		//		border-color:#dc3545!important;
		//	}
		//}

	}
</style>
