<template>
	<div class="produit-card" v-if="data">

		<div class="d-flex align-items-center h-100">
			<div style="min-width:100px; width: 100px">
				<visuel class="m-0" v-if="!data.visuels || data.visuels.length===0" :produit_id="data.id" :size="10" />
				<visuel class="m-0" v-if="data.visuels.length===1" :visuel_id="data.visuels[0].id" :size="10" />
				<visuel class="m-0" v-else :visuel_id="data.visuels[0].id" :size="10" />
			</div>
			<div class="flex-grow-1 mx-3 py-2 d-flex flex-column h-100">
				<router-link18n class="link stretched-link fw-bold" :to="{name:'produit',params:{id:data.id, slug:data.slug}}">
					{{ data.label }}
				</router-link18n>
				<div
					v-if="data.description && description"
					class="small"
					v-text="data.description.split('.').slice(0, 1).join('.')+'.'"></div>

				<div class="flex-grow-1"></div>
				<div v-if="produitsStore.getDeclinaison(data) && prix" class="text-centerX d-flex align-items-center">
					<template v-if="produitsStore.hasMultiPrix(data)">À partir de </template>

					<montant-httc
						v-if="produitsStore.getDeclinaison(data).prix_initial_ht"
						class="small fs-lg fw-boldX text-danger text-decoration-line-through me-3"
						field="prix_initial"
						:montants="produitsStore.getDeclinaison(data)" />
					<montant-httc :montants="produitsStore.getMinDeclinaison(data)" class="d-inline" />
<!--				</div>-->
<!--				<div class="text-center" v-if="produitsStore.getDeclinaison(data)" style="z-index: 2">-->
				</div>
			</div>
			<div v-if="btns || btns_add">
				<panier-btns
					style="z-index: 1"
					@add="$emit('add')"
					@remove="$emit('remove')"
					class="position-relative me-3 text-center lh-1"
					:declinaison_id="produitsStore.getDeclinaison(data).id"
					:display_btns="btns"
					:display_add="btns_add"
					:display_stock="false">
					<template v-if="btns_add">
						<i class="fa fa-bag-shopping-plus"></i>
					</template>
					<template #checkout v-if="btns_add">
						<i class="fa fa-bag-shopping"></i>
					</template>
				</panier-btns>
			</div>
		</div>

		<!--				</b>-->
<!--			</div>-->
<!--		</div>-->
	</div>
</template>
<style lang="scss" scoped>
@import '/src/assets/variables.scss';

.produit-card{
	display: block;
	background: getColor('white');
	//padding:15px;
	border-radius: 8px;
	position: relative;
	overflow: hidden;
	color: getColor('black');
	a{
	color: getColor('black');
		text-decoration: none;
	}

	//@extend .shadow;
	//.tags{
	//	position: absolute;
	//	top:30px;
	//	right:30px;
	//	.tag{
	//		background: lightgrey;
	//		border-radius: 5px;
	//		padding: 4px 6px;
	//		display: flex;
	//		align-items: center;
	//		img{
	//			max-height:50px;
	//			max-width:50px;
	//		}
	//	}
	//}
	.body{
		padding: 12px 12px;
	}
	.footer{
		padding: 0 12px 12px;
	}
}
</style>



<script lang="ts">
// const cartStore = useCartStore()
// const produitsStore = useProductStore()
// defineProps<{
// 	produit: Product
// }>();
// import type { Product } from '../stores/products'
import {useProductStore} from "../stores/products";
import {useCartStore} from "../stores/cart";
// import { AutoPlay, Pagination } from "@egjs/flicking-plugins";

export default {
	props:{
		produit:{default:null},//deprecated
		produit_id:{default:null},//new
		btns:{default:true},
		btns_add:{default:false},
		description:{default:false},
		prix:{default:true},
	},
	data(){
		return {
			cartStore: useCartStore(),
			produitsStore: useProductStore(),
			// plugins:[
				// new Pagination({ type: 'bullet' }),
				// new AutoPlay({ duration: 4000, direction: "NEXT", stopOnHover: true }),
				// new Arrow(),
			// ],
		}
	},
	emits:['add','remove'],
	computed:{
		data(){
			if(this.produit) return this.produit;
			return this.produitsStore.produit(this.produit_id);
		}
	},
	methods:{
		addPanierClose(declinaison_id){
			this.cartStore.add( declinaison_id );
			window.dispatchEvent(new CustomEvent('header-search-modal-close'));
		}
	}
}
</script>
