<template>
	<component :is="hasRupture || hasLimite || hasStock ? 'v-tooltip':'div'" :triggers="['click','hover','focus','touch']">
<!--		-->
		<div class="row align-items-center" v-if="display_select || display_add">
			<template v-if="display_select && addPossible">
				<div class="col-3 col-la-4 col-lg-3">
					<div class="field-container" :class="{focused:focused}" v-if="addPossibleQtt<10">
						<div class="field input">
							<select v-model="qtt" class="text-center" style="border-right: 0.75rem solid transparent!important;" @focus="focused=true" @blur="focused=false">
								<option :key="i" :value="i" v-for="i in addPossibleQtt">{{ i }}</option>
							</select>
						</div>
					</div>
					<vue-field
						v-else
						type="select"
						margin="0"
						style="text-align:center!important"
						v-model="qtt"
						:options="Array.from(Array(addPossibleQtt).keys()).map(function(i){ return {value:i+1,label:i+1}; }) " />
				</div>
			</template>
			<div v-if="display_add" class=" text-centerX mx-autoX" :class="display_select && addPossible ? 'col-9':'col-12'">
				<btn
					v-if="addPossible"
					color="primary"
					@click.stop.prevent="onAdd"
					:label="quantite>0 ? $t('panier.ajouter_autre_au_panier',qtt,{count:qtt}) : $t('panier.ajouter_au_panier')"
					:class="bclass"
					:disabled="!addPossible"><slot /></btn>
				<btn
					v-else-if="quantite>0"
					:to="{name:'checkout'}"
					:label="$t('panier.acceder_au_panier')"
					:class="bclass"
					color="primary">
					<slot name="checkout" />
				</btn>
				<btn
					v-else
					:label="quantite>0 ? $t('panier.ajouter_autre_au_panier',qtt,{count:qtt}) : $t('panier.ajouter_au_panier')"
					:class="bclass"
					color="primary disabled" ><slot /></btn>
				<!-- + Wishlist heart -->
			</div>
		</div>

		<div class="btn-group" style="z-index:1;background:var(--background); border-radius: 30px;" v-if="display_btns">
			<template v-if="quantite && maximum<=0">
				<btn :class="bclass" color="danger text-white btn-r" @click="cartStore.remove(declinaison_id)" fa="trash-alt" :alabel="$t('panier.retirer')" />
			</template>
			<template v-else>
				<btn :class="bclass" :key="'minus'+quantite" v-if="removePossible" color="light btn-r" @click="onRemove" :far="quantite>1 ? 'minus':'trash text-muted'" :alabel="$t('panier.retirer')" />
				<btn :class="bclass" v-if="quantite!==0 || display_btn0" class="ghost btn-light disabled no-animation" :alabel="$t('panier.quantite')">{{ quantite }}</btn>
				<btn :class="bclass" :key="'plus'+quantite" color="light btn-r" @click="onAdd" fa="plus" :disabled="!addPossible" v-if="addPossible" :alabel="$t('panier.ajouter')" />
			</template>
		</div>

		<template #popper v-if="hasRupture || hasLimite || hasStock">
			<div v-if="hasRupture" class="small text-danger">{{ $t('panier.en_rupture_stock') }}</div>
			<div v-else-if="hasLimite" class="small text-muted">{{ $t('panier.limite_x_exemplaires', declinaison.stock?.total, {count:declinaison.stock?.total}) }}</div>
			<div v-else-if="hasStock" class="small text-muted">{{ $t('panier.en_stock') }}</div>
		</template>
	</component>

</template>
<script>
	import Vibration from '/src/shared/Vibration'

	import {useCartStore} from "/src/stores/cart";
	import {useProductStore} from "/src/stores/products";

	export default {
		name: "PanierBtns",
		props: {
			declinaison_id:{dafault:null},
			display_btns:{default:true},
			display_btn0:{default:true},
			display_stock:{default:true},
			display_select:{default:false},
			display_add:{default:false},
			bclass:{default:null},
			modal:{default:true},
			prompt:{default:true},
			display_stock_info:{default:1},//1: text-center block  ou 2: inline-block
		},
		data(){
			return {
				cartStore:useCartStore(),
				qtt:1,
				focused:null,
			}
		},
		emits:['add','remove'],
		computed:{
			cartItem(){
				return useCartStore().delcinaison(this.declinaison_id)
			},
			declinaison(){
				return useProductStore().declinaisons[this.declinaison_id]
			},
			maximum(){
				return this.declinaison?.stock?.total || 0;
			},
			quantite(){
				return this.cartItem?.qtt || 0;
			},
			removePossible(){
				return this.quantite > 0;
			},
			addPossible(){
				return this.quantite < this.maximum;
			},
			addPossibleQtt(){
				return Math.max(0, this.maximum - this.quantite);
			},

			hasRupture(){
				return this.maximum===0 || this.quantite > this.maximum;
			},
			hasLimite(){
				return this.quantite >= this.maximum;
			},
			hasStock(){
				return this.display_stock && this.quantite <= this.maximum;
			}
		},
		methods:{
			onRemove(){
				Vibration.vibrate(100);
				// if(this.prompt && this.quantite<=1){
				// 	let valid = confirm(this.$t('panier.confirmer_suppression_article_panier'));
				// 	if(!valid) return;
				// }
				this.cartStore.remove(this.declinaison_id);
			},
			onAdd(){
				this.$emit('add');
				Vibration.vibrate(100);
				this.cartStore.add(this.declinaison_id, this.qtt );
				if(this.modal) window.dispatchEvent(new CustomEvent('header-panier-modal-open'));
			}
		}
	}
</script>

<style lang="scss" scoped>
	.btn-r{
		border-radius: 50%!important;
	}

	.no-animation{
		background: transparent;
		border: none;
	}
</style>
