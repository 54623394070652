
function prefix(key, strict){
	if(strict) return key;

	let token = null;
	if(typeof sessionStorage!=="undefined") {
		if('od_token' in sessionStorage) {
			token = JSON.parse(sessionStorage.getItem('od_token'));
		}
	}

	let newkey = (token && token.copropriete ? token.copropriete+'/' : '')
		+ (token && token.user ? token.user.id+'/' : '')
		+ key;
// console.log("newkey", newkey);
	return newkey;
}

class LocalStorage {

	strict = true;

	static setStrict(strict) {
		this.strict = strict;
	}

	static in(key){
		key = prefix(key, this.strict);
		if(typeof localStorage!=="undefined") {
			return key in localStorage;
		}
		return false;
	}

	static get(key){
		key = prefix(key, this.strict);
		if(typeof localStorage!=="undefined") {
			if(key in localStorage) {
				return JSON.parse(localStorage.getItem(key));
			}
		}
		return null;
	}

	static set(key, value){
		key = prefix(key, this.strict);
		if(typeof localStorage!=="undefined" && value!==undefined) {
			return localStorage.setItem(key, JSON.stringify(value));
		}
		return null;
	}

	static remove(key){
		key = prefix(key, this.strict);
		if(typeof localStorage!=="undefined") {
			if(key in localStorage) {
				return localStorage.removeItem(key);
			}
		}
		return null;
	}

	static clear(){
		if(typeof localStorage!=="undefined") {
			return localStorage.clear();
		}
		return null;
	}

}

export default LocalStorage;
