<template>
	<component
		ref="btn"
		v-bind="propsToPass"
		:is="to ? 'router-link18n':(href ? 'a':tag)"
		:class="btnClass"
		tabindex="0"
		:target="target"
		:exact="exact"
		:disabled="disabled || pending"
		:aria-label="alabel ? alabel : label"
		@keypress.enter="onClick"
		@click="onClick">
		<loader v-if="pending" class="position-absolute top-0" />
		<i v-if="fab" class="fab" :class="'fa-'+fab+(label?' me-2':'')" />
		<i v-if="fal" class="fal" :class="'fa-'+fal+(label?' me-2':'')" />
		<i v-if="far" class="far" :class="'fa-'+far+(label?' me-2':'')" />
		<i v-if="fa" class="fa" :class="'fa-'+fa+(label?' me-2':'')" />
		<span class="label" v-if="$slots.default || label"><slot>{{ label }}</slot></span>
	</component>
</template>
<script>
export default{
	name:'Btn',
	props:{
		to:{default:null},
		fa:{default:null},
		fab:{default:null},
		fal:{default:null},
		far:{default:null},
		href:{default:undefined},
		target:{default:null},
		label:{default:null},
		alabel:{
			default:null,
			// required: (propsListWithValues) => propsListWithValues.label===null
		},
		exact:{default:null},
		disabled:{default:null},
		pending:{default:false},
		color:{default:'light'},
		tag:{default:'button'},
		defaultclass:{default:'rounded-5 btn btn-'},
	},
	emits:['click'],
	computed: {
		btnClass() {
			return this.defaultclass
				+ this.color
				+ (this.disabled ? ' disabled':'')
				+ (this.pending ? ' position-relative overflow-hidden':'')
		},
		propsToPass(){
			if(this.to) return {to: this.to};
			if(this.href) return {href: this.href};
			return {};
		}
	},
	methods:{
		onClick(e){
			if(this.href) return;
			e.stopPropagation();
			e.preventDefault();
			if(this.pending) return;
			this.$emit('click', e);
		},
	}
}
</script>
