<template>
	<span>
		<montant :montant="montant" />
		<span v-if="!display_ttc" class="small ms-1 fw-normal">HT</span>
	</span>
</template>
<script>
import {useAuthStore} from "../stores/auth";

	export default{
		data(){
			return {
				authStore:useAuthStore()
			}
		},
		props: {
			montants:{default:null},
			field:{default:'prix'},
		},
		computed: {
			display_ttc(){
				return this.authStore.montant === 'ttc';
			},
			montant() {
				// console.log(this.field, this.montants[`${this.field}_ht`], this.montants[`${this.field}_ttc`])
				return this.montants[`${this.field}_${this.display_ttc ? 'ttc':'ht'}`];
			},
		},
	}
</script>
