import Xhr from './Xhr';
import LocalStorage from "./LocalStorage";
import {useAuthStore} from "../stores/auth";

class RestModel{
	constructor(){
		this.promise = null;
		this.id = 'id';
		this.value = 'label';
		// this.route = process.env.VUE_APP_ROOT_API;
		this.route = '';//import.meta.env.VITE_API_URL //deprecated : directement ajouté dans Xhr
	}


	appendRoot(string){
		if(string.substr(0, 4)!="http"){
			string =  import.meta.env.VITE_API_URL + string;
		}
		return string;
	}
	appendToken(string){
		let access_token = useAuthStore().access_token;

		string = this.appendRoot(string);

		string = string.toString();
		let parts = string.split("?");
		return parts[0]+"?"+(parts[1] ? parts[1]+"&" : "")+"token="+access_token;
	}

	url(){
		return this.model;
	}
	get(get){
		const url = this.model + '/' + get;
		// if(LocalStorage.in(url)){
		// 	console.log("LocalStorage.in(url)", url, LocalStorage.in(url));
		// 	new Promise(function(resolve, reject){
		// 		resolve(LocalStorage.get(url));
		// 	});
		// }
		let promise = Xhr('GET', url);
		promise.then(function(data){
			// console.log(data);
			// console.log("XHR", url);
			// LocalStorage.set(url, data);
			return data;
		});
		// .catch((e)=>{});
		return promise;
	}
	me(){
		const url = `${this.model}/me`;
		return Xhr('GET', url)
			.then(function(data){
				return data;
			});
	}
	post(post, data){
		const url = this.model + '/' + post;
		let promise = Xhr('POST', url, data);

		promise.then(function(data){
			return data;
		});
		// .catch((e)=>{});
		return promise;
	}
	put(put, data){
		const url = this.model + '/' + put;
		let promise = Xhr('PUT', url, data);

		promise.then(function(data){
			return data;
		});
		// .catch((e)=>{});
		return promise;
	}
	paginator(properties){
		// this.abortIfPromise();
		const url = this.model + '/paginator'+ (properties!==undefined && properties!==null ? '?'+properties : '');
		let promise = Xhr('GET', url);

		promise.then(function(data){
			return data;
		});
		// .catch((e)=>{});

		return promise;
	}


	list(properties){
		// this.abortIfPromise();
		const url = this.model + (properties!==undefined && properties!==null ? '?'+properties : '');
		this.promise = Xhr('GET', url)
			.then(function(data){
				return data.datas;
			})
		// .catch((e)=>{});
		return this.promise;
	}

	getCacheValue(value, name){
		let datas = this.getCache(name);
		return datas.filter(d => d.value === value)[0];
	}

	getCache(name){
		// if(name==='undefined'){
		// 	name = 'options';
		// }
		const storage = 'slct/'+this.model+'/'+name;
		// console.log("getCache", name, storage, LocalStorage.in(storage));
		LocalStorage.setStrict(false);
		if(LocalStorage.in(storage)){
			return LocalStorage.get(storage);
		}
		//@todo vérifier la validité du cache : inférieur à 1 min ?
		//@todo annuler toutes les requetes identiques et fournir la réponse
		return null;
	}
	setCache(liste, name){
		// if(name==='undefined'){
		// 	name = 'options';
		// }
		const storage = 'slct/'+this.model+'/'+name;
		LocalStorage.set(storage, liste);
	}

	options(){
		return this.list().then((datas) => {
			let liste = datas.map(function(data){
				return {
					value: data[this.id],
					label: data[this.value]
				}
			}.bind(this));
			this.setCache(liste, 'options');
			return liste;
		});
	}
	read(data){
		// this.abortIfPromise();
		const url = `${this.model}/${data.id}`;
		this.promise = Xhr('GET', url)
			.then(function(data){
				return data;
			})
		// .catch((e)=>{});
		return this.promise;
	}
	save(data={}, header={}){
		// console.log("data.get(this.id)", data, data.get(this.id), data instanceof FormData);
		let x1 = data[this.id] && data[this.id]!==null;
		let x2 = data instanceof FormData && data.get(this.id)!==null && data.get(this.id)!=='null';
		// console.log("x1", x1, data[this.id]);
		// console.log("x2", x2, data.get(this.id));
		if(x1 || x2){
			// console.log("update");
			return this.update(data, header);
		}else{
			// console.log("create");
			return this.create(data, header);
		}
	}
	create(data={}, header={}){
		const url = this.model;
		// if(data.id){
		// 	delete data.id;
		// }
		this.promise = Xhr('POST', url, data, header)
			.then(function(data){
				// if(data.code===200){
					return data;//.data;
				// }else{
				// 	console.warn("Xhr create save", data);
				// }
				// if(data.message){
				// 	alert(data.message);
				// }
			});
		//.catch((e)=>{});//.then(()=>{ this.promise = null; });
		return this.promise;
	}
	createAll(data={}, header={}){
		const url = `${this.model}/adds`;
		// if(data.id){
		// 	delete data.id;
		// }
		this.promise = Xhr('POST', url, data, header)
			.then(function(data){
				// if(data.code===200){
				return data.datas;
				// }else{
				// 	console.warn("Xhr create save", data);
				// }
				// if(data.message){
				// 	alert(data.message);
				// }
			});
		//.catch((e)=>{});//.then(()=>{ this.promise = null; });
		return this.promise;
	}
	update(data, header){
		let id = data[this.id] || data.get(this.id);
		const url = `${this.model}/${id}`;
		this.promise = Xhr('PUT', url, data, header)
			.then(function(data){
				// if(data.save){
				return data;
				// }
				// this.promise = null;
			})
		// .catch((e)=>{});
		return this.promise;
	}
	updateAll(ids, data){
		this.promise = Xhr('POST', `${this.model}/editall`, {ids:ids, datas:data})
			.then(function(xhr){
				return xhr;
			})
		return this.promise;
	}
	remove(data){
		const url = `${this.model}/${data[this.id]}`;
		this.promise = Xhr('DELETE', url, data)
			.then(function(data){
				// this.promise = null;
				if(data.delete){
					return data;
				}
			})
		// .catch((e)=>{}).then(()=>{ this.promise = null; });
		return this.promise;
	}
	removeAll(ids){
		const url = `${this.model}/deleteall`;
		this.promise = Xhr('DELETE', url, ids)
			.then(function(data){
				// this.promise = null;
				if(data.delete){
					return data;
				}
			})
		// .catch((e)=>{}).then(()=>{ this.promise = null; });
		return this.promise;
	}

	// abortIfPromise(){
	// 	if(this.promise){
	// 		this.promise.abort();
	// 	}
	// }
}
//
// if(global.RestModel){
//   RestModel = global.RestModel;
// } else {
//   global.RestModel = RestModel;
// }

export default RestModel;
