<template>
	<div class="visuel-top">
<!--			<div class="my-7 py-md-4 py-xl-6 py-xxl-7">-->
				<slot></slot>
<!--			</div>-->
	</div>
</template>
<script>
export default{
	name:'VisuelTop',
	props:{
		url:{default:null},
	}
}
</script>
<style lang="scss" scoped>
@import '/src/assets/variables.scss';
.visuel-top{
	position: relative;
	z-index: -1;
	//mask-image: linear-gradient(to top, transparent 0%, black 0%);
	background-size: cover;
	background-position-x: center;
	background-position-y: 35%;
	background-repeat: no-repeat;
}
</style>
