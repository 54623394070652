<template>
	<div class="collaps position-relative" :class="{visible:visible, b:border}">
		<div class="d-flex align-items-center title">
			<div class="flex-grow-1 pe-2">
				<slot>
					<h5>
						<i v-if="far" :class="`far fa-${far} me-3 text-center`" style="min-width:25px" />
						{{ title }}
					</h5>
				</slot>
			</div>
			<btn :fa="visible ? 'fa fa-chevron-up':'fa fa-chevron-down'" @click="tog" color="link stretched-link" />
		</div>
		<div class="contenu"><slot name="text" /></div>
	</div>
</template>
<style lang="scss" scoped>
	@import '/src/assets/variables.scss';
	.collaps{
		&.b{
			border-bottom: 1px solid getColor('black-transparent-80');
		}
		.title{
			padding: 2.5vh 0;
			:deep(*){
				margin: 0;
			}
		}
		.contenu{
			display: none;
		}
		&.visible{
			.title{
				padding-bottom: 1vh;
			}
			.contenu{
				display: block;
				padding-bottom: 2.5vh;
			}
		}
	}
</style>
<script>
	import Vibration from "/src/shared/Vibration";
	import {addCustomEvent} from "rrweb";

	export default {
		data: function() {
			return {
				visible:false,
			}
		},
		props:{
			border:{ default:true },
			title:{ default:null },
			far:{ default:null }
		},
		methods:{
			open(){
				Vibration.vibrate(100);
				this.visible = true;
				addCustomEvent('collaps-o', {v:this.title})
			},
			close(){
				Vibration.vibrate(100);
				this.visible = false;
				addCustomEvent('collaps-x', {v:this.title})
			},
			tog() {
				this.visible ? this.close() : this.open();
			},
		}
	}
</script>
