// const formatter = new Intl.NumberFormat('fr-FR', {
//     style: 'currency',
//     currency: 'EUR',
// })
//
// export const toCurrency = (value: number) => formatter.format(value)


export function join(array, separateur=", ", dernierSeparateur=" et ") {
	array = [...array];
	if(array.length>1){
		let last = array.pop();
		return array.join(separateur) + dernierSeparateur + last;
	}else{
		return array.join(separateur);
	}
}

export function de(string, tag, lower=false) {
	if (!string) return;
	if (lower) string = string.charAt(0).toLowerCase() + string.substring(1);
	return (['a', 'e', 'i', 'o', 'u', 'y'].indexOf(string.toLowerCase()[0]) > -1 ? "d'":"de ")+(tag?`<${tag}>`:``)+string+(tag?`</${tag}>`:``);
}
export function du(string, tag, lower=false) {
	if (!string) return;
	if (lower) string = string.charAt(0).toLowerCase() + string.substring(1);
	return (['a', 'e', 'i', 'o', 'u', 'y'].indexOf(string.toLowerCase()[0]) > -1 ? "de l'":"du ")+(tag?`<${tag}>`:``)+string+(tag?`</${tag}>`:``);
}
export function la(string, tag, lower=false) {
	if (!string) return;
	if (lower) string = string.charAt(0).toLowerCase() + string.substring(1);
	return (['a', 'e', 'i', 'o', 'u', 'y'].indexOf(string.toLowerCase()[0]) > -1 ? "l'":"la ")+(tag?`<${tag}>`:``)+string+(tag?`</${tag}>`:``);
}
export function le(string, tag, lower=false) {
	if (!string) return;
	if (lower) string = string.charAt(0).toLowerCase() + string.substring(1);
	return (['a', 'e', 'i', 'o', 'u', 'y'].indexOf(string.toLowerCase()[0]) > -1 ? "l'":"le ")+(tag?`<${tag}>`:``)+string+(tag?`</${tag}>`:``);
}
export function au(string, tag, lower=false) {
	if (!string) return;
	if (lower) string = string.charAt(0).toLowerCase() + string.substring(1);
	// return (['a', 'e', 'i', 'o', 'u', 'y'].indexOf(string.toLowerCase()[0]) > -1 ? "à ":"au ")+(tag?`<${tag}>`:``)+string+(tag?`</${tag}>`:``);
	return (['a', 'e', 'i', 'o', 'u', 'y'].indexOf(string.toLowerCase()[0]) > -1 ? "au ":"a un ")+(tag?`<${tag}>`:``)+string+(tag?`</${tag}>`:``);
}
export function numero(string, defo=null) {
	if (!string) return defo;
	return ("000"+string.toString()).substr(("000"+string.toString()).length-3, 3);
}
export function iban(string) {
	if(!string) return '';
	return (string.toString().replace(new RegExp(' ', 'g'), '')+'   ').match(/.{4}/g).join(' ');
}
export function etage(string) {
	if(typeof string==='number' && string===1) string = "1er";
	if(typeof string==='number' && string>1) string = string+"ème";
	string = string.replace(new RegExp('er', 'g'), '<sup class="small">er</sup>');
	string = string.replace(new RegExp('ème', 'g'), '<sup class="small">ème</sup>');
	return string;
}
export function suivi(string) {
	if(!string) return '';
	let x = string.toString().replace(new RegExp(' ', 'g'), '').match(/(.{2})(.{3})(.{3})(.{4})(.{1})/);
	if(!x) return string;
	x.shift();
	return x.join(' ');
}
export function suivifin(string) {
	if(!string) return '';
	let x = string.toString().replace(new RegExp(' ', 'g'), '').match(/(.{2})(.{3})(.{3})(.{4})(.{1})/);
	if(!x) return string;
	x.shift();
	return '...' + x.join(' ').substr(-6);
}
export function cadastre(string) {
	if(!string) return '';
	let x = string.toString().replace(new RegExp(' ', 'g'), '').match(/(.{5})(.{3})(.{2})(.{4})/);
	if(!x) return string;
	x.shift();
	return x.join(' ');
}
export function nd(string, remplace) {
	if (!string) return remplace ? remplace : '...';
	return string;
}
export function deci(string) {
	if(!string || ['number', 'string'].indexOf(typeof string)===-1) return '...';
	return parseFloat(string).toString();
}
export function montant(montant, displayZero=false) {
	if(!montant && !displayZero) return;
	return parseFloat(montant).toLocaleString('fr-FR', {style:'currency', currency:'EUR'});
}
// export function datefr(string) {
// 	if(!string) return;
// 	let options = { year: 'numeric', month: '2-digit', day: '2-digit'};
// 	return new Date(string.substr(0, 10)).toLocaleDateString('fr-FR', options);
// }
export function dateapproximative(string) {
	let double0 = string.indexOf('-00');
	let options = { year: 'numeric', month: '2-digit', day: '2-digit'};
	let mois = ['janvier','février','mars','avril','mai','juin','juillet','août','septembre','octobre','novembre','décembre'];

	switch(double0){
		case -1:
			string = new Date(string.replace(' ', 'T'));
			return string.toLocaleDateString('fr-FR', options);

		case 4:
			return string.substr(0, 4);

		case 7:
			return mois[string.substr(5, 2)-1]+" "+string.substr(0, 4);
	}
}
export function ucfirst(string) {
	if (!string) return '';
	string = string.toString();
	return string.charAt(0).toUpperCase() + string.slice(1);
}
export function uc(string){
	if (!string) return '';
	return string.toString().toUpperCase();
}
export function user(user){
	let string = [];
	if(user.genre) string.push(user.genre==='F' ? 'Mme.':'M.');
	if(user.prenom) string.push(ucfirst(user.prenom));
	if(user.nom) string.push(user.nom.toUpperCase());
	if(user.societe) string.push(user.societe);
	return string.join(' ');
}
export function surligne(string, match=''){
	return (string||'').replace(new RegExp(match,'gi'),(a)=>`<span class="surligne">${a}</span>`)
}

export function duree(durees, aucun='Aucun délai, généralement 30 jours', initial='...'){
	if(!durees) return aucun;
	durees = durees || [initial];

	// console.log(durees, durees[0], typeof durees[0], typeof durees[1])
	let min = durees[0];
	if(typeof min === "undefined") return aucun;
	if(min===0) return "Immédiat";
	let max = durees[ durees.length-1 ];

	return `En ${durees.join(' à ')} ${max>1 ? 'jours':'jour'}`;
}

// Store
// import store from '../store'
export async function clipboard(value) {
	if (navigator.clipboard) {
		try {
			await navigator.clipboard.writeText(value);
			// store.commit('notifPush', {message: `${ucfirst(msg)} a été copié !`, id: 'clipboard'});
			return
		} catch (err) {
			// store.commit('notifPush', {message: `Impossible de copier : ${err}.`, id: 'clipboard'});
		}
	}
};
