<template>
	<div class="card md:card-side bordered animate-pulse">
		<div class="p-8 md:w-64">
			<div class="bg-gray-500 rounded h-48"></div>
		</div>
		<div class="card-body">
			<div class="space-y-2">
				<div class="h-4 bg-gray-500 rounded"></div>
				<div class="h-4 bg-gray-500 rounded"></div>
				<div class="h-4 bg-gray-500 rounded w-5/6"></div>
				<div class="h-4 bg-gray-500 rounded w-1/4"></div>
			</div>
		</div>
	</div>
</template>
