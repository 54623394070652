<template>
	<div class="cookie" :class="{visible:cookie_visible}">
		<div class="container noselect">
			<div class="bg small d-sm-flex align-items-center">
				<i class="fa fa-cookie me-3 d-none d-sm-block"></i>
				<div class="flex-grow-1">
					<i class="fa fa-cookie me-3 d-inline-block d-sm-none"></i>
					{{ $t('cookies.nous_utilisons_cookies') }}<br class="d-none d-md-block">
					{{ $t('cookies.vous_pouvez_parametrer_dans_les') }} <span @click="openModal">{{ $t('cookies.parametres') }}</span>.
				</div>
				<btn class="d-sm-none ms-3 ms-md-5 text-nowrap position-absolute top-0 end-0 translate-middle btn-sm me-2" @click="acceptAll" :alabel="$t('cookies.accepter_et_fermer')" fa="fal fa-times" />
				<btn class="d-none d-sm-block ms-3 ms-md-5 text-nowrap" @click="acceptAll" fa="fal fa-times" :label="$t('cookies.accepter_et_fermer')" />
			</div>
		</div>
	</div>
	<modal-right nom="cookies" v-if="modal" @cancel="onCancelModal" position="center-bottom" :header="false" ref="cookiesModal">
		<div class="mb-3">{{ $t('cookies.configurez_vos_preferences') }}</div>
		<vue-field
			v-model="form.neccessary"
			:placeholder="$t('cookies.necessaires')"
			:labels="[$t('cookies.oui'), $t('cookies.non')]"
			:disabled="true"
			type="switch" />
		<div class="small text-muted mb-3">{{ $t('cookies.necessaires_desc') }}</div>

		<vue-field
			v-model="form.analytic"
			:placeholder="$t('cookies.experience')"
			:labels="[$t('cookies.oui'), $t('cookies.non')]"
			type="switch" />
		<div class="small text-muted mb-3">{{ $t('cookies.experience_desc') }}</div>

		<vue-field
			v-model="form.marketing"
			:placeholder="$t('cookies.marketing')"
			:labels="[$t('cookies.oui'), $t('cookies.non')]"
			type="switch" />
		<div class="small text-muted mb-3">{{ $t('cookies.marketing_desc') }}</div>

		<div class="d-flex">
			<btn class="text-decoration-none flex-grow-1" @click="acceptAll" :label="$t('cookies.accept_all')" />
			<btn color="primary ms-3" @click="saveSettings" fa="fal fa-check" :label="$t('cookies.save_settings')" />
		</div>
		<!--					<span>En poursuivant votre visite</span>-->
		<!--					<span class="d-none d-sm-inline">&nbsp;sur DAVID OLYSTER</span>-->
		<!--					<span>, vous acceptez nos politiques en matière de cookies et de données personnelles.</span>-->
		<!--						<router-link18n :to="{name:'privacy'}" @click.native="closeCookie">En savoir plus</router-link18n>.-->
		<!--						Découvrez comment modifier vos parmètres en consultant nos-->
		<!--						<router-link18n :to="{name:'privacy'}" @click.native="closeCookie">politiques de confidentialité</router-link18n>.-->
		<!--				DAVID OLYSTER utilise les cookies afin d'améliorer votre expérience utilisateur.-->
		<!--				En visitant notre site, vous acceptez notre politique en matière de cookies-->
		<!--				ainsi que le partage de vos données personnelles avec nos partenaires affiliés.-->
		<!--				Pour en savoir plus ou découvrir comment modifier vos parmètres de cookies, consultez nos-->
		<!--				<router-link :to="{name:'privacy'}" @click.native="closeCookie">politiques de confidentialité</router-link>.-->
		<!--				</span>-->
	</modal-right>
</template>
<style lang="scss" scoped>
	@import '/src/assets/variables.scss';
	.cookie{
		position:fixed;
		left: 0;
		right: 0;
		bottom:15px;
		z-index: 10;
		color: getColor('whitesmoke');

		transition:transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
		&:not(.visible) {
			transform: scale(0.95) translateY(100px);
			opacity: 0;
			pointer-events: none;
		}

		.bg{
			padding: 15px;
			border-radius: 8px;
			background: $background-nav;
		}
		.btn{
			float: right;
		}
	}
</style>
<script>
	export default{
		name:'CookieSettings',
		data: function() {
			return {
				modal:false,
				cookie_visible:false,
				cookiesConfigures:false,
				form:{
					neccessary:true,
					analytic:true,
					marketing:true,
				}
			}
		},
		created() {
			this.cookiesConfigures = this.$cookies.get('od');
			window.addEventListener('cookies-modal-open', this.openModal);
			if(!this.cookiesConfigures) this.cookie_visible = true;
		},
		beforeUnmount(){
			window.removeEventListener('cookies-modal-open', this.openModal);
		},
		methods:{
			openModal(){
				this.cookie_visible = false;
				this.form.analytic = JSON.parse(this.$cookies.get('od_analytic') || true);
				this.form.marketing = JSON.parse(this.$cookies.get('od_marketing') || true);
				this.modal = true;
			},
			acceptAll(){
				this.form = {
					neccessary:true,
					analytic:true,
					marketing:true,
				}
				this.saveSettings();
			},
			saveSettings(){
				this.cookiesConfigures = true;
				this.cookie_visible = false;
				this.$cookies.set('od', new Date().getTime());
				this.$cookies.set('od_analytic',!!this.form.analytic);
				this.$cookies.set('od_marketing',!!this.form.marketing);
				this.closeModal();
			},
			closeModal(){
				this.$refs.cookiesModal?.closeModal();
			},
			onCancelModal(){
				this.modal = false;
				if(!this.cookiesConfigures){
					this.cookie_visible = true;
				}
			}
		}
	}
</script>
