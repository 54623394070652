import { createRouter, createWebHistory } from 'vue-router'
import { addCustomEvent } from 'rrweb'
import routes from './routes.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // return { top: 0 }

    // if(to.meta.top){
      return { top: 0, left: 0, behavior: 'instant'}
    // }
    // if (to.hash) {
    //   return {
    //     el: to.hash,
    //     // offset = { y: 100 }
    //   }
    // }
    // if (savedPosition) return savedPosition
    //
    // if(to.meta.noscroll){
    //   return {}
    // }
    // if (to.query.modal !== undefined || from.query.modal !== undefined) {
    //   return {}
    // }
    // return { top: 0, left: 0 }
  },
})

import {useAuthStore} from "../stores/auth";

router.beforeResolve((to, from, next) => {

  // ✅ This will work because the router starts its navigation after
  // the router is installed and pinia will be installed too
  // if(to.meta.head_fixed){
  //
  // }
  // console.log(to.name, to.meta, to);
  const authStore = useAuthStore();
  let metas = getVars(to);
  // let metas = to.meta;
  // console.log("beforeEach", to, metas, authStore.isLoggedIn);
  if (metas.scope) {
    // console.log("beforeEach présence de scope : ", metas.scope);
    if (!authStore.isLoggedIn) {
      console.warn("beforeEach pas loggé", to.fullPath);
      authStore.returnUrl = to.fullPath;
      // console.log(">>", authStore.returnUrl);
      next({ name: "signin", params: { lang: to.params.lang }  });
      return;
    } else if (!authStore.hasScope(metas.scope)) {
      let xx = Object.assign(to.params, { code: 400 });
      console.warn("beforeEach loggé mais pas scope pour", to.fullPath, xx);
      next({ name: "erreur", params: xx });
      return; //, params:{code:'400'}
    }
  }

  next();
});

router.afterEach((to) => {
  addCustomEvent('route', {path:to.fullPath})
})

function getVars(to) {
  let vars = to.meta;
  if (to.matched.length > 0) {
    vars = Object.assign(...to.matched.map(function (value) {
      if (value && value.meta) return value.meta;
      return value;
    }));
  }
  return vars;
  // if(to.matched.some(record => record.meta.requiresAuth)) {
}

export default router;


