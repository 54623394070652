<template>
	<btn
		v-if="!authStore.isLoggedIn || authStore.isLoggedIn && authStore.hasMail"
		color="outline-dark btn-sm position-relative ms-1"
		:alabel="$t('account.my_account')"
		:to="{name:'my-compte'}">
		<i class="far fa-user"></i>
		<span v-if="authStore.isLoggedIn" class="ms-2 d-none d-md-inline-block">{{ authStore.user.prenom }}</span>
	</btn>
</template>
<script lang="ts">
import { useAuthStore } from "../stores/auth";
export default {
	name:'header-user',
	data(){
		return {
			authStore:useAuthStore(),
		}
	},
}
</script>
