import { defineStore } from 'pinia';

// import { fetchWrapper } from '@/helpers';
// import { router } from '/src/router/index.js';

// import { createRouter, createWebHistory } from 'vue-router'
import { useRouter } from 'vue-router'
// pinia.router =
import Xhr from "../shared/Xhr";
import {useCartStore} from "./cart";
import cat from "countries-and-timezones";
import {addCustomEvent} from "rrweb";
// import { useAlertStore } from '@/stores';
// import { useI18n } from "vue-i18n";

// async function getUserFromToken(token=null) {
// 	console.log('getUserFromToken token', token);
// 	if(!token) return;
// 	await Xhr('GET', `/sign/token/${token}`).then((data)=>{
// 		console.log('getUserFromToken data', data);
// 	});
// 	// const user =
// }
// const router = useRouter();

export const useAuthStore = defineStore({
	id: 'auth',
	state: () => ({
		// initialize state from local storage to enable user to stay logged in
		token: JSON.parse(localStorage.getItem('od_token')),//getUserFromToken()
		returnUrl: null,
		router:useRouter(),
		// i18n:useI18n(),
		// isLoggedIn:this.token && this.token.user && this.token.user.id
	}),
	getters: {
		user(store) {
			return store.token ? store.token.user : null;
		},
		userId(store) {
			return store.token && store.token.user ? store.token.user.id : null;
		},
		userNom(store){
			return store.token && store.token.user ? [store.token.user.prenom, store.token.user.nom].filter(x=>x).join(' ') : null;
		},
		userTel(store){
			return store.token?.user?.tel;
		},
		userMail(store){
			return store.token?.user?.mail;
		},
		hasMail(store){
			return store.token && store.token.user && store.token.user.mail;
		},
		stripe_customer(store){
			return store.token && store.token.user ? store.token.user.stripe_customer : null;
		},
		access_token(store){
			return store.token ? store.token.access_token : null;
		},
		isLoggedIn(store) {
			return store.token && store.token.user && store.token.user.id;
		},
		montant(store){
			return store.token && store.token.user ? store.token.user.montant : 'ttc';
		},
		newsletters(store){
			return store.token && store.token.user ? store.token.user.newsletters : false;
		},

	},
	actions: {
		hasScope(scope) {
			let token = this.token;
			if(!token || !token.scopes) return false;

			if(typeof scope === 'string'){
				if(scope.substr(0,1)==='!') return !(token.scopes.indexOf(scope)>-1);
				return token.scopes.indexOf(scope)>-1;
			}

			if(typeof scope == 'object'){
				let droits = [];
				for(let i=0; i<scope.length; i++){
					if(scope[i].substr(0,1)==='!'){
						let sc = scope[i].substring(1, scope[i].length);
						if(token.scopes.indexOf(sc)>-1){
							droits.push(false);
						}else{
							droits.push(true);
						}
					}
					if(token.scopes.indexOf(scope[i])>-1){
						droits.push(true);
					}
				}
				let trues = droits.filter(d=>d===true).length;
				let falses = droits.filter(d=>d===false).length;
				return falses===0 && trues>0;
			}
		},
		setToken(token=null, origine=null){

			// Pinia state
			this.token = token;

			addCustomEvent('auth', { m:this.userMail, from:origine });

			// Storage
			if(!token) {
				localStorage.removeItem('od_token');
				return;
			}
			localStorage.setItem('od_token', JSON.stringify(token));

			// applique la langue
			// @todo vérifier l'application de la langue de l'user api lors du sign
			if(this.$i18n && this.$i18n.locale !== token.user.langue) this.$i18n.locale = token.user.langue;
		},
		async signIn(mail, password, redirect=null) {
			try {
				let panier = useCartStore().id;
				return await Xhr('POST',`/sign/in`, { mail, password, panier }).then((data)=>{
					if(data.code===200){
						this.setToken(data.token, 'in');

						// Modifier le panier suite au merge
						if(data.panier?.hash){
							console.log(`Reprise Panier ${data.panier?.hash} en remplace du ${panier}`, data.panier);
							useCartStore().setPanier(data.panier);
						}

						// redirect to previous url or default to home page
						if(redirect===false) return;
						let url = this.returnUrl || {name:'accueil'};
						// console.log("url", this.returnUrl, url);
						this.router.push(url);
						return null;
					}else{
						return [data.message, data.errors];
					}
				});
			} catch (error) {
				console.log("ERROR", error);
				// const alertStore = useAlertStore();
				return [error];
				// alertStore.error(error);
			}
		},
		async signUp(form, redirect=null, returnData=false) {
			form.langue = "fr"; //this.i18n.locale;
			form.panier = useCartStore().id;

			try {
				return await Xhr('POST',`/sign/up`, form).then((data)=>{
					// console.log("signup", data);

					if(data.code===200) {
						if(returnData === true) return data;

						if(!data.token) return false;

						this.setToken(data.token, 'up');

						// redirect to previous url or default to home page
						if(redirect === false) return data;
						this.router.push(this.returnUrl || {name: 'accueil'});
						return true;
					}else{
						return [data.message, data.errors];
					}
				});
			} catch (error) {
				console.log("ERROR", error);
				// const alertStore = useAlertStore();
				// alertStore.error(error);
				return [error];
			}
		},
		signOut() {
			this.router.push({name: 'signin'});

			this.setToken(null, 'out');

			useCartStore().reset();
			// localStorage.removeItem('user');
		},

		async subscribeNewsletter(value, form= {}){
			//form:prenom,nom,mail,tel
			// console.log('subscribeNewsletter', value, mail);

			if(this.userId){
				// Update l'user connecté
				return Xhr('PUT', `${import.meta.env.VITE_API_URL}users/${this.userId}`, {newsletters:value}).then((data)=>{
					if(data.code===200){
						this.setUserField('newsletters', data.data.newsletters);
						return true;
					}else{
						console.error(data);
						return false;
					}
				})
			} else {
				// Crée un user & set newsletter true
				return await this.signUp({
					mode:'salon',
					prenom:form?.prenom,
					nom:form?.nom,
					mail:form?.mail,
					tel:form?.tel,
					newsletters:true
				});
			}
		},


		saveUserField(form){
			if(!this.userId) return;
			Xhr('PUT', `${import.meta.env.VITE_API_URL}/users/${this.userId}`, form).then((data)=>{
				if(data.code===200){
					let keys = Object.keys(form);
					let values = Object.values(form);
					for(let i=0; i<keys.length; i++){
						this.setUserField(keys[i], values[i]);
					}
				} else {
					console.error(data);
				}
			})
		},


		setUser(user){
			this.token.user = user;
			localStorage.setItem('od_token', JSON.stringify(this.token));
		},
		setUserField(field, value){
			this.token.user[field] = value;
			localStorage.setItem('od_token', JSON.stringify(this.token));
		}
	}
});
