<template>
	<div class="d-flex align-items-center">
		<i class="far me-3 h3 m-0" :class="`fa-${fa}`" />
		<div class="lh-1">
			<div class="h3 m-0">{{ titre }}</div>
			<div><slot /></div>
		</div>
	</div>
</template>
<script>
	export default {
		props:{
			fa:{},
			titre:{},
			info:{}
		}
	}
</script>
