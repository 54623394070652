<template>
	<div class="d-flex align-items-center">
		<slot />
	</div>
</template>
<style lang="scss" scoped>
	@import '/src/assets/variables.scss';
	.d-flex{
		> :first-child{
			flex:1;
		}
		> :last-child{
			text-align:right;
			margin-left: 15px;
			@include smartphones(){
				margin-left: 5px;
			}
		}
		&.flex-column > :last-child{
			@include smartphones(){
				margin-left: 0;
			}
		}
	}
</style>
<script>
	export default {
		name:'Divflex',
	}
</script>
