<template>
	<btn
		color="outline-dark btn-sm position-relative ms-1"
		:alabel="$t('panier.mon_panier')"
		:to="{name:'checkout'}">
		<i class="far fa-bag-shopping"></i>
		<small
			v-if="count"
			class="position-absolute top-0 start-sm-50 start-100 translate-middle badge rounded-pill bg-danger small py-1 px-2 py-sm-1">
			{{ count }}
			<span class="visually-hidden">produits</span>
		</small>

		<span class="d-none d-md-inline-block ms-2">{{ $t('panier.mon_panier') }}</span>
	</btn>
</template>
<script>
import { useCartStore } from '../stores/cart';
export default {
	name: "header-panier",
	data:()=>{
		return {
			cartStore:useCartStore(),
		}
	},
	computed:{
		count(){
			return useCartStore().count;
		},
	},
}
</script>
<style lang="scss" scoped>
	@import "/src/assets/variables.scss";
	.start-sm-50{
		@include smartphones(){
			left:80%!important;
		}
	}
</style>
