<template>
	<figure :class="finalclass">
		<picture class="w-100" :class="vclass">
<!--			<source srcset="image-big.png" type="image/png" media="(min-width:1920px)">-->
<!--			<source srcset="image-med.png" type="image/png" media="(min-width:1200px)">-->
<!--			<source srcset="image-small.png" type="image/png" media="(min-width:700px)">-->
			<source :srcset="produitModel.url(type, id, size, 'webp')" type="image/webp" class="w-100" :class="vclass" draggable="false">
			<img :src="produitModel.url(type, id, size, 'jpg')" :alt="`${alt} - DAVID OLYSTER`" class="w-100" :class="vclass" draggable="false">
		</picture>
		<figcaption v-if="label">{{ label }}</figcaption>
	</figure>
</template>
<style lang="scss" scoped>
	figure{
		overflow: hidden;
		margin: 0;
		position: relative;
		//aspect-ratio: 1;
		&.hover:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: #e4e3df;
			mix-blend-mode: multiply;
			opacity: 0.4;
		}
	}
	figure,
	picture,
	figcaption{
		user-select: none;
	}
</style>
<script>
import Produit from "../services/Produit";
export default{
	name:'Visuel',
	props:{
		visuel_id:{default:null},
		declinaison_id:{default:null},
		produit_id:{default:null},
		actu_id:{default:null},
		size:{default:10},
		alt:{default:null},
		label:{default:null},
		vclass:{default:null},
		hover:{default:false},//ajouter une surcouche couleur gris
	},
	data(){
		return{
			produitModel:new Produit()
		}
	},
	computed:{
		finalclass(){
			return (this.vclass||'') + (this.hover ? ' hover':'');
		},
		type(){
			if(this.visuel_id!==null) return 'visuel';
			if(this.declinaison_id!==null) return 'declinaison';
			if(this.produit_id!==null) return 'produit';
			if(this.actu_id!==null) return 'actu';
			return 'default';
		},
		id(){
			if(this.visuel_id!==null) return this.visuel_id;
			if(this.declinaison_id!==null) return this.declinaison_id;
			if(this.produit_id!==null) return this.produit_id;
			if(this.actu_id!==null) return this.actu_id;
			return 'empty';
		}
	}
}
</script>
