import { defineStore } from 'pinia';
import {Product} from "./products";
import Xhr from "../shared/Xhr";
const fakeStoreUrl = '/principesactifs/paginator?sort=ordre ASC';
const url = fakeStoreUrl;

export interface Principe {
  id: number;
  slug: string;
  label: string;
  text: string;
  date: string;
}

interface ActualiteState {
  items: Principe[];
  // ids: number[];
}

export const usePrincipeStore = defineStore({
  id: 'principes',

  state: (): ActualiteState => ({
    items: [],
    ids: [],
  }),

  getters: {
    // list(): Principe[] {
    //   return this.items;//.map((i) => this.items[i]);
    // },
    list(): Principe[] {
      return this.ids.map((i) => this.items[i]);
    },
    loaded(): boolean {
      return this.items.length > 0;
    },
  },

  actions: {
    // async fetchAll() {
    //   // console.log("fetchAll", this.loaded);
    //   if (this.loaded) return;
    //
    //   var data = null;
    //
    //   if(!sessionStorage.getItem('od_principes')) {
    //     const res = await fetch(url);
    //     data = await res.json().then(json => json.response);
    //     sessionStorage.setItem('od_principes', JSON.stringify(data));
    //   }else{
    //     data = JSON.parse(sessionStorage.getItem('od_principes'));
    //   }
    //
    //   // const res = await fetch(url);
    //   // this.items = await res.json().then(json => json.response);//: Principe[]
    //   // this.items = data;
    //
    //   this.ids = data.map((principe) => {
    //     this.items[principe.id] = principe;
    //     return principe.id;
    //   });
    // },
    fetchAll(force=false) {
      // console.log("fetchAll loaded", this.loaded);
      if (!force && this.loaded) return;
      // var data: Product[] = null;
      let storage = sessionStorage.getItem('od_principes');
      if(storage && storage!=="undefined") {
        this._setMap( JSON.parse(sessionStorage.getItem('od_principes')), 'storage' );
      }

      Xhr('GET', url).then((xhr)=>{
        // console.log("xhr",xhr);
        sessionStorage.setItem('od_principes', JSON.stringify(xhr.response));
        this._setMap( xhr.response, "xhr" );
      });
    },
    _setMap(data: Principe[] = null, from){
      // console.log("set_map principes",from, data)
      this.ids = data.map((product) => {
        this.items[product.id] = product;
        return product.id;
      });
    },
    principe(id){
      return this.items[id];
    }
  },
});
