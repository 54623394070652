<template>
	<teleport to="#modals">
		<div
			class="mymodal"
			:class="{visible:visible}"
			tabindex="0"
			ref="modal"
			@keyup.esc="closeModal"
			@keypress.esc="closeModal">
			<div ref="modal" class="mymodal-content" :class="`modal-${position}`">
				<slot name="all" :closeModal="closeModal">
					<slot name="header" :closeModal="closeModal" v-if="header">
						<div class="header bg-dark text-light p-4">
							<div class="d-flex align-items-center">
								<btn v-if="btn_close && position.substring(0,6)!=='center'" @click="closeModal" fa="chevron-left" color="link text-white ps-0 me-3" />
								<h5 class="m-0 flex-grow-1"><slot name="title" /></h5>
								<btn v-if="btn_close && position.substring(0,6)==='center'" @click="closeModal" fa="times" color="link text-white pe-0 ms-3" />
							</div>
						</div>
					</slot>
					<slot name="body" :closeModal="closeModal">
						<div class="p-4 flex-grow-1">
							<slot :closeModal="closeModal"  />
						</div>
					</slot>
					<div v-if="$slots.footer" class="p-4">
						<slot name="footer" :closeModal="closeModal"></slot>
					</div>
				</slot>
			</div>
			<div ref="bg" class="mymodal-bg" @click="closeModal" @scroll.prevent @wheel.prevent @contextmenu.prevent />
		</div>
	</teleport>
</template>
<style scoped lang="scss">
@import '/src/assets/variables.scss';

.mymodal{
	.mymodal-content{
		position:fixed;
		z-index:150;
		box-shadow: 0 3px 8px rgba(0,0,0,.2);
		//background: getColor('white');
		background: getColor('white');
		top: 0;
		bottom: 0;
		width: 500px;
		max-width: 88vw;
		overflow-x: hidden;
		overflow-y: overlay;
		transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
		white-space: initial;
		text-align: initial;
		opacity: 0;
		-webkit-overflow-scrolling: touch;
		scroll-behavior: smooth;
		display: flex;
		flex-direction: column;
		//min-height: 100%;
		min-height: calc(100% + env(safe-area-inset-top));
		//max-height: 100vh;
		//max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
		padding-bottom: env(safe-area-inset-bottom);
		//overscroll-behavior: none;
		overscroll-behavior: contain;


		&.modal-center-bottom {
			left: calc(50% - min(500px, 88vw) / 2);
			top: initial;
			bottom: 0;
			//height: 300px!important;
			min-height: initial;
			max-height: 90vh;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			transform: translateY(100%);
			@include smartphones() {
				left: 0;
			}
		}
		&.modal-left{
			left:0;
			transform: translateX(-100%);//scale(0.98)
		}
		&.modal-right{
			transform: translateX(100%);//scale(0.98)
			right: 0;
		}
		&.modal-center-left{
			left: 10%;
		}
		&.modal-center-right{
			right: 10%;
		}
		&.modal-x-center{
			left: calc(50% - min(500px, 88vw)/2);
		}
		&.modal-center{
			transform: translateY(10%);
			top: 50vh;
			min-height: inherit;
			bottom: inherit;
			//border-top-left-radius: 10px;
			//border-top-right-radius: 10px;
			border-radius: 10px;
			max-height: 80vh;
			@include smartphones(){
				right: 0;
				left: 0;
				top:inherit;
				bottom: 0;
				max-height: 80vh;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				transform: translateY(50vh);
			}
		}
		&.modal-lg{
			min-width: 80vw;
		}

		@include smartphones(){
			min-width: initial;
			width: 100%;
			max-width: 100%;
		}

		.header{
			position: sticky;
			top:0;
			z-index: 5;
			border-top:env(safe-area-inset-top) solid getColor('primary-color-darken');
			//border-color:getColor('primary-color-darken');
		}
		&.modal-center{
			.header {
				border-top: none;
			}
		}
	}
	.mymodal-bg{
		content:'';
		position: fixed;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index:105;
		transition: opacity 0.3s ease-in-out;
		opacity:0;

		background: $modal-back;
		//background: red;
		-webkit-backdrop-filter: blur(2px);
		backdrop-filter: blur(2px);
		pointer-events:none;
	}
	&.visible{
		>.mymodal-content{
			opacity: 1;
			transform: translateX(0px);// scale(1);
			&.modal-center{
				//transform: translateY(0px);// scale(1);
				transform: translateY(-50%);// scale(1);
				@include smartphones(){
					transform: translateY(0px);
				}
			}
		}
		>.mymodal-bg{
			opacity: 0.6;
			pointer-events:inherit;
		}
	}
}
</style>
<script>
import { addCustomEvent } from "rrweb";

export default{
	props:{
		btn_close: {default:true},
		header: {default:true},
		position: {default:'right'},
		nom: {default:null},
	},
	data() {
		return {
			visible: false,
		}
	},
	emits:['cancel'],
	created(){
		this.openModal();
	},
	// computed:{
		// title(){
		// 	return this.$slots.title ? this.$slots.title()[0]?.children : null;
		// }
	// },
	methods:{
		openModal(){
			this.visible = false;
			if(this.nom) addCustomEvent('modal-o', {v:this.nom});
			this.$nextTick(()=>{
				setTimeout(()=>{
					this.visible = true;
					// document.body.classList.add('overflow-hidden');
					this.$refs.modal?.focus({
						preventScroll: true
					});
				}, 100)
			})
		},
		closeModal(){
			this.visible = false;
			if(this.nom) addCustomEvent('modal-x', {v:this.nom});
			// document.body.classList.remove('overflow-hidden');
			this.$nextTick(()=>{
				setTimeout(()=>{
					this.$emit('cancel');
				}, 300)
			});
		},
	}
}
</script>
