import { onUnmounted } from 'vue'
import { useCartStore } from '../stores/cart'

export const CART_ITEMS = 'od_cart_items'
export const CART_ID = 'od_cart_id'

export const usePersistCart = () => {
   const cartStore = useCartStore()

   const unsub = cartStore.$subscribe(() => {
      localStorage.setItem(CART_ITEMS, JSON.stringify(cartStore.contents))
      localStorage.setItem(CART_ID, JSON.stringify(cartStore.id))
   })

   onUnmounted(() => {
      unsub()
   })
}
