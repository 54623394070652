<template>
	<span>{{ formatedDate }}</span>
</template>
<script>
	export default{
		props: {
			date:{ type: String },
			year:{ default: true },
			weekday:{
				type:[Boolean, String],
				default:undefined,
				validator: function (value) {
					return ['short', 'long', undefined].indexOf(value) !== -1
				}
			},
			approximative:{ default: false },
		},
		computed: {
			formatedDate() {
				if(!this.date) return null;
				let date = new Date(this.date.replace(' ', 'T'));
				let options = { month: '2-digit', day: '2-digit', weekday:this.weekday };
				if(this.year) options.year = 'numeric';

				let locale = this.$i18n.locale;
				// console.log('locale',locale);
				return date.toLocaleDateString(locale, options);
			},
		},
	}
</script>
