import Accueil from "../pages/Accueil.vue";

import Paiement from "../pages/Paiement.vue";

const Sign = () => import(/*webpackChukName:"sign"*/ '../views/auth/sign.vue');
const SignIn = () => import(/*webpackChukName:"sign"*/ '../views/auth/sign-in.vue');
const Recovery = () => import(/*webpackChukName:"sign"*/ '../views/auth/recovery.vue');
const ChangePassword = () => import(/*webpackChukName:"sign"*/ '../views/auth/password.vue');
const PasswordCode = () => import(/*webpackChukName:"sign"*/ '../views/auth/password-code.vue');

const MyAccountAll = () => import(/*webpackChukName:"account"*/ '../views/my/my-compte-all.vue');
const MyCompte = () => import(/*webpackChukName:"account"*/ '../views/my/my-compte.vue');
const MyAdresses = () => import(/*webpackChukName:"account"*/ '../views/my/my-adresses.vue');
const MyCommandes = () => import(/*webpackChukName:"account"*/ '../views/my/my-commandes.vue');
const MyCommande = () => import(/*webpackChukName:"account"*/ '../views/my/my-commande.vue');
// import MyFidel from "../views/my/my-fidel.vue";
// import MyRemises from "../views/my/my-remises.vue";

const Produit = () => import(/*webpackChukName:"produit"*/ '../pages/Produit.vue');
const Checkout = () => import(/*webpackChukName:"checkout"*/ '../pages/Checkout.vue');
const CheckoutConfirmation = () => import(/*webpackChukName:"checkout"*/ '../pages/checkout-confirmation.vue');
const CheckoutReprise = () => import(/*webpackChukName:"checkout"*/ '../pages/checkout-reprise.vue');

const Actualite = () => import(/*webpackChukName:"actu"*/ '../pages/Actualite.vue');

const Contact = () => import(/*webpackChukName:"contact"*/ '../pages/contact.vue');

const Terms = () => import(/*webpackChukName:"terms"*/ '../pages/terms.vue');
const TermsMentions = () => import(/*webpackChukName:"terms"*/ '../pages/terms-mentions.vue');
const TermsRetours = () => import(/*webpackChukName:"terms"*/ '../pages/terms-retours.vue');
const TermsCgu = () => import(/*webpackChukName:"terms"*/ '../pages/terms-cgu.vue');
const TermsCgv = () => import(/*webpackChukName:"terms"*/ '../pages/terms-cgv.vue');
const TermsConfidentialite = () => import(/*webpackChukName:"terms"*/ '../pages/terms-confidentialite.vue');
const TermsCookies = () => import(/*webpackChukName:"terms"*/ '../pages/terms-cookies.vue');

const Erreur = () => import(/*webpackChukName:"erreur"*/ '../pages/erreur.vue');

import routesadmin from "./routes-admin.js";

import { useAuthStore } from "../stores/auth";
function logout() {
  const authStore = useAuthStore();
  authStore.signOut();
}
function notin(to, from, next) {
  if (useAuthStore().isLoggedIn)
    return next({ name: "my-compte", params: { lang: to.params.lang } });
  return next();
}

export default [
  { path:'/', redirect: '/fr' },
  { path:'/:lang(\\w{2,4})', meta: { scope: null }, children:[
    { path: "", name: "accueil", component: Accueil, meta: { scope: null, head_fixed:true } },

    { path:'produits', name:'produits', redirect: {name:'accueil'} },
    { path: 'cosmetique/:id-:slug?', name:'produit', component: Produit, props:true, meta:{head_fixed:true}},

    { path: ':id-:slug?', name:'actualite', component: Actualite, props:true, meta:{head_fixed:true}},

    { path: 'contact/:commande?', component: Contact, name:'contact', props:true, meta:{head_fixed:false}},
    { path: "erreur", name: "erreur", component: Erreur, meta: { scope: null } },

    { path: "conditions", component: Terms, name: "terms", meta:{head_fixed:false} },
    { path: "conditions/mentions-legales", component: TermsMentions, name:"terms-mentions", meta:{head_fixed:false} },
    { path: "conditions/retours", component: TermsRetours, name: "terms-retours", meta:{head_fixed:false} },
    { path: "conditions/generales-utilisations", component: TermsCgu, name: "terms-cgu", meta:{head_fixed:false} },
    { path: "conditions/generales-vente", component: TermsCgv, name: "terms-cgv", meta:{head_fixed:false} },
    { path: "conditions/confidentialite", component: TermsConfidentialite, name: "terms-confidentialite", meta:{head_fixed:false} },
    { path: "conditions/cookies", component: TermsCookies, name: "terms-cookies", meta:{head_fixed:false} },
    // { path: "faq", component: Cgv, name: "cgv_cookies", meta:{head_fixed:false} },



    { path: "sign", component: Sign, meta: { scope: null, head_fixed:false }, children: [
      { path: "", component: SignIn, beforeEnter: notin, name: "signin" },
      { path: "out", name: "signout", beforeEnter: logout },
      { path: "recovery", component: Recovery, name: "recovery"},
      { path: "password/code/:identifier", component: PasswordCode, name: "passwordcode", props: true },
      { path: "password/:tokenn/:hash", component: ChangePassword, name: "password", props: true },
    ]},

    { path: 'mon-compte', component:MyAccountAll, meta:{scope:'user', head_fixed:false }, children:[
      { path: '', component: MyCompte, name:'my-compte'},
      { path: 'adresses', component: MyAdresses, name:'my-adresses'},
      // { path: 'ma-newsletter', redirect:{name:{'my-compte'}}, name:'my-newsletter'},// component: MyNewsletter,
      // { path: 'remises', component: MyRemises, name:'my-remises'},
      // { path: 'fidelite', component: MyFidel, name:'my-fidel'},
      { path: 'commandes', component: MyCommandes, name:'my-commandes'}, // props:(route)=>({confirmation:route.query.order})
      { path: 'commandes/:hash', component: MyCommande, name:'my-commande', props:true},
    ]},

    { path: 'checkout', name:'checkout', component: Checkout, beforeEnter:(to)=>{ useAuthStore().returnUrl = to.fullPath }, meta:{head_fixed:false}},
    { path: 'checkout-reprise/:hash', name:'checkout-reprise', component: CheckoutReprise, meta:{scope:'user', head_fixed:false}, props:true },
    { path: 'checkout-confirmation/:hash', name:'checkout-confirmation', component: CheckoutConfirmation, meta:{scope:'user', head_fixed:false}, props:true},


    // Lien de paiement
    { path: "paiement/:id?", name: "paiement", component: Paiement, props: true },

    routesadmin,

    { path: ":pathMatch(.*)*", redirect: {name:'erreur'} },

    // { path:'produit', redirect: {name:'accueil'} },
    // { path: 'cosmetiques', name:'produits', component: Produits },
    // { path: 'panier', name:'panier', redirect:{name:'checkout'} },// component: Panier, meta:{title:'Mon panier'}
    // { path:'checkout', name:'checkout', redirect: {name:'panier'} },
    // { path: 'panier/:id?', name:'panier', component: Checkout },//, props:true
    // { path: '/', redirect: to => `/${i18n.locale}`}
    // { path: '', name:'accueil', component: Accueil },
    // { path: '/fr', redirect:'/' },
    // { path: ':pathMatch(.*)*', redirect:'/' },
  ]},

  { path: "/:pathMatch(.*)*", redirect: '/fr' },

];
