import { defineStore } from 'pinia';
import { CART_ID, CART_ITEMS } from '../composables/usePersistCart';
import {Product, useProductStore} from './products';
import Xhr from '/src/shared/Xhr.js';

import { v4 as uuid } from 'uuid';
import {addCustomEvent} from "rrweb";

export interface Purchase {
  produit_id: number;
  declinaison_id: number;
  quantity: number;
}

interface CartState {
  contents: Record<string, Purchase>;
}

export interface CartPreview {
  id: number;
  // image: string;
  declinaison: object;//<Declinaison>
  produit: object;//<Produit>
  qtt: number;
  prix_unitaire: number;
  prix_total_ht: number;
  prix_total_ttc: number;
}

var promise = null;

export const useCartStore = defineStore({
  id: 'cart',

  state: (): { contents: any; id: string, panier:any } => ({
    id: JSON.parse(localStorage.getItem(CART_ID) as string) ?? `${uuid()}`,
    contents: JSON.parse(localStorage.getItem(CART_ITEMS) as string) ?? {},
    panier:null
  }),

  getters: {
    count(): number {
      return Object.keys(this.contents).reduce((acc, id) => {
        return acc + this.contents[id].qtt;
      }, 0);
    },

    produits(): any[] {
      const declinaisons = useProductStore().declinaisons;
      return Object.keys(this.contents).map((id) => {
        if(!declinaisons[ id ]) return null;
        return declinaisons[ id ].label;//this.contents[id].declinaison_id ];
      });
    },

    total(): number {
      return this.total_ttc;
    },
    total_ht(): number {
      const products = useProductStore();
      const declinaisons = products.declinaisons;
      // if(!products) return null;
      // if(!declinaisons) return null;
      return Object.keys(this.contents).reduce((acc, id) => {
        let declinaison_id = this.contents[id].declinaison_id;
        let declinaison = declinaisons[declinaison_id];
        let purchase = this.contents[id];
        if(!declinaison) return acc;
        return acc + declinaison.prix_ht * purchase.qtt;//products.items[id].price *
      }, 0);
    },
    total_ttc(): number {
      const products = useProductStore();
      const declinaisons = products.declinaisons;
      // if(!products) return null;
      // if(!declinaisons) return null;
      return Object.keys(this.contents).reduce((acc, id) => {
        let declinaison_id = this.contents[id].declinaison_id;
        let declinaison = declinaisons[declinaison_id];
        let purchase = this.contents[id];
        if(!declinaison) return acc;
        return acc + declinaison.prix_ttc * purchase.qtt;//products.items[id].price *
      }, 0);
    },

    formattedCart(): CartPreview[] {
      const products = useProductStore();
      const declinaisons = products.declinaisons;

      if (!products.loaded) return [];

      return Object.keys(this.contents).map((declinaison_id) => {
        const purchase = this.contents[declinaison_id];
        let declinaison = declinaisons[purchase.declinaison_id];

        let produit = products.items[declinaison.produit_id];
        return {
          id: purchase.declinaison_id,
          declinaison: declinaison,
          produit: produit,
          qtt: purchase.qtt,
          prix_unitaire: declinaison.prix_ttc,
          prix_total_ht: purchase.qtt * declinaison.prix_ht,
          prix_total_ttc: purchase.qtt * declinaison.prix_ttc,

          prix_total_initial_ht: purchase.qtt * declinaison.prix_initial_ht,
          prix_total_initial_ttc: purchase.qtt * declinaison.prix_initial_ttc,
        };
      });
    },

    ventesCroisees(){
      const produits = useProductStore().items;
      const declinaisons = useProductStore().declinaisons;
      var associes = [];
      var keys = Object.keys(this.contents);
      for(let i=0; i<keys.length; i++){
        let id = keys[i];
        let declinaison_id = this.contents[id].declinaison_id;
        let declinaison = declinaisons[declinaison_id]
        let produit = produits[declinaison.produit_id]
        let passocies = produit.associes.map(a => a.produit_associe_id);
        associes.push(...passocies);
      }
      // var allTypesArray = ["4", "4", "2", "2", "2", "6", "2", "6", "6"];
      var map = associes.reduce(function(p, c) {
        p[c] = (p[c] || 0) + 1;
        return p;
      }, {});
      var principauxAssocies = Object.keys(map).sort(function(a, b) {
        return map[b] - map[a];
      });
      var associesPossibles = principauxAssocies.filter(a => Object.keys(this.contents).indexOf(a)===-1);
      // console.log(map, associesPossibles);
      return associesPossibles;
    }
  },

  actions: {
    add(declinaison_id: number, qtt=1) {
      qtt = parseInt(String(qtt));
      if (this.contents[declinaison_id]) {
        this.contents[declinaison_id].qtt += qtt;
      } else {
        this.contents[declinaison_id] = {
          // produit_id:produit_id,
          declinaison_id:declinaison_id,
          qtt: qtt,
        };
      }
      if(promise){
        promise.abort();
        promise = null;
      }
      promise = Xhr('POST', 'paniers/qtt', {panier:this.id, declinaison:declinaison_id, qtt:this.contents[declinaison_id].qtt})
      promise.then((data)=>{
        if(data.panier){
          var x = {};
          data.cds.map((cd) => { x[cd.declinaison_id] = { declinaison_id:cd.declinaison_id, qtt:cd.qtt}; });
          // this.panier = data.panier;
          // useProductStore().setDeclinaisonStock(declinaison_id, data.qttMax);
          this.setPanier(data.panier);
        }
        if(typeof data.qtt !== "undefined"){
          console.error("cart.qtt", data);
          // if(this.contents[declinaison_id].qtt !== data.qtt){
            this.contents[declinaison_id].qtt = data.qtt;
          // }
        }
        if(data.message){
          console.error("cart.message", data.message);
          this.contents[declinaison_id].qtt -= qtt;
          if(this.contents[declinaison_id].qtt<1) this.contents[declinaison_id].qtt = 1;
          // alert(data.message);
        }
      });
    },
    remove(declinaison_id: number) {
      if (!this.contents[declinaison_id]) return;

      this.contents[declinaison_id].qtt -= 1;

      let qtt = this.contents[declinaison_id].qtt;
      if (this.contents[declinaison_id].qtt <= 0) {
        delete this.contents[declinaison_id];
      }

      if(promise){
        promise.abort();
        promise = null;
      }
      promise = Xhr('POST', 'paniers/qtt', {panier:this.id, declinaison:declinaison_id, qtt:qtt})
      promise.then((data)=>{
        if(data.panier){
          var x = {};
          data.cds.map((cd) => { x[cd.declinaison_id] = { declinaison_id:cd.declinaison_id, qtt:cd.qtt}; });
          this.contents = x;
          this.panier = data.panier;
        }
        if(data.message){
          alert(data.message);
        }
      })
    },

    reset(){
      this.id = `${uuid()}`;
      this.panier = {};
      this.contents = {};
      addCustomEvent('reset_panier', { v:this.id })
    },

    getPanierByHash(id){
      this.id = id;
      this.panier = {};
      this.contents = {};
      return this.getPanier();
    },


    getPanier(){
      return Xhr('GET', `paniers/get/${this.id}`).then((data)=>{
        if(data.panier){
          this.setPanier(data.panier);
        }else{
          // console.error('setPanier',data);
          this.reset();
        }

        return data;
      })
    },

    getAllPaniers(){
      return Xhr('GET', `paniers/byMe`).then((data)=>{
        return data;
      })
    },
    setPanier(panier){
      var x = {};
      panier?.commandes_declinaisons?.map((cd) => {
        x[cd.declinaison_id] = { declinaison_id:cd.declinaison_id, qtt:cd.qtt};
      });
      this.id = panier?.hash;
      this.contents = x;
      this.panier = panier;

      // console.log("setPanier CART.TS", panier, x);
      //@todo updater aussi contents si les CD ne sont pas identiques ?!
      // useProductStore().setProduit()
    },



    delcinaison(declinaison_id): number {
      return this.contents[declinaison_id];
    },
    delcinaison_qtt(declinaison_id): number {
      return this.contents[declinaison_id]?.qtt || 0;
    },
  },
});
