<template>
	<button tabindex="0" :class="btnClass" @keypress.enter="onClick" @click="onClick" :aria-label="label ? label : null">
		<i v-if="fa" :class="'fa fa-'+fa" />
		<slot>
			<span v-html="label" />
		</slot>
	</button>
</template>
<script>
	import Vibration from '/src/shared/Vibration'

	export default {
		name: "BtnDelete",
		props: {
			fa:{default:'trash'},
			color: {default: 'light text-danger'},
			responsive: {default: false},
			label:{default:null},
			confirm:{default:"Supprimer ?"},
			alt:{default:false},
		},
		emits:['remove'],
		computed:{
			btnClass(){
				// if(this.alt) return 'btn btn-'+this.color;
				return 'btn btn-'+this.color+' rounded-5';
			}
		},
		methods:{
			onClick(){
				Vibration.vibrate(100);
				if(confirm(this.confirm)) this.$emit('remove');
			}
		}
	}
</script>
