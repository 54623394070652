<template>
	<div class="wrapper-nav" ref="main-scroll" >
		<nav class="nav nav-tabs mx-auto" @click="scrollToActiveItem">
			<div class="me-3 me-sm-5"></div>
			<slot />
			<div class="flex-grow-1"></div>
			<div class="me-3 me-sm-5"></div>
		</nav>
	</div>
</template>
<style scoped lang="scss">
.wrapper-nav {
	position:relative;
	margin:0 auto;
	overflow-y: hidden;
	top:0;
	//Hidden scrollbar
	&::-webkit-scrollbar {
		height:0;
		overflow:visible;
		width:0;
		-webkit-appearance: none;
	}
	nav{
		min-width: max-content;
		:deep(li),
		:deep(> .nav-link){
			display:table-cell;
			position:relative;
			text-align:center;
			cursor:grab;
			cursor:-webkit-grab;
			vertical-align:middle;
			cursor: pointer;
			&.active{
				background: transparent;
			}
		}
	}
}


//.scroller {
//	text-align:center;
//	cursor:pointer;
//	display:none;
//	padding:7px;
//	white-space:no-wrap;
//	vertical-align:middle;
//	background-color:#fff;
//}

//.pointer {}
</style>
<script>

export default{
	name:'Tabs',
	data(){
		return{
			scrollTimeout:null,
		}
	},
	mounted(){
		this.scrollToActiveItem(false);
		window.addEventListener('resize', this.scrollToActiveItem);
	},
	beforeUnmount(){
		window.removeEventListener('resize', this.scrollToActiveItem);
	},
	computed: {
		btnClass() {
			return this.defaultclass + this.color + (this.disabled ? ' disabled':'');
		}
	},
	methods:{
		scrollToActiveItem(behavior=true){
			if(behavior){
				if(this.scrollTimeout) clearTimeout(this.scrollTimeout);
				this.scrollTimeout = setTimeout(this.scrollToItem, 10, behavior);
			}else{
				this.scrollToItem(behavior);
			}
		},
		scrollToItem(behavior){
			this.$nextTick(()=>{
				let scroll = this.$refs['main-scroll'];
				if(!scroll) return;

				let el = scroll.getElementsByClassName('active');
				if(!el || !el[0]) return;
				el = el[0];

				let width = scroll.clientWidth;
				let avant = el.offsetLeft;
				let item = el.offsetWidth;
				scroll.scrollTo({
					left: avant - width/2 + item/2,
					behavior: behavior ? 'smooth':'auto'
				});
			})
		}
	}
}
</script>
