
const AdContainer = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-container.vue');
const AdDefault = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-default.vue');
const AdUsers = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-users.vue');
const AdUser = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-user.vue');
const AdProduits = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-produits.vue');
const AdProduit = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-produit.vue');
// const AdDeclinaison = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-produit-declinaison.vue');
const AdCommandes = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-commandes.vue');
const AdCommande = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-commande.vue');
const AdColis = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-colis.vue');
const AdColi = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-coli.vue');
const AdStatuts = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-statuts.vue');
const AdStatut = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-statut.vue');
const AdLivraisons = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-livraisons.vue');
const AdPaiements = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-paiements.vue');
const AdRemises = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-remises.vue');
const AdFactures = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-factures.vue');
const AdTrackings = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-trackings.vue');
const AdStats = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-stats.vue');
const AdTracks = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-tracks.vue');
const AdAbonnements = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-abonnements.vue');
const AdAbonnement = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-abonnement.vue');
const AdAdresses = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-adresses.vue');
const AdPrincipesactifs = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-principesactifs.vue');
const AdTags = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-tags.vue');
const AdPays = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-pays.vue');
const AdStocks = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-stocks.vue');
const AdArticles = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-articles.vue');
const AdConseils = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-conseils.vue');
const AdConfigs = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-configs.vue');
const AdVisuels = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-visuels.vue');
const AdMetas = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-metas.vue');
const AdFaqs = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-faqs.vue');
const AdFaqsCategories = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/ad-faqs-categories.vue');
const AdOneClickCommande = () => import(/*webpackChukName:"admin"*/ '/src/views/ad/one-click-commande.vue');


import OneClickUser from "../views/ad/one-click-user.vue";

import Paiements from "../pages/Paiements.vue";


export default
{ path: 'ad', component: AdContainer, meta:{scope:'admin', head_fixed:false}, children:[
    { path: 'dash', component: AdDefault, name:'ad'},

    { path: "paiements", name: "ad-lienspaiements", component: Paiements, meta: { scope: "user" } },

        { path: 'users', children:[
        { path:'', component: AdUsers, name:'ad-users' },
        { path: ':id', component: AdUser, name:'ad-user', props:true }
      ]},
    { path: 'abonnements', children:[
        { path:'', component: AdAbonnements, name:'ad-abonnements' },
        { path: ':id', component: AdAbonnement, name:'ad-abonnement', props:true }
      ]},
    { path: 'adresses', component: AdAdresses, name:'ad-adresses' },

    { path: 'produits', children:[
        { path:'', component: AdProduits, name:'ad-produits' },
        { path: ':id', component: AdProduit, name:'ad-produit', props:true },
        // { path: 'declinaison/:id', component: AdDeclinaison, name:'ad-declinaison', props:true }
      ]},


    // { path: 'rituels', component: AdRituels, name:'ad-rituels' },

    { path: 'commandes', children:[
        { path:'', component: AdCommandes, name:'ad-commandes' },
        { path: ':hash', component: AdCommande, name:'ad-commande', props:true }
      ]},
    { path: 'colis', children:[
        { path:'', component: AdColis, name:'ad-colis' },
        { path: ':id', component: AdColi, name:'ad-coli', props:true }
    ]},

    { path: 'tags', component: AdTags, name:'ad-tags'},
    { path: 'principes-actifs', component: AdPrincipesactifs, name:'ad-principesactifs'},
    { path: 'methodes-livraisons', component: AdLivraisons, name:'ad-livraisons'},
    { path: 'methodes-paiements', component: AdPaiements, name:'ad-paiements'},
    { path: 'remises', component: AdRemises, name:'ad-remises'},
    { path: 'articles', component: AdArticles, name:'ad-articles'},
    { path: 'configurations', component: AdConfigs, name:'ad-configs'},
    { path: 'conseils', component: AdConseils, name:'ad-conseils'},
    { path: 'visuels', component: AdVisuels, name:'ad-visuels'},
    { path: 'metas', component: AdMetas, name:'ad-metas'},
    { path: 'faqs', component: AdFaqs, name:'ad-faqs'},
    { path: 'faqs-categories', component: AdFaqsCategories, name:'ad-faqs-categories'},

    { path: 'statuts', children:[
        { path:'', component: AdStatuts, name:'ad-statuts' },
        { path: ':id', component: AdStatut, name:'ad-statut', props:true }
      ]},

    { path: 'factures', component: AdFactures, name:'ad-factures'},
    { path: 'trackings', component: AdTrackings, name:'ad-trackings'},
    { path: 'stats', component: AdStats, name:'ad-stats'},
    { path: 'tracks', component: AdTracks, name:'ad-tracks'},

    { path: 'pays', component: AdPays, name:'ad-pays'},
    { path: 'stocks', component: AdStocks, name:'ad-stocks'},


    { path: 'nouveau-client', name:'nouveau-client', component:OneClickUser, meta:{scope:null}},// component: Panier, meta:{title:'Mon panier'}

    { path: 'nouveau-commande', component:AdOneClickCommande, name:'nouveau-commande'},

    ]
}
