<template>
	<metainfo>
		<template v-slot:title="{ content }">{{ content }}{{ content ? ' - ':'' }}{{ $t("title") }}</template>
<!--		<template v-slot:description="{ metainfo }">{{ metainfo.meta.description }}</template>-->
<!--		<template v-slot:keywords="{ metainfo }">{{ metainfo.meta.keywords }}</template>-->
	</metainfo>
	<header-nav />
	<main>
		<router-view />
	</main>
	<newsletter />
	<cookie-settings />
	<footer-nav />
	<div v-if="admin"></div>
	<div id="modals"></div>
</template>

<style lang="scss" scoped>
main{
	min-height: 60vh;
}
</style>
<script lang="ts">
import HeaderNav from './components/header-nav.vue';
import FooterNav from './components/footer-nav.vue';
import CookieSettings from "./views/cookie-settings.vue";
import Newsletter from "./components/newsletter.vue";

import { usePersistCart } from './composables/usePersistCart';
import { useProductStore } from './stores/products';
import { usePrincipeStore } from './stores/principes';
import { useCartStore } from "./stores/cart";
import { useAuthStore } from "./stores/auth";

import { addCustomEvent } from 'rrweb'
import { ref } from 'vue'
// import {config} from 'vue-gtag';
export default {
	components: {
		HeaderNav,
		FooterNav,
		CookieSettings,
		Newsletter,
	},
	data(){
		return {
		}
	},
	mounted() {
		// Visibility (tab)
		document.addEventListener('visibilitychange', this.onVisibilityChange);

		// Changement de thème
		try {
			const mediaQuery = ref(window.matchMedia("(prefers-color-scheme: dark)"));
			mediaQuery.value.addEventListener('change', this.onMediaQueryTheme);
		} catch (error) {
			console.warn("PrefersColorScheme", error);
		}
	},
	beforeUnmount(){
		// Visibility (tab)
		document.removeEventListener('visibilitychange', this.onVisibilityChange);

		// Changement de thème
		const mediaQuery = ref(window.matchMedia("(prefers-color-scheme: dark)"));
		mediaQuery.value.removeEventListener(mediaQuery, this.onMediaQueryTheme)
	},
	created(){
		const cartStore = useCartStore();
		cartStore.getPanier();

		const produitsStore = useProductStore();
		produitsStore.fetchAll();

		const principesStore = usePrincipeStore();
		principesStore.fetchAll();

// const actualitesStore = useActualitesStore();
// actualitesStore.fetchAll();

// const configsStore = useConfigsStore();
// configsStore.fetch(['nav_top','nav_burger_visuel','nav_burger_to','home_best','home_carrousel','header_items']);

		usePersistCart();
	},
	methods:{
		onMediaQueryTheme(e){
			document.body.setAttribute('data-bs-theme', e.matches ? 'dark':'light');
			addCustomEvent('theme', { v:e.matches ? 'dark':'light' })
		},
		onVisibilityChange(){
			addCustomEvent('visibility', { state:document.visibilityState })
		},
	},
	metaInfo() {
		const locale = this.$i18n.locale;
		return {
			htmlAttrs: {
				lang: locale,
			},
			meta:[
				{name: 'charset', content:'utf-8'},
				// {name: 'theme-color', content:'#BD73BF'},
				// {name: 'theme-color', content:'#BD73BF', media:'(prefers-color-scheme: light)'},
				// {name: 'theme-color', content:'#FF0000', media:'(prefers-color-scheme: dark)'},
				// {name: 'msapplication-navbutton-color', content:'#BD73BF'},
				{name: 'mobile-web-app-capable', content:'yes'},
				{name: 'apple-mobile-web-app-capable', content:'yes'},
				{name: 'apple-mobile-web-app-status-bar-style', content:'black-translucent'},
				{name: 'google', content:'notranslate'},
				{name: 'description', content: this.$t("home.meta.description") },
				{name: "keywords", content: this.$t("home.meta.keywords") },
				// <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
				// <meta http-equiv="Pragma" content="no-cache">
				// <meta http-equiv="Expires" content="-1">
			],
			link: [
				// { rel: 'stylesheet', href: '/css/index.css' },
				// { rel: 'favicon', href: '/img/favicon.ico' },
				// { rel: 'favicon', href: '/img/icons/16x16.png' },
				// { rel: 'icon', href: '/img/icons/16x16.png', type:'image/png', sizes:'16x16' },
				// { rel: 'icon', href: '/img/icons/32x32.png', type:'image/png', sizes:'32x32' },
				// { rel: 'icon', href: '/img/icons/96x96.png', type:'image/png', sizes:'96x96' },
				// { rel: 'icon', href: '/img/icons/192x192.png', type:'image/png', sizes:'192x192' },
				// { rel: 'icon', href: '/img/icons/256x256.png', type:'image/png', sizes:'256x256' },
				// { rel: 'icon', href: '/img/icons/512x512.png', type:'image/png', sizes:'512x512' },
				// { rel: 'apple-touch-icon', href: '/img/icons/180x180.png', type:'image/png', sizes:'180x180' },
				{ rel: 'manifest', href: '/manifest.json' },
			]
		}
	},
	computed:{
		admin(){
			// let loggedin = useAuthStore().isLoggedIn;
			let admin = useAuthStore().hasScope("admin");
			// console.log("computed app info", loggedin, admin);
			if(admin){
				// console.log("app admin", admin, import.meta.env.VITE_GTAG_ID);
				window[`ga-disable-${import.meta.env.VITE_GTAG_ID}`] = admin;
			}
			return admin
		}
	},
}
</script>
